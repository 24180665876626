import styles from "./EulaSignView.shadow.css"
export class EulaSignView extends HTMLElement //implements IViewElement<RootCoordinatorState>
{
  static get is(): string {
    return 'eula-sign-view';
  }

  // private projectNameElement: HTMLDivElement;
  // private enrollmentButtonElement: PraxisButton;
  // private reportContainerElement: HTMLDivElement;
  // private _projectCode: string;
  private markupDiv: HTMLDivElement;
  private agreeCheckbox: HTMLInputElement;
  private saveBtn: HTMLButtonElement;

  private markup = '';
  public set Markup(theMarkup: string){
    this.markup = theMarkup;
    this.markupDiv.innerHTML = this.markup;
  }
  public onEulaSign: Promise<void>;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <div id="sign-eula-dialog">
      <h1>
        <div>Praxis Connect</div>
        <div class="font-body">${i18n.LABEL_END_USER_AGREEMENT}</div>
      </h1>
      <div class="eula-form">
        <div id="markup-container"></div>
        <div>
          <input id="agree-checkbox" name="agree-checkbox" type="checkbox" />
          <label for="agree-checkbox"><strong>${i18n.LABEL_I_AGREE}</strong></label>
        </div>
        <button id="save-btn">${i18n.BUTTON_SAVE}</button>
      </div>
    </div>
    `;
    this.markupDiv = shadowRoot.querySelector('#markup-container') as HTMLDivElement;
    this.agreeCheckbox = shadowRoot.querySelector('#agree-checkbox') as HTMLInputElement;
    this.saveBtn = shadowRoot.querySelector('#save-btn') as HTMLButtonElement;
    this.saveBtn.disabled = true;

    this.agreeCheckbox.addEventListener("click", () => {
      this.saveBtn.disabled = !this.agreeCheckbox.checked;
    });
    this.onEulaSign = new Promise((resolve) => {
      this.saveBtn.addEventListener('click', () => resolve());
    });
  }
}

window.customElements.define(EulaSignView.is, EulaSignView);
