import styles from "./PraxisPaginationBar.shadow.css";

export class PraxisPaginationBar extends HTMLElement {
  private static TOTAL_ATTRIBUTE = 'total';
  private static CURRENT_ATTRIBUTE = 'current';

  private pagesElement: HTMLDivElement;
  private prevElement: HTMLDivElement;
  private nextElement: HTMLDivElement;
  private total: number;

  private template = `
    <style>${styles}</style>
    <div arrow prev hidden>&#60;</div>
    <div pages></div>
    <div arrow next hidden>&#62;</div>
  `;

  private static get observedAttributes(): string[] {
    return [
      PraxisPaginationBar.TOTAL_ATTRIBUTE,
      PraxisPaginationBar.CURRENT_ATTRIBUTE
    ];
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.pagesElement = shadowRoot.querySelector('div[pages]') as HTMLDivElement;
    this.prevElement = shadowRoot.querySelector('div[arrow][prev]') as HTMLDivElement;
    this.nextElement = shadowRoot.querySelector('div[arrow][next]') as HTMLDivElement;

    this.flipPage = this.flipPage.bind(this);
    this.prevElement.addEventListener('click', () => { this.flipPage('prev'); });
    this.nextElement.addEventListener('click', () => { this.flipPage('next'); });
    this.total = 0;
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case PraxisPaginationBar.TOTAL_ATTRIBUTE:
        this.total = Number.parseInt(newValue);
        if (isNaN(this.total)) { throw "Attribute total is not a valid integer" }
        this.pagesElement.innerHTML = '';

        //only 4 tabs will be shown because of the css (min-width:25%)
        //set next arrow to visible when more than 4 pages
        this.prevElement.setAttribute('hidden', '');
        if (this.total > 4){
          this.nextElement.removeAttribute('hidden');
        }else{
          this.nextElement.setAttribute('hidden', '');
        }
        for (let i = 1; i <= this.total; i++) {
          //the first 4 tabs will set the order to 1, others are 2 (which will not be shown in the screen)
          const page = this.pagesElement.appendChild(this.getPageItemElement(i, i.toString() == this.getAttribute(PraxisPaginationBar.CURRENT_ATTRIBUTE)));
          if (i > 4){
            page.setAttribute('hidden', '');
          }
        }
        break;

      case PraxisPaginationBar.CURRENT_ATTRIBUTE:
        if (oldValue === newValue) return;
        this.setCurrent(isNaN(Number.parseInt(newValue)) ? 1 : Math.max(1, Number.parseInt(newValue)));
        break;
    }
  }

  private getPageItemElement(page: number, selected: boolean): HTMLElement {
    const item = document.createElement('div');
    item.setAttribute('page-item', '');
    item.setAttribute('value', page.toString());
    if (selected){
      item.setAttribute('selected', '');
    }
    item.innerHTML = page.toString();
    item.addEventListener('click', (e) => {
      const itemElement = e.currentTarget as HTMLDivElement;
      if (!itemElement){
        throw "Item Element cannot be null"
      }
      const value = Number.parseInt(itemElement.getAttribute('value') as string);
      this.setCurrent(value);
      this.dispatchEvent(new CustomEvent('onPageChange', {detail:{page: value}}))
    });
    return item;
  }

  private setCurrent(current: number) {
    this.setAttribute('current', current.toString());
    for (const n of this.pagesElement.children) {
      const item = n as HTMLElement;
      if (item.innerHTML == current.toString()) {
        item.setAttribute('selected', '');
      } else {
        item.removeAttribute('selected');
      }
    }
  }

  private flipPage(direction: "prev" | "next"){
    const showPages = this.pagesElement.querySelectorAll(':not([hidden])');
    const targetPage = direction == 'next' ? showPages[showPages.length-1] : showPages[0];
    if (!targetPage) { return; }
    for(const p of this.pagesElement.children){
      //hide all page first
      p.setAttribute('hidden', '');
    }
    let tobeShow = (direction == 'next' ? targetPage.nextSibling : targetPage.previousSibling) as HTMLDivElement;
    if (!tobeShow){return;}
    let emptySlot = 0;
    for(let i=0;i<4; i++){
      tobeShow.removeAttribute('hidden');
      tobeShow = (direction == 'next' ? tobeShow.nextSibling : tobeShow.previousSibling) as HTMLDivElement;
      if (tobeShow == null) {
        emptySlot = 3 - i;
        break;
      }
    }
    //fill the empty slot (e.g. 23 pages with 4 tobe shown each time, the last group isnt a group of 4)
    let fillPage = targetPage as HTMLDivElement;
    if (!fillPage) { return; }
    for(let i = 0; i<emptySlot; i++){
      fillPage.removeAttribute('hidden');
      fillPage = (direction == 'next' ? fillPage.previousSibling : fillPage.nextSibling) as HTMLDivElement;
      if (fillPage == null) { break; }
    }
    if (tobeShow == null){
      const pageButtonTobeHidden = direction == 'next' ? this.nextElement : this.prevElement;
      pageButtonTobeHidden.setAttribute('hidden', '');
    }
    const tmp = direction == 'next' ? this.prevElement : this.nextElement;
    tmp.removeAttribute('hidden');
  }
}

window.customElements.define('praxis-pagination-bar', PraxisPaginationBar);
