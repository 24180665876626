import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import styles from "./PraxisTextarea.shadow.scss";

// TODO: update initial minlength validation
export class PraxisTextarea extends _PraxisControlElement<HTMLTextAreaElement> {
  public static get is(): string {
    return 'praxis-textarea';
  }

  public static get observedAttributes(): string[] {
    return [
      ..._PraxisControlElement.observedAttributes,
      'background-color',
      'color',
      'maxlength',
      'minlength',
      'placeholder',
    ];
  }

  public get maxLength(): number {
    return this._wrappedElement?.maxLength || 2500;
  }

  public set maxLength(val: number) {
    this._textareaElement.maxLength = val;
    if (this._wrappedElement) {
      this._wrappedElement.maxLength = val;
    }
  }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    this._textareaElement.value = val;
    if (this._wrappedElement) {
      this._wrappedElement.value = val;
    }
    this._updateCounter();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'background-color':
        if (newValue != oldValue){
          this._backgroundColor = newValue ?? '';
          this._textareaElement.setAttribute('style', `color:${this._color};background-color:${this._backgroundColor}`);
          this._bottomElement.setAttribute('style', `background-color:${this._backgroundColor}`);
          this._counterElement.setAttribute('style', `color:${this._color}`)
        }
        break;
      case 'color':
        if (newValue != oldValue){
          this._color = newValue ?? '';
          this._textareaElement.setAttribute('style', `color:${this._color};background-color:${this._backgroundColor}`);
          this._bottomElement.setAttribute('style', `background-color:${this._backgroundColor}`);
          this._counterElement.setAttribute('style', `color:${this._color}`)
        }
        break;
      default:
        if (newValue === null) {
          this._wrappedElement?.removeAttribute(name);
          this._textareaElement.removeAttribute(name);
        } else {
          this._wrappedElement?.setAttribute(name, newValue);
          this._textareaElement.setAttribute(name, newValue);
        }
        this._validate();
        break;
    }
  }

  public focus(): void {
    this._textareaElement.focus();
  }

  public constructor() {
    super();
    this._onKey = this._onKey.bind(this);
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `<style>${styles}</style>
      <form>
        <textarea></textarea>
        <div id="bottom"><div id="count">count</div><div id="resize"></div></div>
      </form>`;
    this._textareaElement = shadowRoot.querySelector('textarea') as HTMLTextAreaElement;
    this._bottomElement = shadowRoot.querySelector('#bottom') as HTMLDivElement;
    this._counterElement = shadowRoot.querySelector('#count') as HTMLDivElement;
  }

  protected _selector = 'textarea';

  protected _setupComponent(): void {
    if (this._wrappedElement) {
      if (this._wrappedElement.maxLength < 0) {
        this.maxLength = 2500;
      }
      this._textareaElement.value = this._wrappedElement.value;
    }
    this._textareaElement.addEventListener('input', this._onKey);
    this.addEventListener('click', () => this._textareaElement.focus());
    this._updateCounter();
  }

  protected _validate(): void {
    if (this._wrappedElement) {
      const { validity } = this._wrappedElement;
      if (validity.badInput){
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_BAD_INPUT;
      } else if (validity.tooLong) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_TOO_LONG(this.getAttribute('maxlength'));
      } else if (validity.tooShort) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_TOO_SHORT(this.getAttribute('minlength'));
      } else if (validity.valueMissing) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_REQUIRED;
      } else {
        this._validationMessage = this._wrappedElement.validationMessage || '';
      }
    } else {
      this._validationMessage = '';
    }
  }

  private _counterElement: HTMLDivElement;
  private _bottomElement: HTMLDivElement;
  private _textareaElement: HTMLTextAreaElement;
  private _previousValue = '';
  private _backgroundColor = '';
  private _color = '';

  private _onKey() {
    this.value = this._textareaElement.value;
    if (this.value !== this._previousValue) {
      this._previousValue = this.value;
      this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
      // this._updateCounter();
    }
  }

  private _updateCounter() {
    this._counterElement.textContent = `${this.value.length}/${this.maxLength}`;
  }
}

window.customElements.define(PraxisTextarea.is, PraxisTextarea);
