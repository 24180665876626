import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import { PraxisSelect } from "../PraxisSelect/PraxisSelect";
import styles from "./PraxisTime.shadow.css";

const DELIMITER_REGEX = /[^0-9?]+/;
const PARTIAL_OPTION_LABELS = {
  '1': 'Seconds unknown',
  '2': 'Minutes unknown',
  '3': 'Time unknown',
};
const PARTIAL_OPTION_VALUES: {[key: string]: string} = {
  '1': '',
  '2': '',
  '3': '',
}
const MAX_LENGTH = 8;
// const defaultPattern = '^([0-1]\\d|2[0-3]):[0-5]\\d$';
const defaultPattern = '^([0-1]\\d|2[0-3])(:[0-5]\\d){1,2}$';
const defaultPatternRegex = /^([0-1]\d|2[0-3])(:[0-5]\d){1,2}$/;

/**
 * <praxis-time>
 *   <input/>
 * </praxis-time>
 */
export class PraxisTime extends _PraxisControlElement {
  public static is = 'praxis-time';
  public static get observedAttributes(): string[] {
    return [
      ..._PraxisControlElement.observedAttributes,
      'max',
      'min',
      'partial',
      'placeholder',
      'step',
      'value',
    ];
  }

  public get max(): string | null {
    if (this._maxTime === null)  {
      return null;
    } else {
      return this.getAttribute('max');
    }
  }

  public set max(value: string | null) {
    if (value && defaultPatternRegex.test(value)) {
      this.setAttribute('max', value);
    } else {
      this.removeAttribute('max');
    }
  }

  public get maxLength(): number {
    return this._inputElement.maxLength;
  }

  public get min(): string | null {
    if (this._minTime === null) {
      return null;
    } else {
      return this.getAttribute('min');
    }
  }

  public set min(value: string | null) {
    if (value && defaultPatternRegex.test(value)) {
      this.setAttribute('min', value);
    } else {
      this.removeAttribute('min');
    }
  }

  public get partial(): boolean {
    return this.hasAttribute('partial');
  }

  public set partial(value: boolean) {
    if (value) {
      this.setAttribute('partial', '');
    } else {
      this.removeAttribute('partial');
    }
  }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(value: string) {
    const time = this._getTime(value.replace(/\?{2}/g, '00'));
    if (time !== null) {
      this._inputElement.value = value;
      if (value){
        this._inputElement.removeAttribute('empty');
      }else{
        this._inputElement.setAttribute('empty', '');
      }
      this._isReady && this._updatePartialTimeSelection(value);
      if (this._wrappedElement) {
        this._wrappedElement.value = value;
      }
    } else {
      if (this._wrappedElement) {
        this._wrappedElement.value = '';
        this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
      }
    }
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'disabled':
        this._setDisabled();
        break;
      case 'max':
        this._maxTime = newValue ? this._getTime(newValue) : null;
        break;
      case 'min':
        this._minTime = newValue ? this._getTime(newValue) : null;
        break;
      case 'partial':
        this._setPartial();
        break;
      case 'placeholder':
        this._inputElement.placeholder = newValue || '';
        break;
      case 'readonly':
        this._setReadOnly();
        break;
      case 'required':
        this._inputElement.required = this.hasAttribute('required');
        break;
      case 'step':
        this._setStep(newValue);
        break;
      case 'value':
        this.value = newValue || '';
        break;
    }
    this._validate();
  }

  public focus(): void {
    this._inputElement.focus();
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = `
      <style>${styles}</style>
      <form>
        <input pattern="${defaultPattern}" maxlength="5" empty/>
      </form>
      <praxis-select>
        <select>
          <option value="">Set partial answer</option>
          <option value="1">Seconds unknown (00:00:??)</option>
          <option value="2">Minutes unknown (00:??:??)</option>
          <option value="3">Time unknown (??:??:??)</option>
        </select>
      </praxis-select>
    `;
    this._inputElement = shadowRoot.querySelector('input') as HTMLInputElement;
    this._selectElement = shadowRoot.querySelector('praxis-select') as PraxisSelect;
  }

  protected _selector = 'input';
  protected _wrappedElement?: HTMLInputElement;

  protected _setupComponent(): void {
    this._previousInputElementValue = this._wrappedElement?.value || '';
    this.value = this._previousInputElementValue;
    this._previousValue = this.value;
    this._addEventListeners();
  }

  protected _validate(): void {
    const { validationMessage, validity } = this._inputElement;
    const { value } = this;
    this.hasCriticalError = validity.patternMismatch;
    if (!validationMessage || validity.customError) {
      const errorMessage
        = !value && this._inputElement.value && i18n.ERROR_MESSAGE_FIELD_INVALID
        || this._aboveMax(value) && i18n.ERROR_MESSAGE_FIELD_RANGE_OVERFLOW(this.max)
        || this._belowMin(value) && i18n.ERROR_MESSAGE_FIELD_RANGE_UNDERFLOW(this.min)
        || '';
      this._inputElement.setCustomValidity(errorMessage);
    }
    this._validationMessage
      = this._inputElement.validationMessage
      || this._wrappedElement?.validationMessage
      || '';
  }

  private _inputElement: HTMLInputElement;
  private _maxTime: number | null = null;
  private _minTime: number | null = null;
  private _previousInputElementValue = '';
  private _previousValue = '';
  private _selectElement: PraxisSelect;
  private _showSeconds = false;

  private _aboveMax(value: string) {
    const minPossibleTime = this._getTime(value.replace(/\?{2}/g, '00'));
    return this._maxTime !== null && minPossibleTime !== null && minPossibleTime > this._maxTime;
  }

  private _addEventListeners() {
    this._onInput = this._onInput.bind(this);
    // this._inputElement.addEventListener('keydown', this._onKey);
    // this._inputElement.addEventListener('keyup', this._onKey);
    this._inputElement.addEventListener('input', this._onInput);
    //this._inputElement.addEventListener('blur', this._autocomplete.bind(this));

    this._selectElement.addEventListener('praxis-input-change', this._handlePartialTimeSelection.bind(this));
    this._selectElement.addEventListener('click', this._updateSelectOptionsLabels.bind(this));
    this._selectElement.addEventListener("ready", () => { this._updatePartialTimeSelection(this.value) });
  }

  private _autocomplete() {
    const [h, m, s] = this._inputElement.value.split(DELIMITER_REGEX) as [string, string, string];
    if (h.includes('?')) {
      this.value = this._showSeconds ? '??:??:??' : '??:??';
    } else if (parseInt(h) < 24) {
      const hh = h.padStart(2, '0');
      if (!m) {
        this.value = this._showSeconds ? `${hh}:00:00` : `${hh}:00`;
      } else if (m.includes('?')) {
        this.value = this._showSeconds ? `${hh}:??:??` : `${hh}:??`;
      } else if (parseInt(m) < 60) {
        const mm = m.padStart(2, '0');
        if (!this._showSeconds) {
          this.value = `${hh}:${mm}`;
        } else if (!s) {
          this.value = `${hh}:${mm}:00`;
        } else if (s.includes('?')) {
          this.value = `${hh}:${mm}:??`;
        } else if (parseInt(s) < 60) {
          this.value = `${hh}:${mm}:${s.padStart(2, '0')}`;
        }
      }
    }
    this._validate();
    this._handlePossibleChange();
  }

  private _belowMin(value: string) {
    const maxPossibleTime = this._getTime(value.replace(/^\?{2}/, '23').replace(/\?{2}/g, '59'));
    return this._minTime !== null && maxPossibleTime !== null && maxPossibleTime < this._minTime;
  }

  private _getCurrentTime(): string[] {
    const date = new Date();
    return [
      date.getHours(),
      date.getMinutes(),
    ].map(v => v.toString().padStart(2, '0'));
  }

  private _getTime(value: string): number | null {
    if (value.startsWith('24')) {
      return null;
    } else {
      const time = new Date(`1970-01-01T${value}Z`).getTime();
      return isNaN(time) ? null : time;
    }
  }

  private _handlePartialTimeSelection() {
    const selectValue = this._selectElement.value;
    if (selectValue) {
      this.value = PARTIAL_OPTION_VALUES[selectValue] || '';
      this._handlePossibleChange();
    }
  }

  private _handlePossibleChange() {
    if (this._previousValue !== this.value) {
      this._previousValue = this.value;
    }
  }

  private _onInput() {
    const { value } = this._inputElement;
    if (this._previousInputElementValue !== value) {
      this._previousInputElementValue = value;
      this.value = value;
      this._validate();
      this._handlePossibleChange();
    }
  }

  private _setDisabled() {
    const { disabled } = this;
    this._inputElement.disabled = disabled;
    this._selectElement.disabled = disabled;
  }

  private _setPartial() {
    this._updateInputAttributes();
  }

  private _setReadOnly() {
    const { readOnly } = this;
    this._inputElement.readOnly = readOnly;
  }

  private _setStep(value: string | null) {
    const stepValue = value ? parseInt(value) : NaN;
    this._showSeconds = stepValue % 60 > 0;
    this._updateInputAttributes();
  }

  private _updateInputAttributes() {
    const { partial } = this;
    let patternValue;
    let maxLength;
    if (this._showSeconds) {
      if (partial) {
        patternValue = '^((\\?{2}|([0-1]\\d|2[0-3]))(:\\?{2}){2})|(([0-1]\\d|2[0-3]):(\\?{2}|[0-5]\\d):\\?{2})|(([0-1]\\d|2[0-3]):[0-5]\\d:(\\?{2}|[0-5]\\d))$';
      } else {
        patternValue = '^([0-1]\\d|2[0-3])(:[0-5]\\d){2}$';
      }
      maxLength = 8;
    } else {
      if (partial) {
        patternValue = '^(\\?{2}:\\?{2})|(([0-1]\\d|2[0-3]):(\\?{2}|[0-5]\\d))$';
      } else {
        patternValue = defaultPattern;
      }
      maxLength = 5;
    }

    this._inputElement.pattern = patternValue;
    this._inputElement.maxLength = maxLength;

    if (this._wrappedElement) {
      this._wrappedElement.pattern = patternValue;
      this._wrappedElement.maxLength = maxLength;
    }
  }

  private _updatePartialOptionValues() {
    const inputValue = this._inputElement.value;
    const current = this._getCurrentTime();
    const [hh, mm] = inputValue.length >= 5
      ? inputValue.split(DELIMITER_REGEX).map((v, i) => v.includes('?') ? current[i] : v)
      : current;

    if (this._showSeconds) {
      PARTIAL_OPTION_VALUES['1'] = `${hh}:${mm}:??`;
      PARTIAL_OPTION_VALUES['2'] = `${hh}:??:??`;
      PARTIAL_OPTION_VALUES['3'] = `??:??:??`;
    } else {
      PARTIAL_OPTION_VALUES['2'] = `${hh}:??`;
      PARTIAL_OPTION_VALUES['3'] = `??:??`;
    }
  }

  private _updatePartialTimeSelection(value: string) {
    if (!value.endsWith('?')) {
      this._selectElement.value = '';
    } else {
      this._updateSelectOptionsLabels();
      if (/\d{2}:\d{2}:\?{2}/.test(value)) {
        this._selectElement.value = '1';
      } else if (/\d{2}:\?{2}:\?{2}/.test(value)) {
        this._selectElement.value = '2';
      } else if (/\?{2}:\?{2}:\?{2}/.test(value)) {
        this._selectElement.value = '3';
      } else {
        this._selectElement.value = '';
      }
    }
  }

  private _updateSelectOptionsLabels() {
    this._updatePartialOptionValues();
    for (const option of this._selectElement.options) {
      if (option.value === '1') {
        option.hidden = !this._showSeconds;
      }
      if (option.value) {
        option.textContent = `${PARTIAL_OPTION_LABELS[option.value as keyof typeof PARTIAL_OPTION_LABELS]} (${PARTIAL_OPTION_VALUES[option.value]})`
      }
    }
    this._selectElement.updateOptions();
  }
}

window.customElements.define(PraxisTime.is, PraxisTime);