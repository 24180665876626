import defineCustomElement from "@/Common/utils/defineCustomElement";
import styles from "./ThankYouView.shadow.scss";

export class ThankYouView extends HTMLElement
{
  static get is(): string {
    return 'thank-you-view';
  }

  public constructor() {
    super();
    this.attachShadow({mode:'open'}).innerHTML = `<style>${styles}</style>
      <div>${i18n.LABEL_QUESTIONNAIRE_SUBMIT_MESSAGE}</div>
      <praxis-icon name="check-circle-outline"></praxis-icon>
    `;
  }
}

defineCustomElement(ThankYouView)
