import "@/Common/views";
import "@/WebComponents/index";
import { anonymousAccess, updateUserInformation } from "@/Common/actions/auth/auth";
import PraxisShell from "@/Common/views/PraxisShell/PraxisShell";
import { en } from "@/Common/i18n/en";
import { fr } from "@/Common/i18n/fr";
import { CommonRootState } from "./reducers";
import { ThunkDispatch } from "redux-thunk";
import { IRouteConfig, router } from "./services/router/router";
import { Action, applyMiddleware, createStore, Reducer, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import '@Shared/styles/base-theme.css';
import '@Shared/styles/style.css';
import '@Shared/styles/ios-theme.css';
import '@Shared/styles/temp-theme.css';
import '@Shared/fonts/material-design-icons/MaterialIcons.css';
import '@Shared/fonts/fontawesome/css/all.css';
import { authService } from '@/Common/services/auth/auth';

export interface IAppShellConfig {
  searchEnabled: boolean,
  sideBarClosed: boolean,
  sideBarLabels: {
    homeLabel: string;
    subtitle: string;
  };
}

export interface IInit<RootState extends CommonRootState = CommonRootState> {
  appShellConfig: IAppShellConfig;
  authConfig?: {
    authority: string;
    client_id: string;
    redirect_uri: string;
    post_logout_redirect_uri: string;
    client_secret: string;
    popup_redirect_uri: string;
    report_uri: string;
  };
  store: Store<RootState>;
  dispatch: ThunkDispatch<RootState, any, Action>;
  routes: IRouteConfig[];
}

export const init = async <RootState extends CommonRootState>(props: IInit<RootState>): Promise<void> => {
  const {appShellConfig, authConfig, dispatch, routes, store } = props;

  //Prepare Praxis Connect
  //Check browser language
  switch(window.navigator.language.toLowerCase()){
    case "fr":
    case 'fr-ca':
      window.i18n = fr;
      break;
    case 'en-us':
    case 'en':
    default:
      window.i18n = en;
  }
  const appShell = new PraxisShell<RootState>();
  appShell.sideBarLabels = appShellConfig.sideBarLabels;
  if (appShellConfig.searchEnabled){
    appShell.enableSearch();
  }
  if (appShellConfig.sideBarClosed){
    appShell.closeSiderBar();
  }
  appShell.dispatch = dispatch;
  appShell.store = store;
  appShell.addEventListener("shell-ready", () =>{
    // TODO: may want to update signIn uri
    // redirectPath gets defined on signinRedirect in authService
    const redirectPath = localStorage.getItem('redirectPath');
    router.navigate(redirectPath || location.pathname);
    localStorage.removeItem('redirectPath');
  });

  router.init(routes, appShell);

  // attempt auth
  if (authConfig) {
    authService.configure(authConfig);
    const user = await authService.retrieveUserInformation();
    if (!user || !user.expires_in || user.expires_in <= 100) {
      authService.signinRedirect();
      return;
    }
    appShell.showExpiredWarningIn(user.expires_in);
    await dispatch(updateUserInformation(user));

  } else {
    await dispatch(anonymousAccess());
  }
  document.body.innerHTML= '';
  document.body.appendChild(appShell);

  // handle browser back/forward button
  window.addEventListener('popstate', () => router.navigate(location.pathname));

  // handle navigation event from praxis-anchor
  window.addEventListener('navigate', (e) => {
    const { detail } = e as CustomEvent;
    const hashIndex = detail.indexOf('#');
    const { hash, pathname } = detail.startsWith('http')
      ? new URL(detail)
      : {
        hash: hashIndex === -1 ? '' : detail.slice(hashIndex),
        pathname: (detail[0] === '/' ? detail : '/'+ detail),
      };
    router.navigate(pathname + hash);
  });
}

export const initProdStore = <S extends CommonRootState>(reducer: Reducer<S>): Store<S> => createStore(
  reducer,
  applyMiddleware(thunk),
);

export const initDevStore = <S extends CommonRootState>(reducer: Reducer<S>): Store<S> => createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export const initStore = process.env.NODE_ENV === 'production' ? initProdStore : initDevStore;
