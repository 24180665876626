
import { en } from "@/Common/i18n/en";

// for test
if (process.env.NODE_ENV !== 'production' ) {
  window.i18n = en;
}

export abstract class _PraxisControlElement<W extends HTMLElement = HTMLElement> extends HTMLElement {
  /**
   * name of the tag
   */
  public static is: string;

  /**
   * Default attributes to observe.
   * Child class can have more by adding following code.
   * ```ts
   * public static get observedAttributes(): string[] {
   *   return [
   *     ..._PraxisControlElement.observedAttributes,
   *     ...
   *   ];
   * }
   * ```
   */
  public static get observedAttributes(): string[] {
    return [
      'disabled',
      'readonly',
      'required'
    ];
  }

  /**
   * Replace and wrap an element with calling class
   * @param child element to be wrapped
   */
  public static wrap(child: HTMLElement): _PraxisControlElement {
    const wrapper = document.createElement(this.is) as _PraxisControlElement;

    const temp = document.createElement('i');
    child.replaceWith(temp);
    wrapper.appendChild(child);
    temp.replaceWith(wrapper);

    return wrapper;
  }

  public get disabled(): boolean {
    return this.hasAttribute('disabled');
  }

  public set disabled(v: boolean) {
    if (v) {
      this.setAttribute('disabled', '');
      this._wrappedElement?.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
      this._wrappedElement?.removeAttribute('disabled');
    }
  }

  public get name(): string {
    return this.getAttribute('name') || '';
  }

  public get readOnly(): boolean {
    return this.hasAttribute('readonly');
  }

  public set readOnly(v: boolean) {
    if (v) {
      this.setAttribute('readonly', '');
      this._wrappedElement?.setAttribute('readonly', '');
    } else {
      this.removeAttribute('readonly');
      this._wrappedElement?.removeAttribute('readonly');
    }
  }

  public get required(): boolean {
    return this.hasAttribute('required');
  }

  public get validationMessage(): string {
    if (this.disabled) {
      return '';
    } else {
      return this._validationMessage;
    }
  }
  public set hasCriticalError(value: boolean){
    if (value){
      this.setAttribute('critical-error', '');
    }else{
      this.removeAttribute('critical-error');
    }
  }
  public get hasCriticalError(): boolean{
    return this.hasAttribute('critical-error');
  }

  public abstract get value(): string;

  public abstract set value(val: string);

  /**
   * Basic behaviors should include:
   *   - applying the appropriate changes to wrappedElement or elements in shadowDOM
   *   - run `this._validate()`at the end
   */
  public abstract attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void;

  /**
   * Make sure to call `super.connectedCallback()` to inherit basic behavior, unless you need to override the callback.
   * Basic behaviors:
   *   1. assign this._wrappedElement
   *   2. sync attributes between _wrappedElement and parent element
   *   3. run this._setupComponent();
   *   4. run this._validate();
   */
  public connectedCallback(): void {
    this._wrappedElement = this.querySelector(this._selector) as W;
    this._syncAttributes();
    this._setupComponent();
    this._validate();
    this._isReady = true;
    this.dispatchEvent(new Event("ready"));
  }

  public focus(): void {
    super.focus();
  }

  /**
   * Touch
   * Used in PraxisForm to validate control when toggle to enable due to branch logic
   */
  public touch(): void {
    this._validate();
  }

  public constructor() {
    super();
    this.addEventListener('input', this._changeEventHandler.bind(this));
    this.addEventListener('focusout', this._focusoutEventHandler.bind(this));
  }

  /**
   * selector to find the wrapped element
   */
  protected abstract _selector: string;
  /**
   * setup component after the element has been:
   * - connected to the DOM tree
   * - found the wrapped element
   * - copied all of the attributes to this element from the wrapped element
   */
  protected abstract _setupComponent(): void;
  /**
   * Defined after connectedCallback()
   */
  protected _wrappedElement?: W;
  protected _validationMessage = '';
  protected _isReady = false;

  /**
   * Update attributes and properties related to validation.
   * This gets called on 'change' and 'focusout' event on the element.
   * There is no need to checked disabled for getting `this.validationMessage`
   * TODO: consider adding validityState
   */
  protected abstract _validate(): void;

  private _changeEventHandler() {
    this._validate();
    this._wrappedElement?.dispatchEvent(new Event('praxis-input-change', {bubbles: true, composed: true}));
  }

  private _focusoutEventHandler() {
    this._validate();
  }

  private _copyAttributes(srcElement: HTMLElement, targetElement: HTMLElement) {
    const { attributes } = srcElement;
    if (attributes) {
      for (const { name, value } of attributes) {
        if (targetElement.getAttribute(name) !== value) {
          targetElement.setAttribute(name, value);
        }
      }
    }
  }

  /**
   * Parent's attributes has priority
   */
  private _syncAttributes() {
    if (this._wrappedElement) {
      this._copyAttributes(this, this._wrappedElement);
      this._copyAttributes(this._wrappedElement, this);
    } else {
      this._wrappedElement = document.createElement(this._selector) as W;
      this._copyAttributes(this, this._wrappedElement);
      this.append(this._wrappedElement);
    }
  }
}