import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import { _PraxisSelectSingle } from "./_PraxisSelectSingle";
import styles from "./PraxisSelect.shadow.css";

export class PraxisSelect extends _PraxisControlElement {
  public static is = 'praxis-select';

  public get options(): HTMLOptionsCollection {
    if (this._wrappedElement) {
      return this._wrappedElement.options;
    } else {
      throw new Error('Wrapped element not found')
    }
  }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    if (this._wrappedElement) {
      this._wrappedElement.value = val;
    }
  }

  public updateOptions(): void {
    this._wrappedElement?.updateOptions();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    if (newValue === null) {
      this._wrappedElement?.removeAttribute(name);
    } else {
      this._wrappedElement?.setAttribute(name, newValue);
    }
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `<style>${styles}</style><slot></slot>`;
  }

  protected _selector = 'select';
  protected _wrappedElement?: _PraxisSelectSingle;

  protected _setupComponent(): void {
    if (this._wrappedElement) {
      if (this.hasAttribute('multiple')) {
        throw 'Not implemented';
      } else {
        this._wrappedElement = _PraxisSelectSingle.wrap(this._wrappedElement) as _PraxisSelectSingle;
      }
    }
    this.tabIndex = 0;
  }

  protected _validate(): void {
    this._validationMessage = this._wrappedElement?.validationMessage || '';
  }
}

window.customElements.define(PraxisSelect.is, PraxisSelect);
