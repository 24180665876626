import defineCustomElement from "@/Common/utils/defineCustomElement";
import styles from "./PraxisNotification.shadow.scss";
import { IShareRequest } from "@/Common/interfaces/IShareRequest";
import { PraxisDirectEntryNotification } from "../PraxisNotification/PraxisDirectEntryNotification/PraxisDirectEntryNotification";
import { PraxisShareRequestNotification } from "../PraxisNotification/PraxisShareRequestNotification/PraxisShareRequestNotification";
import { PraxisOutstandingFlagNotification } from "../PraxisNotification/PraxisOutstandingFlagNotification/PraxisOutstandingFlagNotification";
import IDirectEntryNotification from "@/Common/interfaces/IDirectEntryNotification";
import { PraxisExpandableInfo } from "@/WebComponents/PraxisExpandableInfo/PraxisExpandableInfo";
import { IOutstandingFlag } from "@/Common/interfaces/IFormInstance";
import { getLocalDate } from "@/Common/utils/dateTime";

export class PraxisNotification extends HTMLElement {
  public static get is(): string {
    return 'praxis-notification';
  }

  public constructor() {
    super();
    this.directEntryNotificationClick = this.directEntryNotificationClick.bind(this);
    const shadowRoot = this.shadowRoot || this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `<praxis-progress-bar class='no-border thin'></praxis-progress-bar>`;
  }

  private setEmpty(type: string){
    this.setAttribute('empty', '');
    const shadowRoot = this.shadowRoot || this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = `<style>${styles}</style>
      <praxis-icon name="check-mark"></praxis-icon>
      <div>
        <div class="heading">${i18n.LABEL_ALL_CAUGHT_UP}</div>
        <div>${i18n.LABEL_NO_NEW_TYPE_TO_REVIEW(type)}</div>
      </div>`
  }

  public loadNotification(notifications: IDirectEntryNotification[]): void {
    const shadowRoot = this.shadowRoot || this.attachShadow({ mode: "open" });
    if (notifications.length) {
      this.setAttribute('contain-expandable', '');
      shadowRoot.innerHTML = `<style>${styles}</style>
        <praxis-expandable-info notification expand-label='${i18n.LABEL_YOU_HAVE_NOTIFICATIONS(notifications.length)}'></div>
      `;
      const expandable = shadowRoot.querySelector('praxis-expandable-info') as PraxisExpandableInfo;
      for (const n of notifications) {
        const directEntryNotificationElement = new PraxisDirectEntryNotification();
        directEntryNotificationElement.init(
          n.participantId,
          n.projectName,
          n.projectCode,
          n.formName,
          n.formCode,
          n.facility,
          n.date,
          n.enrollmentId,
          n.notificationId,
          n.formInstanceId
        );
        directEntryNotificationElement.addEventListener('direct-entry-notification-click', this.directEntryNotificationClick as EventListener);
        expandable.append(directEntryNotificationElement);
      }
    } else {
      this.setEmpty('notifications');
    }
  }
  private directEntryNotificationClick(e: CustomEvent){
    this.dispatchEvent(new CustomEvent("direct-entry-notification-click",{ detail: e.detail }));
  }

  public loadShareRequest(incomings: IShareRequest[], outgoings: IShareRequest[]): void {
    const shadowRoot = this.shadowRoot || this.attachShadow({ mode: "open" });
    if (incomings.length || outgoings.length) {
      this.setAttribute('contain-expandable', '');
      shadowRoot.innerHTML = `<style>
          ${styles}
          [title]{
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            color: #666;
          }
          [incoming]{
            padding-bottom: 25px;
          }
        </style>
        <praxis-expandable-info share-request expand-label='${i18n.LABEL_SHARE_REQUEST_NOTIFICATION(incomings.length + outgoings.length)}'>
          <div incoming share-request-container>
            <div class="sharing-title">
              ${i18n.LABEL_INCOMING_SHARE_REQUEST}
            </div>
            <div content></div>
          </div>
          <div outgoing share-request-container>
            <div class="sharing-title">
              ${i18n.LABEL_OUTGOING_SHARE_REQUEST}
            </div>
            <div content></div>
          </div>
        </praxis-expandable-info>`;

      const incomingDiv = shadowRoot.querySelector('div[incoming] > [content]') as HTMLDivElement;
      if (incomings.length) {
        for (const r of incomings) {
          const shareRequestElement = new PraxisShareRequestNotification();
          shareRequestElement.load(r, "incoming");
          incomingDiv.append(shareRequestElement);
        }
      } else {
        incomingDiv.textContent = 'No outstanding incoming share request';
      }

      const outgoingDiv = shadowRoot.querySelector('div[outgoing] > [content]') as HTMLDivElement;
      if (outgoings.length) {
        for (const r of outgoings) {
          const shareRequestElement = new PraxisShareRequestNotification();
          shareRequestElement.load(r, "outgoing");
          outgoingDiv.append(shareRequestElement);
        }
      }else{
        outgoingDiv.textContent = 'No outstand outgoing share request';
      }
    } else {
      this.setEmpty('share requests');
    }
  }

  public loadFlags(flags: IOutstandingFlag[]): void{
    const shadowRoot = this.shadowRoot || this.attachShadow({ mode: "open" });
    if (flags.length){
      this.setAttribute('contain-expandable', '');
      shadowRoot.innerHTML = `<style>${styles}</style>
        <praxis-expandable-info share-request expand-label='${i18n.LABEL_FLAG_REQUIRING_ATTENTION(flags.length)}'>
      `;

      const flagGroupByProject: { [code: string]: PraxisOutstandingFlagNotification[] } = {};
      const projectCodes: string[] = [];
      flags.forEach(f => {
        const outstandingFlagItem = new PraxisOutstandingFlagNotification();
        outstandingFlagItem.init(
          f.enrollmentId,
          f.participantId,
          f.projectCode,
          f.facilityName,
          f.formName,
          f.formType,
          f.formInstanceId,
          f.fieldName,
          getLocalDate(new Date(f.lastUpdated))
        );
        if (projectCodes.some(c => c == f.projectCode)){
          flagGroupByProject[f.projectCode]?.push(outstandingFlagItem);
        }else{
          projectCodes.push(f.projectCode);
          flagGroupByProject[f.projectCode] = [outstandingFlagItem];
        }
      })
      const sortedPojectCodes = projectCodes.sort();

      const expandable = shadowRoot.querySelector('praxis-expandable-info');
      for(const code of sortedPojectCodes){
        if (projectCodes.length > 1){
          const title = document.createElement('div');
          title.classList.add('flag-project-code');
          title.textContent = decodeURI(code);
          expandable?.append(title);
        }
        flagGroupByProject[code]?.forEach(f => {
          expandable?.append(f);
        })
      }
    }else{
      this.setEmpty('outstanding flags');
    }
  }
}

defineCustomElement(PraxisNotification);
