import { Reducer } from "redux";
import { IOutstandingFlag } from "@/Common/interfaces/IFormInstance";

export interface IOutstandingFlagsState<T> {
  status: 'loading' | 'loaded' | 'failed';
  outstandingFlags: IOutstandingFlag[];
}

const LOAD_OUTSTANDING_FLAGS = 'LOAD_OUTSTANDING_FLAGS';
const UPDATE_OUTSTANDING_FLAG = 'UPDATE_OUTSTANDING_FLAG';
const REMOVE_OUTSTANDING_FLAG = 'REMOVE_OUTSTANDING_FLAG';

interface ILoadOutstandingFlagsAction {
  type: typeof LOAD_OUTSTANDING_FLAGS;
  outstandingFlags: IOutstandingFlag[];
}

interface IUpdateOutstandingFlag {
  type: typeof UPDATE_OUTSTANDING_FLAG;
  outstandingFlag: IOutstandingFlag;
}

interface IRemoveOutstandingFlag {
  type: typeof REMOVE_OUTSTANDING_FLAG;
  formInstanceId: number;
  fieldName?: string;
}

export type OutstandingFlagsActionTypes
= ILoadOutstandingFlagsAction
| IUpdateOutstandingFlag
| IRemoveOutstandingFlag

const initState: IOutstandingFlagsState<IOutstandingFlag> = {
  status: 'loading',
  outstandingFlags: []
}

export const outstandingFlags: Reducer<IOutstandingFlagsState<IOutstandingFlag>, OutstandingFlagsActionTypes> = (state = initState, action) => {
  switch(action.type) {
    case LOAD_OUTSTANDING_FLAGS: {
      const { outstandingFlags } = action;
      return{
        status: 'loaded',
        outstandingFlags
      };
    }
    case UPDATE_OUTSTANDING_FLAG: {
      const { outstandingFlag } = action;
      const existingFlagIndex = state.outstandingFlags.findIndex((flag) =>
        flag.formInstanceId == outstandingFlag.formInstanceId &&
        flag.enrollmentId == outstandingFlag.enrollmentId &&
        flag.formName == outstandingFlag.formName &&
        flag.formType == outstandingFlag.formType &&
        flag.fieldName == outstandingFlag.fieldName &&
        flag.projectCode == outstandingFlag.projectCode &&
        flag.facilityName == outstandingFlag.facilityName
      );

      if(existingFlagIndex !== -1) {
        const updatedFlags = [...state.outstandingFlags];
        updatedFlags[existingFlagIndex] = {
          ...outstandingFlag,
          lastUpdated: outstandingFlag.lastUpdated
        };
        return {
          ...state,
          outstandingFlags: updatedFlags
        };
      } else {
        return {
          ...state,
          outstandingFlags: [...state.outstandingFlags, outstandingFlag]
        }
      }
    }
    case REMOVE_OUTSTANDING_FLAG: {
      const { formInstanceId, fieldName } = action;

      state.outstandingFlags = state.outstandingFlags.filter(f => {
        return f.formInstanceId != formInstanceId || (fieldName && f.fieldName != fieldName);
      })

      return state;
    }
    default: {
      return state;
    }
  }
}