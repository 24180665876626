import { IErrors } from "@/Common/interfaces/IErrors";

type MethodInit = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface ICustomRequest {
  additionalHeaders: {[key: string]: string};
  body?: BodyInit;
  formData?: boolean;
  method: MethodInit;
  path: string;
}
export abstract class AbstractRequest {
  private static origin = process.env.PRAXIS_CONNECT_DATA_CENTER_ORIGIN;

  public async request(method: MethodInit, path: string, body?: BodyInit, formData?: boolean): Promise<Response> {
    const headers = await this._newHeaders();

    if (body && !formData) {
      headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    const requestOptions: RequestInit = {
      body,
      headers,
      method,
    };
    return fetch(`${AbstractRequest.origin}/${path}`, requestOptions)
      .then(res => {
        if (res.ok) {
          return res;
        } else {
          throw res;
        }
      });
  }

  public async customRequest(options: ICustomRequest): Promise<Response>{
    const { additionalHeaders, body, formData, method, path } = options;
    const headers = await this._newHeaders();

    if (additionalHeaders) {
      for (const key in additionalHeaders) {
        const header = additionalHeaders[key];
        if (header) {
          headers.append(key, header);
        }
      }
    }

    if (body && !formData) {
      headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    const requestOptions: RequestInit = {
      body,
      headers,
      method,
    };
    return fetch(`${AbstractRequest.origin}/${path}`, requestOptions)
      .then(res => {
        if (res.ok) {
          return res;
        } else {
          throw res;
        }
      });
  }

  // TODO: handle 401 error
  public errorHandler(defaultErrorMessage: string) {
    return async (response: Response) => {
      if (response && response.json) {
        const error:IErrors = {status: response.status, msgs:[defaultErrorMessage], trace:''};

        if(response.status >= 500)
        {
          //Server error
          const data = await response.json()
            .catch(() => null);
          if (data && data.trace)
          {
            if (data.trace){
              error.trace = data.trace;
            }
            if (data.errors as string[]){
              error.msgs = data.errors;
            }
          }
          throw error;
        }
        else if (response.status >= 400)
        {
          //Client error
          const data = await response.json()
            .catch(() => null);
          if (data && data.errors as string[]){
            error.msgs = data.errors;
          }else if (response.status == 404){
            //Not found (404) without any message
            error.msgs = [i18n.ERROR_SERVER_404_NOT_FOUND];
          }
          throw error;
        }
        else{
          throw await response.json().catch(() => ({status: 900, msgs:[defaultErrorMessage]}));
        }
      } else {
        //unknown error
        throw { status: 999, msgs: [defaultErrorMessage] };
      }
    }
  }

  protected async _newHeaders(): Promise<Headers> {
    return new Headers();
  }
}
