import { IUser } from "@/Common/interfaces/IUser";

export type AuthState = {
  authenticated: true;
  user: IUser;
} | {
  authenticated: false;
  user: undefined;
} | {
  authenticated?: undefined;
  user?: undefined;
}

/**
 * action types
 */
export const UPDATE_ANONYMOUS_ACCESS = 'UPDATE_ANONYMOUS_ACCESS';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
interface IAuthAction {
  type: typeof UPDATE_USER_INFORMATION;
  user: IUser;
}

interface IAnonymousAuthAction {
  type: typeof UPDATE_ANONYMOUS_ACCESS;
}

export type AuthActionTypes = IAuthAction | IAnonymousAuthAction;

/**
 * reducer
 */
export const auth = (state: AuthState = {}, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case UPDATE_USER_INFORMATION: {
      return {
        authenticated: true,
        user: {
          ...action.user,
          username: `${action.user.firstName}, ${action.user.lastName}`
        }
      };
    }
    case UPDATE_ANONYMOUS_ACCESS: {
      return {
        authenticated: false,
        user: undefined
      };
    }
    default: {
      return state;
    }
  }
}
