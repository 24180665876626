export class NotFoundView extends HTMLElement
// implements IViewElement
{
  static get is(): string {
    return 'not-found-view';
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `
      <style type="text/css">
        #container {
          background-color: #FEFEFE;
          padding: 40px;
          max-width: 720px;
        }
        .content {
            display: flex;
            flex-direction: column;
          }
          
          .header-content {
            z-index: 2;
          }
          
          .interactive-component {
            max-width: 600px;
            min-height: 300px;
            position: relative;
          }
          
          .interactive-component > * {
            display: inline-block;
            position: absolute;
          }
          
          .b1 {
            height: 36px;
            opacity: .2;
            right: 196px;
            top: 0;
            width: 41px;
          }
          
          .b2 {
            height: 23px;
            opacity: .15;
            right: 0;
            top: 40px;
            width: 24px;
          }
          
          .b3 {
            height: 62px;
            opacity: .3;
            right: 235px;
            top: 152px;
            width: 71px;
          }
          
          .butterfly {
            background-size: cover;
            background-image: url('data:image/svg+xml,<svg width="71" height="62" viewBox="0 0 71 62" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.5 45.6529C32.7739 45.6529 29.8365 48.9855 29.8365 61.9997C23.1509 61.9997 13.6547 62.1176 9.20085 57.478C6.29162 54.449 2.88486 46.3345 3.99587 44.7016C5.76266 42.1015 11.4522 42.5304 14.3874 40.7723C21.8361 36.3132 19.1241 31.7564 19.1241 31.7564C11.554 29.5842 10.5872 28.8166 7.28015 26.3948C5.33776 24.9711 4.36007 21.6788 4.36007 19.5968C4.36007 17.5159 5.22179 9.89085 3.6035 7.89912C1.98412 5.90632 -1.45947 2.26896 0.681268 0.191233C1.57984 -0.679354 3.27509 1.64044 5.85373 2.7722C8.43346 3.90397 16.3515 4.64927 20.5506 8.14224C24.7508 11.6341 26.6942 17.5011 28.3613 21.5557C30.0284 25.6113 33.0427 30.5768 35.5 30.5768C37.9572 30.5768 40.9716 25.6113 42.6387 21.5557C44.3057 17.5011 46.2503 11.6341 50.4494 8.14224C54.6496 4.64927 62.5665 3.90397 65.1463 2.7722C67.726 1.64044 69.4212 -0.679354 70.3187 0.191233C72.4595 2.26896 69.0158 5.90632 67.3976 7.89912C65.7793 9.89085 66.6399 17.5159 66.6399 19.5968C66.6399 21.6788 65.6633 24.9711 63.7198 26.3948C60.4139 28.8166 59.447 29.5842 51.8769 31.7564C51.8769 31.7564 49.1639 36.3132 56.6125 40.7723C59.5489 42.5304 65.2384 42.1015 67.0052 44.7016C68.1151 46.3345 64.7083 54.449 61.8002 57.478C57.3464 62.1176 47.8491 61.9997 41.1635 61.9997C41.1635 48.9855 38.226 45.6529 35.5 45.6529Z" fill="%23000000"/></svg>');
          }
        
      </style>
      <div id="container">
        <div class="content">
          <div class="header-content">
            <h3> ${i18n.ERROR_CONTENT_UNAVAILABLE}</h3>
            <p>
            ${i18n.ERROR_404_PAGE_NOT_FOUND}
            </p>
          </div>
          <div class="interactive-component">
            <div class="b1 butterfly"></div>
            <div class="b2 butterfly"></div>
            <div class="b3 butterfly"></div>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define(NotFoundView.is, NotFoundView);
