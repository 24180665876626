import { IEula } from "@/Common/interfaces/IEula";
import { secureRequest } from "@/Common/services/dataCenter/secureRequest";

export const postResetPassword = (email: string, projectCode: string): Promise<void> => {
  const body: BodyInit = JSON.stringify({ email, projectCode });
  return secureRequest.request('POST', 'User/ResetPassword', body)
    .then(() => undefined)
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('user')));
}

export const isLatestEulaSigned = (): Promise<boolean> => {
  return secureRequest.request('GET', 'User/Eula/latest')
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('Eula')));
}

export const signEula = (): Promise<void> => {
  return secureRequest.request('POST', 'User/Eula')
    .then(() => undefined)
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('Eula')));
}

export const getEula = (): Promise<IEula> => {
  return secureRequest.request('GET', 'User/Eula')
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('Eula')));
}

export const postUnlockUser = (email: string, projectCode: string): Promise<void> => {
  //TODO: RICK
  //take a reference of post Reset Password
  //Do a Post request to data center unlock user function

  const body: BodyInit = JSON.stringify({ email, projectCode });
  return secureRequest.request('POST', 'User/Unlock', body)
    .then(() => undefined)
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('user')));
  // console.log("Not Implemented.......");
  // return Promise.resolve();
}