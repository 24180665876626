import defineCustomElement from "@/Common/utils/defineCustomElement";
import { PraxisForm } from "@/WebComponents/PraxisForm/PraxisForm";
import { IViewElement } from "@/Common/interfaces/IViewElement";
import { RootParticipantState } from "@/ParticipantApp/reducers";
import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import styles from "./FormView.shadow.scss";
import { PraxisDialog } from "@/WebComponents/PraxisDialog/PraxisDialog";
import { getDirectEntryForm, saveDirectEntryForm, submitDirectEntryForm } from "@/ParticipantApp/services/directEntry/directEntry";
import { router } from "@/Common/services/router/router";
import { PraxisProgressBar } from "@/WebComponents/PraxisProgressBar/PraxisProgressBar";
import { IErrors } from "@/Common/interfaces/IErrors";

export class FormView extends HTMLElement implements IViewElement
{
  private code?: string;

  static get is(): string {
    return 'form-view';
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `<style>${styles}</style>
      <praxis-progress-bar class='no-border thin'></praxis-progress-bar>
      <div id="container">
        <div class="hr" id="project-name"></div>
        <div id="actions">
          <praxis-button icon="save" id="save" disabled>${i18n.BUTTON_SAVE}</praxis-button>
          <praxis-button icon="send" id="submit">${i18n.BUTTON_SUBMIT}</praxis-button>
        </div>
        <div id="form-name"></div>
        <praxis-form participant></praxis-form>
      </div>
    `;
    this._progressbar = shadowRoot.querySelector('praxis-progress-bar') as PraxisProgressBar;
    this._container = shadowRoot.querySelector('#container') as HTMLDivElement;
    this._projectNameElement = shadowRoot.querySelector('#project-name') as HTMLDivElement;
    this._formNameElement = shadowRoot.querySelector('#form-name') as HTMLDivElement;
    this._saveButton = shadowRoot.querySelector('#save') as PraxisButton;
    this._submitButton = shadowRoot.querySelector('#submit') as PraxisButton;
    this._formElement = shadowRoot.querySelector('praxis-form') as PraxisForm;

    this._container.hidden = true;

    this._addEventListeners();
  }

  public connectedCallback(): void {
    const [ , , , code ] = location.pathname.split('/');
    // dispatch(loadDirectEntryView(code));
    if (!code) { router.navigate('/notfound'); return; }
    this.code = code;

    getDirectEntryForm(code)
      .then(({ markup, name, projectName }) => {
        if (!this._formElement.loaded) {
          this._projectNameElement.textContent = projectName;
          this._formNameElement.textContent = name;
          this._formElement.load(markup);
          this._formElement.getFormFieldComments('all').forEach(c => { c.hidden = true; });
          this._formElement.isPartial = true;
          this._formElement.touchAndUpdateValidateMessage();
          this._container.hidden = false;
          this._progressbar.hidden = true;
        }
      })
      .catch(() => {
        this.shadowRoot.innerHTML = `<style>${styles}</style>
        <div id="not-found">
          <h1>${i18n.LABEL_QUESTIONNAIRE_NOT_FOUND}</h1>
          <div>${i18n.LABEL_QUESTIONNAIRE_NOT_FOUND_MESSAGE}</div>
        </div>
      `;
      });
  }

  public disconnectedCallback(): void {
    window.removeEventListener('beforeunload', this._confirmCloseForm)
  }

  // public update({ formView }: RootParticipantState): void {}

  private _progressbar: PraxisProgressBar;
  private _projectNameElement: HTMLDivElement;
  private _formNameElement: HTMLDivElement;
  private _formElement: PraxisForm;
  private _hasChangeSinceLastSave = false;
  private _saveButton: PraxisButton;
  private _submitButton: PraxisButton;
  private _container: HTMLDivElement;

  private _addEventListeners() {
    this._confirmCloseForm = this._confirmCloseForm.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._saveForm = this._saveForm.bind(this);
    this._submitForm = this._submitForm.bind(this);
    this._saveButton.addEventListener('click', this._saveForm);
    this._submitButton.addEventListener('click', this._submitForm);
    this._formElement.addEventListener('change', this._handleFormChange);
    this._formElement.addEventListener('save', this._saveForm);
    this._formElement.addEventListener('save-and-close', this._submitForm);
  }

  private _confirmCloseForm(e: Event) {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return e.returnValue = 'There are unsaved changes on the page. Are you sure you want to leave?'
  }

  private _handleFormChange() {
    if (this._formElement.hasChange) {
      this._handleFormHasChange();
    } else {
      this._handleFormHasNoChange();
    }
  }

  private _handleFormHasChange() {
    if (!this._hasChangeSinceLastSave) {
      window.addEventListener('beforeunload', this._confirmCloseForm);
      this._saveButton.disabled = false;
      this._hasChangeSinceLastSave = true;
    }
  }

  private _handleFormHasNoChange() {
    if (this._hasChangeSinceLastSave) {
      window.removeEventListener('beforeunload', this._confirmCloseForm);
      this._saveButton.disabled = true;
      this._hasChangeSinceLastSave = false;
    }
  }

  private async _saveForm() {
    try {
      const formData = this._formElement.formData;
      this._formElement.disabled = true;
      await saveDirectEntryForm(this.code, formData);
      this._formElement.disabled = false;
      this._formElement.updateLastSavedFormData();
      this.dispatchEvent(new CustomEvent("open-snack-bar",
        { bubbles: true, composed: true, detail: { text: i18n.SNACKBAR_FORM_HAS_BEEN_SAVED } }));
      this._handleFormChange();
    } catch (e) {
      const error = e as IErrors
      if (error && error.msgs) {
        this._formElement.displayErrors(error.msgs);
      } else {
        this._formElement.displayErrors([i18n.ERROR_MESSAGE_UNEXPECTED]);
      }
      this.scroll({ top: 0 });
    }
  }

  private async _submitForm() {
    // TODO: 'required' validation could use some optimization/refactor
    this._formElement.touchAndUpdateValidateMessage();
    const { valid } = this._formElement;
    const message = valid?
      `<p>${i18n.CONFIRMATION_SUBMIT_FORM_1}</p><p>${i18n.CONFIRMATION_SUBMIT_FORM_2}</p>`
      : `<p>${i18n.CONFIRMATION_SUBMIT_FORM_0}</p><p>${i18n.CONFIRMATION_SUBMIT_FORM_1}</p><p>${i18n.CONFIRMATION_SUBMIT_FORM_2}</p>`;

    const submit = await PraxisDialog.confirm(message);
    if (submit) {
      try {
        if (await this._formElement.hasChange) {
          await this._saveForm();
          this._formElement.disabled = true;
        }
        await submitDirectEntryForm(this.code);
        router.navigate('/ThankYou');
      } catch (error: any) {
        if (error && error.errors) {
          this._formElement.displayErrors(error.errors);
        } else {
          this._formElement.displayErrors([i18n.ERROR_MESSAGE_UNEXPECTED]);
        }
        this.scroll({ top: 0 });
      }
    }
  }
}

defineCustomElement(FormView)
