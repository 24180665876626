import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import styles from "./PraxisInput.shadow.css";

// TODO: update initial minlength validation
export class PraxisInput extends _PraxisControlElement {
  public static is = 'praxis-input'

  public static get observedAttributes(): string[] {
    return [
      ..._PraxisControlElement.observedAttributes,
      'maxlength',
      'minlength',
      'placeholder',
      'type',
      'value',
      'readonly',
    ];
  }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    if (this._wrappedElement) {
      this._wrappedElement.value = val;
      this.setAttribute('value', val);
    }
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    if (newValue === null) {
      this._wrappedElement?.removeAttribute(name);
    } else {
      this._wrappedElement?.setAttribute(name, newValue);
    }
    if (name == 'readonly'){
      if (this._wrappedElement){
        if (newValue === null){
          this._wrappedElement.hidden = false;
          this._readonlyDiv.hidden = true;
        }else{
          this._readonlyDiv.textContent = this.value;
          this._wrappedElement.hidden = true;
          this._readonlyDiv.hidden = false;
        }
      }
    }
    this._validate();
  }

  public focus(): void {
    this._wrappedElement?.focus();
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `<style>${styles}</style><div readonly hidden></div><slot></slot>`;

    this._readonlyDiv = shadowRoot.querySelector('div[readonly]') as HTMLDivElement;
  }

  protected _selector = 'input';
  protected _wrappedElement?: HTMLInputElement;

  protected _setupComponent(): void {
    this.addEventListener('input', this._onInput);
  }

  protected _validate(): void {
    if (this._wrappedElement) {
      const { validity } = this._wrappedElement;
      if (validity.badInput){
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_BAD_INPUT;
      } else if (validity.tooLong) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_TOO_LONG(this.getAttribute('maxlength'));
      } else if (validity.tooShort) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_TOO_SHORT(this.getAttribute('minlength'));
      } else if (validity.rangeOverflow) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_RANGE_OVERFLOW(this.getAttribute('max'));
      } else if (validity.rangeUnderflow) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_RANGE_UNDERFLOW(this.getAttribute('min'));
      } else if (validity.valueMissing) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_REQUIRED;
      } else if (validity.patternMismatch) {
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_PATTERN_NOT_MATCH;
      } else {
        this._validationMessage = this._wrappedElement.validationMessage || '';
      }
    } else {
      this._validationMessage = '';
    }
  }

  private _previousValue = '';
  private _readonlyDiv: HTMLDivElement;

  private _onInput() {
    if (this.value !== this._previousValue) {
      this._previousValue = this.value;
      this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
    }
  }
}

window.customElements.define(PraxisInput.is, PraxisInput);
