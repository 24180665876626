import defineCustomElement from "@/Common/utils/defineCustomElement";
import styles from "./PraxisShareRequestNotification.shadow.scss";
import { IShareRequest } from "@/Common/interfaces/IShareRequest";
import { router } from "@/Common/services/router/router";
// import { navigateAndDeleteNotification } from "./PraxisShareRequestNotificationAction.ts";

export class PraxisShareRequestNotification extends HTMLElement {
  public static get is(): string {
    return 'praxis-share-request-notification';
  }

  public load(shareRequest: IShareRequest, direction: "incoming" | "outgoing"): void {
    const {
      sendingFacility,
      receivingFacility,
      participantId,
      projectCode,
      enrollmentId
    } = shareRequest;

    this.attachShadow({ mode: "open" }).innerHTML = `
      <style>${styles}</style>
      <praxis-icon name="${direction == "incoming" ? "input" : "output"}"></praxis-icon>
      <div content>${projectCode.replaceAll('%20', ' ')} - ${participantId} at ${sendingFacility} ${i18n.LABEL_BEING_SHARED} ${receivingFacility}</div>
    `;

    this.addEventListener('click',
      () => router.navigate(`/${projectCode}/sharerequest`)
    );
  }
}

defineCustomElement(PraxisShareRequestNotification);
