export const hasDifferentValues = <T = string>(x: T[] | undefined, y: T[] | undefined): boolean => {
  if (y === undefined || x === undefined){
    return x !== y;
  }
  if (x.length === y.length) {
    const xSorted = x.sort();
    const zSorted = y.sort();
    for (let i = 0; i < x.length; i++) {
      if (xSorted[i] !== zSorted[i]) {
        return true;
      }
    }
    return false;
  } else {
    return true;
  }
}