import { Reducer, CombinedState, ActionFromReducersMapObject, StateFromReducersMapObject } from 'redux';
import { auth } from './auth/auth';
import { errors } from './errors/errors';
import { projects } from './projects/projects';
import { formInstances } from './FormInstances/FormInstances';
import { formGroupInstances} from './FormGroupInstances/FormGroupInstances';
import { outstandingFlags } from '@/Common/reducers/outstandingFlags/outstandingFlags';

export const commonReducers = {
  auth,
  errors,
  projects,
  formGroupInstances,
  formInstances,
  outstandingFlags
}

export type CommonRootState = ReturnType<
Reducer<
CombinedState<StateFromReducersMapObject<typeof commonReducers>>,
ActionFromReducersMapObject<typeof commonReducers>
>
>;
