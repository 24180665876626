import { getToken } from "@/Common/services/dataCenter/report/report";
import { getCert } from "@/Common/services/report/report";
import styles from "./ReportView.shadow.css";
// import { router } from "@/Common/services/router/router";

export class ReportView extends HTMLElement
{
  static get is(): string {
    return 'report-view';
  }

  private container: HTMLDivElement;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `
      <style>${styles}</style>
      <div id="container">
        <praxis-progress-bar class='no-border thin'></praxis-progress-bar>
      </div>
    `;
    this.container = shadowRoot.querySelector('#container') as HTMLDivElement;
  }

  public async connectedCallback() {
    //get report token
    await getToken().then(report => {
      if (report.token) {
        return getCert(report.token)
          .then(() => {
            this.container.innerHTML = `<iframe id="report-iframe" src="${process.env.PRAXIS_CONNECT_REPORT_ORIGIN}/hub">`;
          })
          .catch(() => {
            //Cannot authorize with Qlik Sense Virtual Proxy
            this.container.innerHTML = '<praxis-expandable-info icon="info" expand-label="Reporting server is not available, please try again later. If problem exists, please contact Praxis Connect technical support."></praxis-expandable-info>';
          });
      } else {
        //No token due to no report username or report domain assicated with user
        this.container.innerHTML = '<praxis-expandable-info icon="info" expand-label="You are currently not associated with any reports.">Please contact your project manager to add any report needed.</praxis-expandable-info>';
      }
    }).catch(() => {
      //Praxis Connect DataCenter returning error while fetching jwt
      this.container.innerHTML = '<praxis-expandable-info icon="info" expand-label="Reporting server is not available, please try again later. If problem exists, please contact Praxis Connect technical support."></praxis-expandable-info>';
    });
  }
}

window.customElements.define(ReportView.is, ReportView);
