import { hasDifferentValues } from "@/Common/utils/hasDifferentValues";

export type SupportedInputs = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface IEvaluationCallback {
  (this: any, element: SupportedInputs, result: boolean | number | string): void;
}

interface IField {
  affectedByFieldNames: string[] | null;
  affectingFieldNames?: string[];
  conditionLogic: string | null;
  element: SupportedInputs;
  value: string | number | string[] | number[] | undefined;
  // type: 'date' // TODO: this will be needed to support date related branch logic
}

interface IFields {
  [name: string]: IField | undefined;
}

/**
 * Get all elements' values in the form
 * Get all condition logics and corresponding elements
 * When evaluating element affects the condition logic, trigger evaluationCallback
 */
export class EvaluateLogic {
  private _attributeName: string;
  private _evaluationCallback: IEvaluationCallback;
  private _fields: IFields;
  private _conditionedFieldNames: Set<string>;
  constructor(elements: SupportedInputs[], attributeName: string, applyLogicInStartup: boolean, evaluationCallback: IEvaluationCallback) {
    this._evaluationCallback = evaluationCallback.bind(this);

    this._attributeName = attributeName;
    this._fields = {};
    this._conditionedFieldNames = new Set([]);

    // store all elements' data
    for (const element of elements) {
      const elementName = element.name;
      if (elementName) {
        let value: IField['value'] = undefined;
        if (element.tagName === 'SELECT') {
          const values = Array.from((element as HTMLSelectElement).options)
            .filter(o => o.selected && o.value)
            .map(o => o.value);
          value = values.every(v => typeof +v === 'number')
            ? values.map(v => +v)
            : values;
        } else if (element.value) {
          if (element.type === 'number') {
            value = +element.value;
          } else {
            value = element.value;
          }
        }

        const conditionLogic = element.getAttribute(this._attributeName);
        const affectedByFieldNames = conditionLogic && Array.from(conditionLogic.matchAll(/\^\[(\w+)\]/g)).map(m => m[1]) as string[] || null;

        this._fields[elementName] = {affectedByFieldNames, conditionLogic, element, value};

        if (conditionLogic) {
          this._conditionedFieldNames.add(elementName);
        }
      }
    }

    // apply affectingFieldNames
    for (const fieldName in this._fields) {
      const field = this._fields[fieldName];
      if (field) {
        const { affectedByFieldNames } = field;
        if (affectedByFieldNames && affectedByFieldNames.length) {
          for (const affectedByFieldName of affectedByFieldNames) {
            const affectedByField = this._fields[affectedByFieldName];
            if (affectedByField) {
              if (affectedByField.affectingFieldNames) {
                affectedByField.affectingFieldNames.push(fieldName);
              } else {
                affectedByField.affectingFieldNames = [fieldName];
              }
            }
          }
        }
      }
    }

    if (applyLogicInStartup) {
      this.applyLogicToAllInputs();
    }
  }

  /**
   * apply logic to associated inputs from single target input
   */
  public evaluate(target: SupportedInputs): void {
    let applyingInputs = [];

    if (target.tagName === 'SELECT') {
      if (target.disabled){
        applyingInputs = this._updateClassificationInput(target.name, []);
      }else{
        const values = Array.from((target as HTMLSelectElement).options)
          .filter(o => o.selected)
          .map(o => o.value);
        const value = values.every(v => typeof +v === 'number')
          ? values.map(v => +v)
          : values;
        applyingInputs = this._updateClassificationInput(target.name, value);
      }
    } else {
      applyingInputs = this._updateAnnotationInput(target.name, target.disabled ? '' : target.value);
    }

    this._applyConditions(applyingInputs);
  }

  /**
   * apply logic to all conditioned inputs
   */
  public applyLogicToAllInputs(): void {
    this._applyConditions(this._conditionedFieldNames);
  }

  private _updateAnnotationInput(name: string, value: string | undefined): string[] {
    const field = this._fields[name];
    if (field && field.value !== value) {
      field.value = value;
      if (field.affectingFieldNames) {
        return field.affectingFieldNames;
      }
    }
    return [];
  }

  private _updateClassificationInput(name:string, values: string[] | number[] | undefined): string[] {
    const field = this._fields[name];
    const fieldValue = field?.value == undefined
      ? []
      : Array.isArray(field.value)
        ? field.value
        : [field.value];
    if (field && hasDifferentValues<string|number>(fieldValue as string[] | number[], values)) {
      field.value = values;
      if (field.affectingFieldNames) {
        return field.affectingFieldNames;
      }
    }
    return [];
  }

  private _applyConditions(names: string[] | Set<string>) {
    for (const name of names) {
      const field = this._fields[name];
      if (field) {
        const { affectedByFieldNames, conditionLogic, element } = field;
        if (conditionLogic && affectedByFieldNames) {
          //evaluate condition logic even one of the element is disabled instead of disable the element
          //in order to solve the problem of looping reference
          //e.g. ^[FieldA] reference ^[FieldB] in branch logic and ^[FieldB] reference ^[FieldA] in its branch logic as well

          // if (affectedByFieldNames.some(f => this._fields[f]?.element.disabled)){
          //   this._evaluationCallback(element, false);
          // }else{
          //   const result = this._evaluateConditionLogic(conditionLogic, affectedByFieldNames);
          //   this._evaluationCallback(element, result);
          // }
          const result = this._evaluateConditionLogic(conditionLogic, affectedByFieldNames);
          this._evaluationCallback(element, result);
        }
      }
    }
  }

  private _evaluateConditionLogic(conditionLogic: string, affectedByFieldNames: string[]): boolean | number | string {
    let temp = conditionLogic;
    for (const name of affectedByFieldNames) {
      const field = this._fields[name];
      if (field) {
        const { value } = field;
        // temp = temp.replace(new RegExp(`\\[${name}\\]`,'g'), JSON.stringify(value || ''));
        //temp = temp.replace(new RegExp('\\${' + name + '\\}','g'), JSON.stringify(value || ''));

        switch (typeof value) {
          case 'string':
            temp = temp.replace(`^[${name}]`, `'${value}'`);
            break;

          default:
            temp = temp.replace(`^[${name}]`, JSON.stringify(value || ''));
        }
      }
    }
    temp = (temp.includes('return') ? "" : "return ") + temp;
    try {
      return new Function(temp)();
    } catch (error) {
      console.error(new Error(`Bad condition logic\nerror evaluating "${conditionLogic}" as "${temp}"\n` + error));
      return false;
    }
  }
}