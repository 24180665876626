import defineCustomElement from "@/Common/utils/defineCustomElement";
import styles from "./PraxisDirectEntryNotification.shadow.scss";

export class PraxisDirectEntryNotification extends HTMLElement {
  public static get is(): string {
    return 'praxis-direct-entry-notification';
  }

  private _shadowRoot: ShadowRoot;

  private href = '';
  private notificationId = -1;
  private contentElement: HTMLDivElement;

  public constructor() {
    super();

    this._navigateAndDeleteNotification = this._navigateAndDeleteNotification.bind(this);

    const shadowRoot = this.attachShadow({ mode: 'open' });

    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <praxis-icon name="info"></praxis-icon>
    <div content>
    </div>
    `;
    this._shadowRoot = shadowRoot;
    this.contentElement = shadowRoot.querySelector('[content]') as HTMLDivElement;

    this.addEventListener('click', this._navigateAndDeleteNotification);
  }

  public init(
    participantId: string,
    projectName: string,
    projectCode: string,
    formName: string,
    formCode: string,
    facility: string,
    date: string,
    enrollmentId: number,
    notificationId: number,
    formInstanceId: number){
    this.contentElement.innerHTML = `
    <div class="heading">${i18n.LABEL_PARTICIPANT_SUBMITTED_FORM(participantId)}</div>
    <div>${projectName}</div>
    <div>${formName} (${formCode})</div>
    <div>${facility}</div>
    <div>${i18n.LABEL_FORM_SUBMITTED_ON(date)}</div>
    `;
    this.href = `/${projectCode}/${enrollmentId}/${formInstanceId}`;
    this.notificationId = notificationId;
  }

  // public async connectedCallback(): Promise<void> { }

  private async _navigateAndDeleteNotification() {
    this.dispatchEvent(new CustomEvent(
      "direct-entry-notification-click",
      { detail: { href: this.href, notificationId: this.notificationId } }
    ));
  }
}

defineCustomElement(PraxisDirectEntryNotification);
