import styles from "./PraxisSnackbar.shadow.css";

/**
 * Example
 * ```html
 * <praxis-snackbar>
 *   <div slot="message">The invitation is being sent</div>
 * </praxis-snackbar>
 * ```
 * ```html
 * <praxis-snackbar>
 *   <div slot="message">Send invitation?</div>
 *   <praxis-button slot="buttons">No</praxis-button>
 *   <praxis-button slot="buttons">Yes</praxis-button>
 * </praxis-snackbar>
 * ```
 */
export class PraxisSnackbar extends HTMLElement {
  private DEFAULT_TIMEOUT = 3000;
  private template = `
    <style>${styles}</style>
    <div id="snackbar">
      <div id="message"></div>
    </div>
  `;

  // private template = `
  //   <style>${styles}</style>
  //   <div id="snackbar">
  //     <div id="message"><slot name="message"></slot></div>
  //     <div id="buttons"><slot name="buttons"></slot></div>
  //   </div>
  // `;

  private messageDiv: HTMLDivElement;

  public constructor() {
    super();
    this.close = this.close.bind(this);
    this.dispatchClose = this.dispatchClose.bind(this);
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.addEventListener('close', this.close);

    this.messageDiv = shadowRoot.querySelector('#message') as HTMLDivElement;
  }

  public toast(text: string): void{
    this.messageDiv.innerText = text;
    requestAnimationFrame(() => requestAnimationFrame(() => this.setAttribute('open', '')));
    if (!this.hasAttribute('persistent')) {
      const timeout = Number(this.getAttribute('timeout'));
      setTimeout(this.dispatchClose, isNaN(timeout) || timeout <= this.DEFAULT_TIMEOUT ? this.DEFAULT_TIMEOUT : timeout);
    }
  }

  // private connectedCallback(): void {
  //   requestAnimationFrame(() => requestAnimationFrame(() => this.setAttribute('open', '')));
  //   if (!this.hasAttribute('persistent')) {
  //     const timeout = Number(this.getAttribute('timeout'));
  //     setTimeout(this.dispatchClose, isNaN(timeout) || timeout <= this.DEFAULT_TIMEOUT ? this.DEFAULT_TIMEOUT : timeout);
  //   }
  // }

  private dispatchClose(): void {
    this.dispatchEvent(new Event('close', { bubbles: true }));
  }

  private close(): void {
    this.messageDiv.innerText = '';
    this.removeAttribute('open');
    //setTimeout(() => this.remove(), 1000);
  }
}

window.customElements.define('praxis-snackbar', PraxisSnackbar);
