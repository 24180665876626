import { ISavedFormView } from "@/Common/interfaces/IFormView";
import { secureRequest } from "@/Common/services/dataCenter/secureRequest"

interface IMarkAsCompleteResponse {
  userName: string;
  dateTime: string;
}

interface IMarkAsVerifiedResponse {
  userName: string;
  dateTime: string;
  ownership: string;
  completeness: string;
}

export const putFormMarkAsCompleteService = (formInstanceId: number, note: string): Promise<IMarkAsCompleteResponse> => {
  const payload = JSON.stringify({note: note});
  return secureRequest.request('PUT', `FormInstance/${formInstanceId}/MarkAsComplete`, payload)
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('form complete')));
}

export const putFormMarkAsVerifiedService = (formInstanceId: number, note: string): Promise<IMarkAsVerifiedResponse> => {
  const payload = JSON.stringify({note: note});
  return secureRequest.request('PUT', `FormInstance/${formInstanceId}/MarkAsVerified`, payload)
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('form verify')));
}

export const postFormInstanceService = (data: FormData): Promise<ISavedFormView> => {
  return secureRequest.request('POST', 'FormInstance', data, true)
    .then((response) => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('form instance')));
}