import { IDialogViewElement } from "@/Common/interfaces/IViewElement";
import styles from "./PraxisDialog.shadow.scss";

export class PraxisDialog extends HTMLElement {
  public static get observedAttributes(): string[] {
    return [
      'data-title',
      'view-component-name',
    ];
  }
  private closeButton: HTMLButtonElement;
  private mainElement: HTMLDivElement;
  private titleElement: HTMLDivElement;
  private _loadedView?: IDialogViewElement;
  private template = `
    <style>${styles}</style>
    <div id="dialog">
      <div id="header">
        <praxis-button icon="close" size="normal"></praxis-button>
        <div id="title"></div>
      </div>
      <div id="main"></div>
    </div>
    <div id="backdrop"></div>
  `;

  public set align(value: 'bottom' | 'center' | 'top' ) {
    this.setAttribute('align', value);
  }

  public set justify(value: 'left' | 'center' | 'right') {
    this.setAttribute('justify', value);
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.closeButton = shadowRoot.querySelector('[icon="close"]') as HTMLButtonElement;
    this.mainElement = shadowRoot.querySelector('#main') as HTMLDivElement;
    this.titleElement = shadowRoot.querySelector('#title') as HTMLDivElement;
    this.addEventListeners();
  }

  /**
   * behaves similar to native window.confirm but with async
   */
  public static async confirm(innerHTML: string): Promise<boolean> {
    const dialog = new PraxisDialog();
    dialog.id = 'confirm';
    dialog.align = 'center';
    dialog.justify = 'center';
    document.body.append(dialog)
    dialog.mainElement.innerHTML = `<div id="message">${innerHTML}</div><div id="buttons"><praxis-button id="ok">OK</praxis-button><praxis-button id="cancel">${i18n.BUTTON_CANCEL}</praxis-button></div>`;
    const okButton = dialog.mainElement.querySelector('#ok') as HTMLElement;
    const cancelButton = dialog.mainElement.querySelector('#cancel') as HTMLElement;

    return new Promise<boolean>((resolve) => {
      okButton.addEventListener('click', () => resolve(true));
      cancelButton.addEventListener('click', () => resolve(false));
    }).then(value => {
      dialog.remove();
      return value;
    })
  }

  public static expiringUser(expiresIn: number, callback: (dialog: HTMLElement, expiring: boolean) => void): void {
    const dialog = new PraxisDialog();
    dialog.id = 'expiring';
    dialog.align = 'center';
    dialog.justify = 'center';
    document.body.append(dialog)
    dialog.mainElement.innerHTML = `<div id="message"><p>${i18n.ERROR_MESSAGE_SESSION_EXPIRES_IN(expiresIn)}</p></div><div id="buttons"><praxis-button id="ok">OK</praxis-button></div>`;
    dialog.setAttribute('expiring', '');
    const okButton = dialog.mainElement.querySelector('#ok') as HTMLElement;

    okButton.addEventListener('click', () => {
      const expiring = dialog.hasAttribute('expiring');
      if (expiring){
        clearInterval(interval);
      }
      callback(dialog, expiring);
    });

    const endTime = new Date((new Date()).getTime() + expiresIn * 1000);
    const interval = setInterval(() => {
      if (new Date() > endTime) {
        dialog.removeAttribute('expiring');
        const messageDiv = dialog.mainElement.querySelector('#message');
        if (messageDiv) {
          messageDiv.innerHTML = `<p>${i18n.ERROR_MESSAGE_LOGIN_AGAIN}</p>`;
        }
        clearInterval(interval);
      }
      const expiresIn = dialog.mainElement.querySelector('#expiresIn');
      if (expiresIn) {
        const timeLeft = Math.ceil((endTime.getTime() - new Date().getTime()) / 1000);
        expiresIn.innerHTML = timeLeft.toString();
      }
    }, 1000);
  }

  public loadView(viewComponentName: string, attributes?: { name: string, value: string }[], data?: object, callback?: (e: object) => void): void {
    this.mainElement.innerHTML = `<${viewComponentName} ${
      attributes ? attributes.map(a => `${a.name}="${a.value}"`).join(' ') : ''
    }></${viewComponentName}>`;
    this._loadedView = this.mainElement.querySelector(viewComponentName) as IDialogViewElement;
    if (data){
      this._loadedView.data = data;
    }
    if (callback){
      this._loadedView.callback = callback;
    }
  }

  public setViewAttributes(attributes: {name: string; value: string}[]): void {
    for (const att of attributes) {
      if (this.mainElement.firstElementChild) {
        this.mainElement.firstElementChild.setAttribute(att.name, att.value);
      }
    }
  }

  private addEventListeners(): void {
    this.closeButton.addEventListener('click', () => this.hasAttribute('loading') ? this.hide() : this.close());
    this.mainElement.addEventListener('close', () => this.close());
    this.mainElement.addEventListener('loading', () => this.setAttribute('loading', ''));
    this.mainElement.addEventListener('loaded', () => this.removeAttribute('loading'));
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case 'data-title':
        this.titleElement.textContent = newValue;
        break;
      default:
        break;
    }
  }

  private hide(): void {
    this.setAttribute('hidden', '');
    this.dispatchEvent(new Event('hide', { bubbles: true }));
  }

  public restore(): void {
    this.removeAttribute('hidden');
  }

  private close(): void {
    this.dispatchEvent(new Event('close', { bubbles: true }));
  }
}

window.customElements.define('praxis-dialog', PraxisDialog);
