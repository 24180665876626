
import { PraxisRightPanel } from "@/WebComponents/PraxisRightPanel/PraxisRightPanel";
import styles from "./FieldCommentView.shadow.css"
import { PraxisTextarea } from "@/WebComponents/Controls/PraxisTextarea/PraxisTextarea";
import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import { postFlagCommentFieldService, putFlagCommentFieldStatusService } from "@/Common/services/dataCenter/flagField/flagField";
import { IDialogViewElement } from "@/Common/interfaces/IViewElement";
import { IErrors } from "@/Common/interfaces/IErrors";
import { authService } from "@/Common/services/auth/auth";

export class FieldCommentView extends HTMLElement implements IDialogViewElement {

  static get is(): string {
    return 'flag-field-view';
  }

  private _formVersionInstanceId: number;
  private _fieldName: string;
  private _status: string;
  private _role: string;
  private _closeButton: PraxisButton;
  private _submitButton: PraxisButton;
  private _resolveButton: PraxisButton;
  private _panel: PraxisRightPanel;
  private _textareaElement: PraxisTextarea;
  public callback?: (e:object) => void;

  public set data(comments: {note: string, user: string, date: string}[]){
    const commentsElement = this.shadowRoot?.querySelector('[comments]') as HTMLDivElement;

    if(comments.length) this._resolveButton.disabled = false;

    for(const comment of comments){
      const commentElement = document.createElement('div');
      commentElement.classList.add('comment-container')
      const template = `
        <div class="comment-text">${comment.note}</div>
        <div class="comment-by">${comment.user} ${this.dateFormat(comment.date)}</div>
      `
      commentElement.innerHTML = template;
      commentsElement.append(commentElement);
    }
  }

  private dateFormat(dateISOString: string): string {
    if (!dateISOString) {
      return 'Unknown';
    }
    const date = new Date(dateISOString);
    const month = date.getMonth() +  1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >=  12 ? 'PM' : 'AM';
    const formattedHours = hours %  12 ||  12;
    const formattedMinutes = minutes <  10 ? '0' + minutes : minutes;

    return `${month}/${day}/${year} ${formattedHours}:${formattedMinutes} ${ampm}`
  }

  constructor() {
    super();

    this._formVersionInstanceId = parseInt(this.getAttribute('form-instance-id') || '');
    this._fieldName = this.getAttribute('field-name') || '';
    this._status = this.getAttribute('field-status') || '';
    this._role = this.getAttribute('user-role') || '';

    this._onChangeHandler = this._onChangeHandler.bind(this);
    this._close = this._close.bind(this);
    this._submit = this._submit.bind(this);
    this._submitResolve = this._submitResolve.bind(this);

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <praxis-right-panel no-loading ${PraxisRightPanel.MAIN_ERROR_MESSAGE_ATTRIBUTE}="${i18n.ERROR_MESSAGE_ADD_COMMENT}">
      <div slot='content'>
        <div comments></div>
        <div class="field">
          <div class="label">${i18n.LABEL_ADD_NOTE}</div>
          <div class="comment-input-container">
            <textarea id="comment-input" name="comment-input" maxlength="500"></textarea>
          </div>
        </div>
      </div>
      <div slot='footer'>
        <praxis-button id="close-btn" text>${i18n.BUTTON_CLOSE}</praxis-button>
        <praxis-button id="submit-btn" disabled text>${i18n.BUTTON_POST_NOTE}</praxis-button>
        <praxis-button id="resolve-btn" disabled icon-right="completed" text>${i18n.BUTTON_MARK_AS_RESOLVED}</praxis-button>
      </div>
    </praxis-right-panel>
    `;

    this._closeButton = shadowRoot.querySelector('#close-btn') as PraxisButton;
    this._submitButton = shadowRoot.querySelector('#submit-btn') as PraxisButton;
    this._resolveButton = shadowRoot.querySelector('#resolve-btn') as PraxisButton;
    this._panel = shadowRoot.querySelector('praxis-right-panel') as PraxisRightPanel;
    this._textareaElement = PraxisTextarea.wrap(shadowRoot.querySelector('#comment-input') as HTMLTextAreaElement) as PraxisTextarea;

    this._textareaElement.addEventListener('praxis-input-change', this._onChangeHandler);
    this._closeButton.addEventListener('click', this._close);
    this._submitButton.addEventListener('click', this._submit);
    this._resolveButton.addEventListener('click', this._submitResolve);
  }

  public connectedCallback(){
    if(this._status === 'Resolved') this._resolveButton.textContent = `${i18n.LABEL_MARK_AS_OUTSTANDING}`;
    if(this._role !== 'monitor') this._resolveButton.remove()
  }

  private _onChangeHandler() {
    this._submitButton.disabled = !this._textareaElement.value;
  }

  private _close(){
    this.dispatchEvent(new Event('close', { bubbles: true, composed: true }));
  }

  private async _submit() {
    const comment =  this._textareaElement.value as string;
    const user = await authService.retrieveUserInformation();
    const date = new Date().toISOString();

    try {
      const { hasFlagFromCoordinator, hasFlagFromMonitor } = await (postFlagCommentFieldService(this._formVersionInstanceId, this._fieldName, comment));

      this._panel.doneProgressing();
      if (this.callback) {
        this.callback({ action: 'add-comment', name: this._fieldName, note: comment, user: user?.email, date: date, hasFlagFromMonitor, hasFlagFromCoordinator });
      }
      this._close();
    }
    catch (e) {
      const { msgs, trace } = e as IErrors;
      this._panel.showError(msgs, trace);
    }
  }

  private async _submitResolve() {
    const isOutstanding = this._status === 'Resolved' ? true : false;

    try {
      const { hasFlagFromCoordinator, hasFlagFromMonitor } = await (putFlagCommentFieldStatusService(this._formVersionInstanceId, this._fieldName, isOutstanding));

      this._panel.doneProgressing();
      if (this.callback) {
        this.callback({ action: 'mark-flag', name: this._fieldName, isOutstanding, hasFlagFromCoordinator, hasFlagFromMonitor });
      }
      this._close();
    } catch (e) {
      const { msgs, trace } = e as IErrors;
      this._panel.showError(msgs, trace);
    }
  }
}

window.customElements.define(FieldCommentView.is, FieldCommentView);