import { IProject } from '@/Common/interfaces/IProject';
import { ProjectsActionTypes } from '@/Common/reducers/projects/projects';
import { ThunkAction } from 'redux-thunk';
import { getProjects } from '@/Common/services/dataCenter/projects/projects';
import { CommonRootState } from '@/Common/reducers';

export const getProjectsAction = (): ThunkAction<void, CommonRootState, unknown, ProjectsActionTypes> => async dispatch => {
  await getProjects()
    .then(data => dispatch(loadProjects(data.projects)));
}

export const loadProjects = (projects: IProject[]): ProjectsActionTypes => {
  return { type: 'LOAD_PROJECTS', projects };
}

export const updateProject = (projectCode: string, project: Partial<IProject>): ProjectsActionTypes => {
  return { type: 'UPDATE_PROJECT', projectCode, project };
}