import { AbstractRequest } from "./AbstractRequest";

class AnonymousRequest extends AbstractRequest {
  protected async _newHeaders(): Promise<Headers> {
    const headers = new Headers();
    if (window.PC_DC_Auth.Expired) {
      await window.PC_DC_Auth.Refresh();
    }
    headers.append("Authorization", `Bearer ${window.PC_DC_Auth.token}`);
    return headers;
  }
}

export const anonymousRequest = new AnonymousRequest();