import { PraxisRadio } from "@/WebComponents/Controls/PraxisRadio/PraxisRadio";
import { _PraxisControlElement } from "../../../Controls/_PraxisControlElement/_PraxisControlElement";
import { EvaluateLogic, SupportedInputs } from "../EvaluateLogic";

export class CalculateLogic extends EvaluateLogic {
  constructor(elements: SupportedInputs[]) {
    super(elements, 'calculate-logic', false, function(element, result) {
      element.value = result.toString();

      if (element.getAttribute('is') == 'praxis-radio'){
        const radioWrapper = element.parentElement as PraxisRadio;
        radioWrapper.refresh();
      }

    });
  }
}