import { PraxisCheckbox } from "../PraxisCheckbox/PraxisCheckbox";
import { PraxisInput } from "../PraxisInput/PraxisInput";
import { PraxisRadio } from "../PraxisRadio/PraxisRadio";
import styles from "./PraxisClassificationOther.shadow.css";

export class PraxisClassificationOther extends HTMLElement{
  public static is = 'praxis-classification-other';

  public static wrap(select: HTMLSelectElement, input: HTMLInputElement): PraxisClassificationOther {
    const parent = document.createElement(this.is);
    select.replaceWith(parent);
    const classificationInput = select.getAttribute('is')?.includes('radio')
      ? PraxisRadio.wrap(select)
      : PraxisCheckbox.wrap(select);
    parent.append(classificationInput);
    parent.append(PraxisInput.wrap(input));
    return parent;
  }

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `<style>${styles}</style><slot></slot>`
  }
}

window.customElements.define(PraxisClassificationOther.is, PraxisClassificationOther);
