import styles from "./PraxisProgressBar.shadow.css";

/**
 * Shows progress status.
 * If there is no progress data provided then the bar will be displayed as loader.
 * Example:
 * ```html
 * <praxis-progress-bar label="This label will appear above the progress bar"></praxis-progress-bar>
 * ```
 */
export class PraxisProgressBar extends HTMLElement {
  private static LABEL_ATTRIBUTE = 'label';
  private labelElement: HTMLDivElement;

  private template = `
    <style>${styles}</style>
    <slot></slot>
    <div label class='hide'></div>
    <div progress-bar>
      <div primary></div>
      <div secondary></div>
    </div>
  `;

  private static get observedAttributes(): string[] {
    return [
      PraxisProgressBar.LABEL_ATTRIBUTE,
    ];
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.labelElement = shadowRoot.querySelector('[label]') as HTMLDivElement;
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisProgressBar.LABEL_ATTRIBUTE:
        this.setLabel(newValue);
        break;
    }
  }

  public setLabel(text: string): void {
    if (text == ''){
      this.labelElement.classList.add('hide');
    }else{
      this.labelElement.classList.remove('hide');
      this.labelElement.textContent = text;
    }
  }
}

window.customElements.define('praxis-progress-bar', PraxisProgressBar);
