import styles from "./PraxisFormHeader.shadow.css";
import { PraxisFormMenu } from "../PraxisFormMenu/PraxisFormMenu";
import { PraxisSelect } from "../Controls/PraxisSelect/PraxisSelect";
import { IMarkAsVerifyDetail, IMarkedAsCompleteDetail } from "@/Common/interfaces/IFormInstance";
import { IFormVersion } from "@/Common/interfaces/IFormView";

export class PraxisFormHeader extends HTMLElement {
  private template = `
    <style>${styles}</style>
    <div class="hr" project-name></div>
    <div class="hr" participant-container></div>
    <praxis-form-menu></praxis-form-menu>
    <div form-details>
      <div form-name></div>
      <div form-code></div>
      <div form-version-label></div>
      <div form-version-select></div>
    </div>
    <div form-instance-data>
      <div form-mark-as-complete></div>
      <div form-mark-as-verified></div>
      <div form-direct-entry></div>
    </div>
  `;

  private projectNameElement: HTMLDivElement;
  private participantContainer: HTMLDivElement;
  private formNameElement: HTMLDivElement;
  private formCodeElement: HTMLDivElement;
  private formVersionLabelElement: HTMLDivElement;
  private formMarkAsCompleteElement: HTMLDivElement;
  private formMarkAsVerifiedElement: HTMLDivElement;
  private formDirectEntryElement: HTMLDivElement;
  private versionSelectContainer: HTMLDivElement;
  private lastVersionNumberSend = '';
  public formMenu: PraxisFormMenu;

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.projectNameElement = shadowRoot.querySelector("[project-name]") as HTMLDivElement;
    this.participantContainer = shadowRoot.querySelector("[participant-container]") as HTMLDivElement;
    this.formNameElement = shadowRoot.querySelector("[form-name]") as HTMLDivElement;
    this.formCodeElement = shadowRoot.querySelector("[form-code]") as HTMLDivElement;
    this.formVersionLabelElement = shadowRoot.querySelector("[form-version-label]") as HTMLDivElement;
    this.formMarkAsCompleteElement = shadowRoot.querySelector("[form-mark-as-complete]") as HTMLDivElement;
    this.formMarkAsVerifiedElement = shadowRoot.querySelector("[form-mark-as-verified]") as HTMLDivElement;
    this.formDirectEntryElement = shadowRoot.querySelector("[form-direct-entry]") as HTMLDivElement;
    this.versionSelectContainer = shadowRoot.querySelector("[form-version-select]") as HTMLDivElement;
    this.formMenu = shadowRoot.querySelector("praxis-form-menu") as PraxisFormMenu;
  }

  private set projectName(value: string | undefined) {
    if (value) {
      this.projectNameElement.removeAttribute('hidden');
      this.projectNameElement.textContent = value;
    } else {
      this.projectNameElement.setAttribute('hidden', '');
    }
  }

  private set participantId(value: string | undefined) {
    if (value) {
      this.participantContainer.removeAttribute('hidden');
      this.participantContainer.innerHTML = `ID:<b participantId>${value ?? "not available"}</b>`;
    } else {
      this.participantContainer.setAttribute('hidden', '');
    }
  }

  private set formName(value: string | undefined){
    if (value) {
      this.formNameElement.removeAttribute('hidden');
      this.formNameElement.textContent = value;
    } else {
      this.formNameElement.setAttribute('hidden', '');
    }
  }

  private set formCode(value: string | undefined){
    if (value) {
      this.formCodeElement.removeAttribute('hidden');
      this.formCodeElement.textContent = value;
    } else {
      this.formCodeElement.setAttribute('hidden', '');
    }
  }

  private set formVersionLabel(value: string | undefined){
    if (value) {
      this.formVersionLabelElement.removeAttribute('hidden');
      this.formVersionLabelElement.textContent = value;
    } else {
      this.formVersionLabelElement.setAttribute('hidden', '');
    }
  }

  private set versions(value: IFormVersion[] | undefined) {
    if (value && value.length > 1) {
      const versionSelect = document.createElement('select');
      versionSelect.setAttribute('no-empty', '');
      this.versionSelectContainer.innerHTML = '';
      this.versionSelectContainer.append(versionSelect);

      const versionLabel = this.formVersionLabelElement.textContent;
      this.formVersionLabelElement.setAttribute('hidden', '');
      for (const version of value) {
        const option = document.createElement('option') as HTMLOptionElement;
        option.value = version.number.toString();
        option.textContent = version.label;
        if (version.label == versionLabel) {
          option.setAttribute('selected', '');
        }
        versionSelect.append(option);
      }
      const versionPraxisSelect = PraxisSelect.wrap(versionSelect) as PraxisSelect;
      versionPraxisSelect.addEventListener('praxis-input-change', (e:Event) =>{
        const select = e.currentTarget as PraxisSelect;
        //prevent double send when the selection changes
        if (this.lastVersionNumberSend != select.value){
          this.dispatchEvent(new CustomEvent('praxis-form-version-changed', {detail:{versionNumber: select.value} }));
          this.lastVersionNumberSend = select.value;
        }
      })
    } else {
      this.versionSelectContainer.setAttribute('hidden', '');
    }
  }

  public set markedAsCompleteDetail(value: IMarkedAsCompleteDetail | undefined){
    if (value) {
      this.formMarkAsCompleteElement.removeAttribute('hidden');
      this.formMarkAsCompleteElement.innerHTML = `
        <div>
          <div id="marked-as-complete-message">${i18n.FORM_MARKED_AS_COMPLETED_MESSAGE(value.username, value.dateTime)}</div>
          <div id="marked-as-complete-reason">${i18n.FORM_MARKED_AS_COMPLETED_REASON(value.note)}</div>
        </div>
      `;
    } else {
      this.formMarkAsCompleteElement.setAttribute('hidden', '');
    }
  }

  public set markedAsVerifyDetail(value: IMarkAsVerifyDetail | undefined) {
    if(value) {
      this.formMarkAsCompleteElement.remove();
      if(value.verified) {
        this.formMenu.verifyButtonToggle = false;
        this.formMarkAsVerifiedElement.removeAttribute('hidden');
        this.formMarkAsVerifiedElement.innerHTML = `
          <div>
            <div id="verified-as-complete-message">${i18n.FORM_MARKED_AS_VERIFIED_MESSAGE(value.username, value.dateTime)}</div>
          </div>
        `;
      } else {
        this.formMenu.verifyButtonToggle = true;
        this.formMarkAsVerifiedElement.removeAttribute('hidden');
        this.formMarkAsVerifiedElement.innerHTML = `
          <div>
            <div id="verified-as-complete-message">${i18n.FORM_MARKED_AS_UNVERIFIED_MESSAGE(value.username, value.dateTime)}</div>
            <div id="verified-as-complete-reason">${i18n.FORM_MARKED_AS_UNVERIFIED_REASON(value.note)}</div>
          </div>
        `;
      }
    } else {
      this.formMarkAsVerifiedElement.setAttribute('hidden', '');
    }
  }

  public set directEntryTime(value: string | undefined){
    if (value) {
      this.formDirectEntryElement.removeAttribute('hidden');
      this.formDirectEntryElement.innerHTML = `<praxis-icon name="mail-forward"></praxis-icon>${i18n.FORM_DIRECT_ENTRY_REQUESTED_ON(value)}`;
    } else {
      this.formDirectEntryElement.setAttribute('hidden', '');
    }
  }

  public init(
    projectName?: string,
    participantId?: string,
    formName?: string,
    formCode?: string,
    formLabel?: string,
    directEntryTime?: string,
    markAsCompleteDetail?: IMarkedAsCompleteDetail,
    markedAsVerifyDetail?: IMarkAsVerifyDetail,
    versions?: IFormVersion[]) {
    this.projectName = projectName;
    this.participantId = participantId;
    this.formName = formName;
    this.formCode = formCode;
    this.directEntryTime = directEntryTime;
    this.markedAsCompleteDetail = markAsCompleteDetail;
    this.markedAsVerifyDetail = markedAsVerifyDetail;
    this.formVersionLabel = formLabel;
    this.versions = versions;
  }
}

window.customElements.define('praxis-form-header', PraxisFormHeader);