import { IOutstandingFlag } from "@/Common/interfaces/IFormInstance";
import { secureRequest } from "@/Common/services/dataCenter/secureRequest"

export const postFlagCommentFieldService = (_formVersionInstanceId: number, fieldName: string, comment: string): Promise<{hasFlagFromMonitor:boolean, hasFlagFromCoordinator: boolean}> => {
  const payload = JSON.stringify({comment: comment});
  return secureRequest.request('POST', `FieldValueComment/${_formVersionInstanceId}/${fieldName}`, payload)
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('Flag field')));
}

export const putFlagCommentFieldStatusService = (_formVersionInstanceId: number, fieldName: string, isOutstanding: boolean): Promise<{hasFlagFromMonitor:boolean, hasFlagFromCoordinator: boolean}> => {
  const payload = JSON.stringify({isOutstanding: isOutstanding});
  return secureRequest.request('PUT', `FieldValueComment/${_formVersionInstanceId}/${fieldName}`, payload)
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('Flag field')));
}

export const getOutstandingFlags = (): Promise<{outstandingFlags: IOutstandingFlag[]}> => {
  return secureRequest.request('GET', 'FieldValueComments')
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('Flag field')));
}