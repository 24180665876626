import defineCustomElement from "@/Common/utils/defineCustomElement";
import { IViewElement } from "@/Common/interfaces/IViewElement";
import { RootParticipantState } from "@/ParticipantApp/reducers";

export class ParticipantSummaryView extends HTMLElement implements IViewElement
{
  static get is(): string {
    return 'participant-summary-view';
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `<div>TODO: participant page</div>`;
  }
}

defineCustomElement(ParticipantSummaryView)
