import i18n from "./interface";

const dateFormat = (dateISOString: string | null) => {
  if (!dateISOString) {
    return 'Unknown';
  }
  const date = new Date(dateISOString);
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const dd = date.getDate().toString().padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

/* eslint sort-keys: "error" */
export const en: i18n = {
  ADD_DATA_COLLECTION_POINT_FORM_DESCRIPTION: (minOccurrence, maxOccurrence) => `${minOccurrence === 0 ? 'Optional' : 'Required'}${maxOccurrence > 1 ? ` - Can be added ${maxOccurrence === 2147483647 ? 'multiple' : `up to ${maxOccurrence}`} times` : ''}`,
  BUTTON_ADD: 'Add',
  BUTTON_ADD_NEW_COLLECTION_POINT: 'Add new collection point',
  BUTTON_ALL_DONE_SAVE: 'All done - Save and ',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CHOOSE_FILE: 'Choose file',
  BUTTON_CLOSE: 'Close',
  BUTTON_COPY_LINK_TO_CLIPBOARD: 'Copy link to clipboard',
  BUTTON_CREATE: 'Create',
  BUTTON_CREATE_PROJECT: 'Create new project',
  BUTTON_DELETE: "Delete",
  BUTTON_DIRECT_ENTRY: 'Direct entry',
  BUTTON_EDIT: 'Edit',
  BUTTON_ENROLL: 'Enroll',
  BUTTON_ENROLL_PARTICIPANT: 'Enroll a new participant',
  BUTTON_FLAG: 'Flag',
  BUTTON_FLAGGED: 'Flagged',
  BUTTON_GO_TO_NEXT_SECTION: 'Go to next section',
  BUTTON_IMPORT_LANGUAGE: 'Import language',
  BUTTON_IMPORT_NEW_FORM_VERSION: 'Import new form version',
  BUTTON_INVITE: 'Invite',
  BUTTON_INVITE_NEW_MEMBER: 'Invite new member to this project',
  BUTTON_MARK_AS_COMPLETED: 'Mark as "completed"',
  BUTTON_MARK_AS_RESOLVED: 'Mark as "resolved"',
  BUTTON_MARK_AS_UNVERIFIED: 'Mark as "unverified"',
  BUTTON_MARK_AS_VERIFIED: 'Mark as "verified"',
  BUTTON_POST_NOTE: 'Post Note',
  BUTTON_REVIEW_SHARE_REQUEST: 'Review share request',
  BUTTON_SAVE: 'Save',
  BUTTON_SAVE_GO_TO_NEXT_SECTION: 'Save and go to next section',
  BUTTON_SHARE_PARTICIPANT: 'Share Participant',
  BUTTON_SUBMIT: 'Submit',
  CONFIRMATION_CLEAR_FORM: 'Clearing a form is a permanent action and cannot be undone. All data from the form will be removed and the form will go back to its incomplete state.\n\nDo you want to proceed?',
  CONFIRMATION_DELETE_FILE: 'Deleting a file is a permanent action and cannot be undone.\n\nDo you want to proceed?',
  CONFIRMATION_DELETE_FORM: 'Deleting a form is a permanent action and cannot be undone. All data from the form will be removed and the form will go back to its incomplete state.\n\nDo you want to proceed?',
  CONFIRMATION_DELETE_FORMGROUP: 'Do you wish to delete this data collection point? All data will be deleted for this data collection point.',
  CONFIRMATION_LOGOUT_MESSAGE: 'You have unsaved changes on this page. You can proceed to logout without saving or cancel.',
  CONFIRMATION_SUBMIT_FORM_0: 'You have not completed all of the required fields.',
  CONFIRMATION_SUBMIT_FORM_1: 'Are you sure you want to submit your answers?',
  CONFIRMATION_SUBMIT_FORM_2: 'If not press "Cancel" to return to the form or press "OK" to submit your answers.',
  CONSENT_BANNER_CONSENTED: dateISOString => `<span>Consent obtained</span> on ${dateFormat(dateISOString)}`,
  CONSENT_BANNER_NOT_CONSENTED: dateISOString => `<span>Consent not obtained</span> on ${dateFormat(dateISOString)}`,
  CONSENT_BANNER_PENDING: '<span>Consent pending</span>',
  DELETE_PARTICIPANT: 'Delete',
  DIRECT_ENTRY_BUTTON_GENERATE_LINK: 'Generate link',
  DIRECT_ENTRY_BUTTON_REMOVE_LINK: 'Remove link',
  DIRECT_ENTRY_DESCRIPTION_GENERATE_LINK: 'Press the "generate link" button to create a web link you can share with the participant for them to complete the form.',
  DIRECT_ENTRY_DESCRIPTION_REMOVE_LINK: 'Press the "remove link" button to delete the link. The link will become inactive and the participant will lose the ability to complete the form.',
  DIRECT_ENTRY_HEADING_GENERATE_LINK: 'Share direct entry link with participant',
  DIRECT_ENTRY_HEADING_REMOVE_LINK: 'Remove direct entry link and cancel the direct entry request',
  ERROR_404_PAGE_NOT_FOUND: 'The content you\'re seeking could not be retrieved from our servers. Please verify your request and try again later. If the problem continues, please consult with Praxis Connect Support.',
  ERROR_ADD_DCP: 'We were not able to add the data collection point to the project. We found the following issues:',
  ERROR_CONTENT_UNAVAILABLE: 'Content Unavailable.',
  ERROR_EDIT_DCP: 'We were not able to edit the data collection point. We found the following issues:',
  ERROR_MESSAGE_ADD_COMMENT: 'We were not able to add comment. We found the following issues:',
  ERROR_MESSAGE_CHOOSE_FACILITIES: 'Please choose facilities',
  ERROR_MESSAGE_CREATE_PROJECT: 'We were not able to create the project. We found the following issues:',
  ERROR_MESSAGE_ERRORS_IN_FORM: 'We found the following errors in the form:',
  ERROR_MESSAGE_FIELD_BAD_INPUT: 'Input format is not valid',
  ERROR_MESSAGE_FIELD_INVALID: 'This field is invalid',
  ERROR_MESSAGE_FIELD_PATTERN_NOT_MATCH: 'Input pattern is not valid',
  ERROR_MESSAGE_FIELD_RANGE_OVERFLOW: val => val ? `Invalid value. Please provide a number less or equal to ${val}` : 'Invalid value. Please provide a lower value',
  ERROR_MESSAGE_FIELD_RANGE_UNDERFLOW: val => val ? `Invalid value. Please provide a number greater or equal to ${val}` : 'Invalid value. Please provide a greater value',
  ERROR_MESSAGE_FIELD_REQUIRED: 'This field is required',
  ERROR_MESSAGE_FIELD_TOO_LONG: val => val ? `Input cannot be longer than ${val}` : 'Input is too long',
  ERROR_MESSAGE_FIELD_TOO_SHORT: val => val ? `Input must be longer than ${val}` : 'Input is too short',
  ERROR_MESSAGE_FILE_TOO_LARGE: val => `File is too large. Please select a file smaller than ${val}MB`,
  ERROR_MESSAGE_FILE_TYPE_INVALID: val => `File should be of type ${val}`,
  ERROR_MESSAGE_LOGIN_AGAIN: `Your session has expired. Please click OK to login again.<br><small>Login dialog will be opened in a popup after OK is clicked.</small>`,
  ERROR_MESSAGE_MARK_FORM_AS_COMPLETE: 'We were not able to mark the form as complete. We found the following issues:',
  ERROR_MESSAGE_MARK_FORM_AS_VERIFIED: 'We were not able to mark the form as verified. We found the following issues:',
  ERROR_MESSAGE_NO_RESULTS: 'No results found.',
  ERROR_MESSAGE_SESSION_EXPIRES_IN: val => `<p>Your session expires in <span id="expiresIn" style="font-weight:bold">${val}</span> seconds. Please click OK to continue.</p>`,
  ERROR_MESSAGE_UNEXPECTED: 'Unexpected error has occurred',
  ERROR_SERVER_404_NOT_FOUND: 'The requested resource was not found in our server',
  ERROR_SERVER_PROCESS_DEFAULT: val => `Unexpected error has occurred while processing ${val} in our server`,
  ERROR_SERVER_RETRIEVE_DEFAULT: val => `Unexpected error has occurred while retrieving ${val} in our server`,
  ERROR_SERVER_WITH_TRACEID: val => `Unexpected error occurred, please contact Praxis Connect Admin with this id (${val})`,
  FORM_COMPLETED: 'Completed',
  FORM_DIRECT_ENTRY_REQUESTED_ON: dateISOString => `Direct entry requested on ${dateFormat(dateISOString)}`,
  FORM_INCOMPLETE: 'Incomplete',
  FORM_MARKED_AS_COMPLETED: 'Marked as completed',
  FORM_MARKED_AS_COMPLETED_MESSAGE: (username, dateISOString) => `This form was marked as "Complete" by ${username} on ${dateFormat(dateISOString)}`,
  FORM_MARKED_AS_COMPLETED_REASON: (reason) => `Reason: ${reason}`,
  FORM_MARKED_AS_UNVERIFIED: 'Marked as unverified',
  FORM_MARKED_AS_UNVERIFIED_MESSAGE: (username, dateISOString) => `This form was marked as "Unverified" by ${username} on ${dateFormat(dateISOString)}`,
  FORM_MARKED_AS_UNVERIFIED_REASON: (reason) => `Reason: ${reason}`,
  FORM_MARKED_AS_VERIFIED_MESSAGE: (username, dateISOString) => `This form was marked as "Verified" by ${username} on ${dateFormat(dateISOString)}. You cannot edit or delete it.`,
  FORM_PARTIALLY_COMPLETED: 'Partially completed',
  FORM_SAVE_DISCARD_CHANGES: '<p>You have unsaved changes. If you proceed, any changes you’ve made will be lost. Are you sure you want to continue?</p>',
  FORM_SAVE_WARNING_DUPLICATE_FIELDS: 'The participant you are attempting to enroll contains key attributes similar to existing participants. Do you want to proceed?',
  FORM_VERIFIED: 'Verified',
  FORM_VERSION_CHANGE: '<div>By opening a different form version you will lose the information you have already entered.</div><br/><div>Do you want to proceed?</div>',
  IDENTITY_ROLE: {
    'Praxis Connect Coordinator': 'Coordinator',
    'Praxis Connect Monitor': 'Monitor',
    'Praxis Connect Project Manager': 'Project Manager',
  },
  LABEL_ABOUT_ROLES_DESCRIPTION: "Praxis Connect supports three different user roles. Each role will have their own set of interfaces. <br /> Coordinator: They collect data for the project by entering into Praxis Connect. They can also request participants to enter data themselves by generating \"direct entry requests\".<br /> Project Manager: They can modify the project's detail and invite other members to the project.<br /> Monitor: Can view deidentified project data, in read only form, to determine its quality. They can also generate flags to request coordinator's review.",
  LABEL_ABOUT_ROLES_HIDE: 'Learn more about roles',
  LABEL_ABOUT_ROLES_SHOW: 'Hide role information',
  LABEL_ACTIVE_USERS: 'active user(s)',
  LABEL_ACTIVITY_SUMMARY: 'My activity summary',
  LABEL_ACTIVITY_SUMMARY_HEADING: 'Participants in your facilities with open records:',
  LABEL_ADD_ANOTHER_ROLE: 'Add another role',
  LABEL_ADD_DCP: 'Add a data collection point',
  LABEL_ADD_FILES: 'Add files',
  LABEL_ADD_NOTE: 'Add a note:',
  LABEL_ADD_OPTIONAL_FORMS: 'Add optional forms',
  LABEL_ALL_CAUGHT_UP: 'You Are All Caught Up!',
  LABEL_BEING_SHARED: 'is being shared with',
  LABEL_CHOOSE_ROLE: 'Choose a role',
  LABEL_CONFIRM_CLOSE_MESSAGE: 'There are unsaved changes on the page. Are you sure you want to leave ?',
  LABEL_CREATE_PROJECT: 'Create a new project',
  LABEL_DATE_OF_ENROLLMENT: 'Date of enrollment',
  LABEL_DCP_NOT_MATCHING: 'Data collection points not matching the current consent status',
  LABEL_DCP_SELECT_FACILITY: 'Select the facility or group responsible for the data collection',
  LABEL_DCP_START_DATE: 'Date in which data collection is expected to start',
  LABEL_DELETE_BTN: 'Delete this label',
  LABEL_DELETE_PARTICIPANT: 'Delete this participant',
  LABEL_DISABLED_USERS: 'disabled user(s)',
  LABEL_EDIT_DCP: 'Edit Data Collection Point',
  LABEL_END_USER_AGREEMENT: 'End User Agreement',
  LABEL_END_USER_LICENSE_AGREEMENT: 'End User License Agreement',
  LABEL_ENROLLING_FACILITY_OR_GROUP: 'Enrolling facility or group',
  LABEL_ENROLLMENT_DATE: 'Enrollment Date',
  LABEL_EXISTING_PARTICIPANT_ID: 'Existing participant ID',
  LABEL_FACILITY_RECEIVING_PARTICIPANT: 'Facility receiving the participant',
  LABEL_FACILITY_SHARING_PARTICIPANT: 'Facility sharing the participant',
  LABEL_FILES: ' files',
  LABEL_FILTER_FACILITIES_GROUPS: 'Filter facilities and groups',
  LABEL_FLAG_FIELD_FOR: 'Flag Notes For ',
  LABEL_FLAG_REQUIRING_ATTENTION: (flag) => `You have ${flag} flag(s) requiring your attention.`,
  LABEL_FOLLOW_PARTICIPANT_INFO: 'Following a participant gives you quick access to it from the dashboard. You can start following a participant by navigating to it and tapping the <praxis-icon name="add-user"></praxis-icon> Follow button at the top-right corner of the interface.',
  LABEL_FORMS: 'Forms',
  LABEL_FORM_SUBMITTED_ON: date => `Form submitted on: ${date}`,
  LABEL_FOR_COORDINATORS: 'For Coordinators',
  LABEL_FOR_PROJECT_MANAGERS: 'For Project Managers',
  LABEL_FOR_PROJECT_MONITORS: 'For Project Monitors',
  LABEL_GATHERING_INFORMATION: 'Gathering Information',
  LABEL_HAS_ACCESS_TO_PARTICIPANT: 'Has access to this participant',
  LABEL_IMPORT_FORM_VERSION: 'Import form versions to project',
  LABEL_IMPORT_PROJECT_LANGUAGE: 'Import project language',
  LABEL_INCOMING_SHARE_REQUEST: 'Incoming share requests',
  LABEL_INVITE_MEMBER: 'Invite member to project',
  LABEL_I_AGREE: 'I agree',
  LABEL_LABEL_TEXT: 'Label Text',
  LABEL_LANGUAGES: 'Languages',
  LABEL_LAST_UPDATED: 'Last Updated Date',
  LABEL_LAST_UPDATED_ON: date => `Last updated on: ${date}`,
  LABEL_LOADING: 'Loading', // TODO: was not in mockup
  LABEL_LOADING_FORM: 'Loading form', // TODO: was not in mockup
  LABEL_LOADING_PRAXIS_CONNECT: 'Loading Praxis Connect',
  LABEL_LOGOUT: 'Logout',
  LABEL_MANAGE_LABEL: 'Manage labels',
  LABEL_MARK_AS_OUTSTANDING: 'Mark as Outstanding',
  LABEL_MARK_FORM_AS_COMPLETED: 'Mark form as "completed"',
  LABEL_MARK_FORM_AS_UNVERIFIED: 'Mark form as "unverified"',
  LABEL_MARK_FORM_AS_VERIFIED: 'Mark form as "verified"',
  LABEL_NOTIFICATIONS: 'Notifications:',
  LABEL_NOT_FOLLOWING_PARTICIPANTS: 'You are currently not following any participants.',
  LABEL_NOT_FOUND: 'Not found',
  LABEL_NO_DCP: 'There are no available data collection points for this participant',
  LABEL_NO_FILE_CHOSEN: 'No file chosen',
  LABEL_NO_INCOMING_SHARE_REQUEST: 'There are no incoming share requests.',
  LABEL_NO_NEW_TYPE_TO_REVIEW: type => `There are no new ${type} for you to review.`,
  LABEL_NO_OUTGOING_SHARE_REQUEST: 'There are no outgoing share requests.',
  LABEL_OUTGOING_SHARE_REQUEST: 'Outgoing share requests',
  LABEL_OUTSTANDING_FLAGS: 'Outstanding Flags:',
  LABEL_PARTICIPANT_CONTACT_ADDITIONAL_PHONE_NUMBER: 'Additional phone number',
  LABEL_PARTICIPANT_CONTACT_ADDRESS: 'Address',
  LABEL_PARTICIPANT_CONTACT_CITY: 'City',
  LABEL_PARTICIPANT_CONTACT_COMMENTS: 'Comments',
  LABEL_PARTICIPANT_CONTACT_COUNTRY: 'Country',
  LABEL_PARTICIPANT_CONTACT_EMAIL: 'Email',
  LABEL_PARTICIPANT_CONTACT_NAME: 'Name',
  LABEL_PARTICIPANT_CONTACT_PHONE_NUMBER: 'Phone number',
  LABEL_PARTICIPANT_CONTACT_POSTAL_CODE: 'Postal code',
  LABEL_PARTICIPANT_CONTACT_PROVINCE: 'Province',
  LABEL_PARTICIPANT_CONTACT_TYPE: 'Type',
  LABEL_PARTICIPANT_ID: 'Participant Id:',
  LABEL_PARTICIPANT_SHARE_DESC: 'When participants are shared by or with your facility or group, they are shown here until the share request has been dealt with. Participant records are usually shared when a participant moves to a different facility or group and the ethics in your project allow the sharing of information.',
  LABEL_PARTICIPANT_SUBMITTED_FORM: participantId => `A participant (${participantId}) has submitted the form`,
  LABEL_PARTICIPANT_TO_BE_DELETED: "Deleting a participant is a permanent action. Once you delete the record you will NOT be able to recoer the information.",
  LABEL_PENDING_SHARE_REQUESTS: 'Pending share requests:',
  LABEL_PENDING_USERS: 'pending user(s)',
  LABEL_PROJECTS: 'Projects',
  LABEL_PROJECT_CODE: 'Project code',
  LABEL_PROJECT_DESCRIPTION: 'Description (optional)',
  LABEL_PROJECT_MEMBERS: 'Project members',
  LABEL_PROJECT_NAME: 'Project name',
  LABEL_QUESTIONNAIRE_NOT_FOUND: 'Questionnaire not found',
  LABEL_QUESTIONNAIRE_NOT_FOUND_MESSAGE: 'The questionnaire you are trying to access is not available for direct entry anymore',
  LABEL_QUESTIONNAIRE_SUBMIT_MESSAGE: `Thank you for taking the time <br/> to complete the questionnaire.`,
  LABEL_REASON_FORM_NOT_COMPLETED: 'Please select the reason why the form was not originally completed',
  LABEL_SEARCH: 'Search',
  LABEL_SELECT_DCP: 'Select the data collection points you want to add:',
  LABEL_SELECT_EXCEL_FORM_VERSION_FILE: 'Select the Excel file with the form version definition',
  LABEL_SELECT_EXCEL_LANGUAGE_FILE: 'Select the Excel file with the language definition',
  LABEL_SELECT_EXCEL_PROJECT_FILE: 'Select the Excel file with the project definition',
  LABEL_SELECT_FACILITIES_GROUPS: 'Select facilities or groups',
  LABEL_SELECT_FILES_TO_BE_ADDED: 'Select the files to be added to',
  LABEL_SELECT_FORMS_TO_BE_ADDED: 'Select the forms to be added to',
  LABEL_SEND_FORM_TO_PARTICIPANT: 'Send form to participant',
  LABEL_SHARE_PARTICIPANT: 'Share Participant',
  LABEL_SHARE_REQUEST_NOTIFICATION: notifications =>  `You have ${notifications} offer(s) to share participants with your facility.`,
  LABEL_SHARE_REQUEST_SENT: 'Share request has been sent',
  LABEL_SPECIFY_ROLE: 'Please specify at least one role',
  LABEL_USE_EXISTING_PARTICIPANT_ID: 'Use an existing participant ID (i.e. for a participant who has been previously assigned a study ID by another application or study administrator)',
  LABEL_YOU_HAVE_NOTIFICATIONS: notifications =>  `You have ${notifications} notification(s)`,
  MENU_ADD_FILES: 'Add files',
  MENU_ADD_OPTIONAL_FORMS: 'Add optional forms',
  MENU_ASSIGN_FORM: 'Assign this form to a team member',
  MENU_CLEAR_FORM: 'Clear all form contents',
  MENU_CLOSE_FORM: 'Close this form',
  MENU_DELETE_DCP: 'Delete this data collection point',
  MENU_DELETE_FORM: 'Delete this form',
  MENU_DIRECT_ENTRY: 'Direct entry',
  MENU_EDIT_DETAILS: 'Edit details',
  MENU_EDIT_FORM: 'Edit this form',
  MENU_MARK_COMPLETED: 'Mark this form as completed',
  MENU_MARK_VERIFIED: 'Mark this form as verified',
  MENU_PRINT_FORM: 'Print this form',
  MENU_SAVE_FORM: 'Save this form',
  MENU_SET_LABEL: 'Set label',
  OPTION_CHOOSE_A_FACILITY_OR_GROUP: 'Choose a facility or group',
  PARTICIPANT_SEARCH_ENROLLMENT_DATE: dateISOString => `Enrollment date: ${dateFormat(dateISOString)}`,
  PARTICIPANT_SEARCH_LAST_UPDATED: dateISOString => `Last updated: ${dateFormat(dateISOString)}`,
  SECTION_DEFAULT_ENROLLMENT_DETAIL: 'Enrollment detail',
  SECTION_DEFAULT_PARTICIPANT_CONTACT_DETAIL: 'Contact information',
  SNACKBAR_ALL_FILE_UPLOAD_SUCCESS: 'Files has been uploaded successfully',
  SNACKBAR_COPIED_TEXT: 'Copied text',
  SNACKBAR_ERROR_WHILE_SAVING: 'Error while saving',
  SNACKBAR_FAILED_TO_SUBMIT_CHECK_ERROR_MESSAGE: 'Failed to submit. Please check the error message at top of the screen.', // TODO: was not in mock up
  SNACKBAR_FAILED_TO_UPDATE_SHARE_REQUEST: errorMessage => `Failed to update. ${errorMessage}`,
  SNACKBAR_FILE_HAS_BEEN_DELETED: 'File has been deleted',
  SNACKBAR_FILE_HAS_BEEN_UPDATED: 'File has been updated',
  SNACKBAR_FILE_NAME_EXISTS: 'File name exists',
  SNACKBAR_FLAG_FIELD_COMMENT_POSTED: 'Flag field comment posted',
  SNACKBAR_FORM_GROUP_HAS_BEEN_DELETED: 'Data collection point has been deleted',
  SNACKBAR_FORM_HAS_BEEN_CLEARED: 'Form has been cleared',
  SNACKBAR_FORM_HAS_BEEN_DELETED: 'Form has been deleted',
  SNACKBAR_FORM_HAS_BEEN_SAVED: 'Form has been saved',
  SNACKBAR_FORM_LABEL_HAS_BEEN_CREATED: 'Label has been created',
  SNACKBAR_NEW_ENROLLMENT_HAS_BEEN_ADDED: 'New enrollment has been added',
  SNACKBAR_NEW_FORM_HAS_BEEN_ADDED: 'New form has been added',
  SNACKBAR_NEW_PARTICIPANT_HAS_BEEN_ADDED: 'New participant has been added',
  SNACKBAR_PROJECT_LANGUAGE_IMPORTED: 'Language has been imported',
  SNACKBAR_PROJECT_LANGUAGE_IMPORT_FAILED: 'Language import failed',
  SNACKBAR_SHARE_REQUEST_ACCEPTED: 'Sharing request accepted.',
  SNACKBAR_SHARE_REQUEST_REJECTED: 'Sharing request rejected.',
  SNACKBAR_SHARE_REQUEST_SENT: 'Share request sent successfully',
  SNACKBAR_SHARE_REQUEST_WITHDRAWN: 'Sharing request withdrawn.',
  SNACKBAR_UNKNOWN_ERROR: 'Unknown error',
  TITLE_ENROLLMENT_DETAILS: 'Enrollment Details',
}