import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
import { PraxisInputElement } from "../PraxisInputElement/PraxisInputElement";

export class PraxisSelectWithFilter extends PraxisInputElement {
  public static DISABLED_ATTRIBUTE = 'disabled';
  public static LABEL_ATTRIBUTE = 'label';
  public static PLACEHOLDER_ATTRIBUTE = 'placeholder';
  public static SORT_ATTRIBUTE = 'sort';
  public static get observedAttributes(): string[] {
    return [
      PraxisSelectWithFilter.DISABLED_ATTRIBUTE,
      PraxisSelectWithFilter.LABEL_ATTRIBUTE,
      PraxisSelectWithFilter.PLACEHOLDER_ATTRIBUTE,
    ];
  }

  public get value(): string[] {
    return (Array.from(this._shadowRoot
      .querySelectorAll('input[type="checkbox"]:not(#filtered-items)')) as HTMLInputElement[])
      .filter(i => i.checked).map(i => i.value);
  }
  public set value(values: string[]) {
    const inputs = Array.from(this._shadowRoot.querySelectorAll('input[type="checkbox"]:not(#filtered-items)')) as HTMLInputElement[];
    for (const i of inputs) {
      i.checked = values.includes(i.value);
      i.dispatchEvent(new Event('change'));
    }
  }
  private template = `
    <style>
      :host(:not([hidden])){
        display:block;
        font-size:12px;
      }
      #label-container{
        background-color: #FFF;
        border: 1px solid #999;
        color: #666;
        cursor: pointer;
        display:flex;
        justify-content: space-between;
        padding: 8px;
      }
      #search-container{
        display: flex;
        align-items: center;
        padding: 4px;
        border-bottom: 1px solid #ccc;
      }
      #search-input-container{
        background-color: #0000000c;
        display: flex;
        height: 16px;
        padding: 8px;
        flex-grow: 1;
      }
      #search{
        background-color: transparent;
        border: none;
        flex-grow: 1;
        font-size: 12px;
        outline: none;
      }
      #search::placeholder{
        color:#666;
        opacity: 1;
      }
      [type="checkbox"]{
        margin: 0 12px 0 8px;
        width: 16px;
        height: 16px;
      }
      #search-input-container > praxis-icon{
        cursor: pointer;
        font-size: 8px;
      }
      div#options {
        max-height: 150px;
        overflow-y: auto;
      }
      div#options.no-scroll{
        overflow-y: inherit;
        max-height: none;
      }
      div#options > div{
        align-items: center;
        display: flex;
        height: 36px;
        padding: 4px;
      }
      div#options > div > label{
        flex-grow: 1;
        margin-left: 10px;
        width: 0;
      }

      :host(:not(.collapsed)) #dropdown{
        background-color: #FFF;
        border: 1px solid #aaa;
        border-top: 0;
        box-shadow: 0px 3px 6px #00000029;
      }
      :host(.collapsed) div#options{
        overflow-y: inherit;
        max-height: none;
      }
      :host(.collapsed) div#search-container,
      :host(.collapsed) div#options > div:not(.checked),
      :host div#options > div.filtered{
        display: none;
      }
    </style>
    <div id="label-container">
      <div id="label">Choose...</div>
      <praxis-icon name="add"></praxis-icon>
    </div>
    <div id="dropdown">
      <div id="search-container">
        <input type="checkbox" id="filtered-items" name="filtered-items"/>
        <div id="search-input-container">
          <input id="search" placeholder="Search..."/>
          <praxis-icon name="close"></praxis-icon>
        </div>
      </div>
      <div id="options"></div>
    </div>
    <slot name="option"></slot>
  `;

  public errorMessages: string[] = [];
  private _shadowRoot: ShadowRoot;
  private labelElement: HTMLDivElement;
  private searchInputElement: HTMLInputElement;
  private expandAndCollapseIcon: PraxisIcon;
  private searchContainer: HTMLDivElement;
  private searchCheckboxElement: HTMLInputElement;
  private optionDivs: HTMLDivElement[];

  private get isCollapsed(): boolean{
    return this.classList.contains('collapsed')
  }
  private set isCollapsed(value: boolean){
    if (value){
      this.classList.add('collapsed');
      this.expandAndCollapseIcon.setAttribute('name', 'add')
    }else{
      this.classList.remove('collapsed');
      this.expandAndCollapseIcon.setAttribute('name', 'check-mark')
    }
  }

  constructor() {
    super();
    this.onSearchCheckboxChanged = this.onSearchCheckboxChanged.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onToggleCollapsed = this.onToggleCollapsed.bind(this);
    this.toggledDisabled = this.toggledDisabled.bind(this);
    this.onOptionCheckboxChanged = this.onOptionCheckboxChanged.bind(this);
    this.validate = this.validate.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    this._shadowRoot = shadowRoot;
    shadowRoot.innerHTML = this.template;
    this.labelElement = shadowRoot.querySelector('#label') as HTMLDivElement;
    this.searchInputElement = shadowRoot.querySelector('#search') as HTMLInputElement;
    this.expandAndCollapseIcon = shadowRoot.querySelector('#label-container>praxis-icon') as PraxisIcon;
    this.searchContainer = shadowRoot.querySelector('#search-container') as HTMLDivElement;
    this.searchCheckboxElement = shadowRoot.querySelector('#search-container>input') as HTMLInputElement;

    //move light dom to shadow dom so that we can style and get events
    const options = shadowRoot.querySelector('slot[name="option"]') as HTMLSlotElement;
    this.optionDivs = options.assignedElements().sort((a, b) => {
      const labelAElement = a.querySelector('label');
      const labelBElement = b.querySelector('label');
      if (!labelAElement || !labelAElement.textContent) {
        return 1;
      }
      if (!labelBElement || !labelBElement.textContent) {
        return -1;
      }
      const labelA = labelAElement.textContent.trim();
      const labelB = labelBElement.textContent.trim();
      if (this.getAttribute(PraxisSelectWithFilter.SORT_ATTRIBUTE) === 'asc') {
        return labelA.localeCompare(labelB);
      } else if (this.getAttribute(PraxisSelectWithFilter.SORT_ATTRIBUTE) === 'desc') {
        return labelB.localeCompare(labelA);
      } else {
        return 0;
      }
    }) as HTMLDivElement[];
    const optionsDiv = shadowRoot.querySelector('#options') as HTMLElement;
    this.optionDivs.forEach(e => {
      const input = e.querySelector('input[type="checkbox"]') as HTMLInputElement;
      input.addEventListener("change", this.onOptionCheckboxChanged);
      optionsDiv.appendChild(e);
    })
    options.remove();

    //label
    const labelContainer = shadowRoot.querySelector('#label-container');
    if (labelContainer) {
      labelContainer.addEventListener("click", this.onToggleCollapsed);
    }

    //search input
    this.searchInputElement.addEventListener("keyup", this.onSearchInputChange);

    //clear search string
    const clearSearchBtn = shadowRoot.querySelector('#search-input-container>praxis-icon') as PraxisIcon;
    clearSearchBtn.addEventListener("click", this.clearSearchInput);

    //search container checkbox
    this.searchCheckboxElement.addEventListener("click", this.onSearchCheckboxChanged);

    this.isCollapsed = true;

    this.addEventListeners();
  }

  public validate(): void {
    this.errorMessages = [];

    this.valid = true;
    if (this.hasAttribute('required') && this.value.length === 0) {
      this.errorMessages.push(this.getAttribute('required-error-message') || i18n.ERROR_MESSAGE_FIELD_REQUIRED)
    }

    if (this.errorMessages.length > 0) {
      this.valid = false;
      this.dispatchEvent(new Event('invalid'));
    }
  }

  private addEventListeners(): void {
    // this.addEventListener('blur', this.validate);
  }

  private onToggleCollapsed(): void{
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed){
      this.searchInputElement.focus();
      this.searchCheckboxElement.checked = this.optionDivs.every(o => o.classList.contains('checked'));
    }else{
      this.clearSearchInput();
    }
  }

  private updateSearchCheckboxElement(): void {
    const unfilteredOptions = this.optionDivs.filter(o => !o.classList.contains('filtered'));
    this.searchCheckboxElement.checked = unfilteredOptions.length > 0 &&
      unfilteredOptions.every(o => o.classList.contains('checked'));
  }

  private onOptionCheckboxChanged(e: Event): void{
    const checkbox = e.target as HTMLInputElement;
    const { parentElement } = checkbox;
    if (parentElement) {
      if (checkbox.checked){
        parentElement.classList.add('checked');
      } else {
        parentElement.classList.remove('checked');
      }
    }
    this.updateSearchCheckboxElement();
    this.validate();
    this.dispatchEvent(new Event('change'));
  }

  private onSearchInputChange(e: Event): void{
    const textInput = e.target as HTMLInputElement;
    this.filterOptions(textInput.value);
    this.updateSearchCheckboxElement();
  }

  private clearSearchInput(): void{
    this.searchInputElement.value = '';
    this.filterOptions('');
    this.updateSearchCheckboxElement();
  }

  private filterOptions(search: string): void{
    this.optionDivs.forEach(e => {
      const labelElement = e.querySelector('label') as HTMLLabelElement;
      if (search == '' || labelElement.innerHTML.match(new RegExp(search.trim(), 'gi'))){
        e.classList.remove('filtered');
      }else{
        e.classList.add('filtered');
      }
    })
  }

  private onSearchCheckboxChanged(): void{
    const checked = this.searchCheckboxElement.checked;
    this.optionDivs.forEach((o: HTMLElement) => {
      if (!o.classList.contains('filtered')) {
        const optionCheckboxElement = o.querySelector('input') as HTMLInputElement;
        optionCheckboxElement.checked = checked;
        optionCheckboxElement.dispatchEvent(new Event('change'));
      }
    });
  }

  private toggledDisabled(): void {
    if (this.hasAttribute(PraxisSelectWithFilter.DISABLED_ATTRIBUTE)) {
      this.searchCheckboxElement.setAttribute('disabled', '');
      this.optionDivs.forEach(o => o.querySelector('input')?.setAttribute('disabled', ''));
    } else {
      this.searchCheckboxElement.removeAttribute('disabled');
      this.optionDivs.forEach(o => o.querySelector('input')?.removeAttribute('disabled'));
    }
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisSelectWithFilter.DISABLED_ATTRIBUTE:
        this.toggledDisabled();
        break;
      case PraxisSelectWithFilter.LABEL_ATTRIBUTE:
        this.labelElement.textContent = newValue;
        break;
      case PraxisSelectWithFilter.PLACEHOLDER_ATTRIBUTE:
        this.searchInputElement.setAttribute('placeholder', newValue);
        break;
    }
  }
}

// Define the new element
window.customElements.define(
  "praxis-select-with-filter",
  PraxisSelectWithFilter
);
