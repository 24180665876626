import { PraxisProfilePicture } from "@/WebComponents/PraxisProfilePicture/PraxisProfilePicture";
import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import styles from "./PraxisHeaderBar.shadow.css";

export class PraxisHeaderBar extends HTMLElement {
  private menuElement: PraxisButton;
  private searchElement: PraxisButton;
  private profilePictureElement: PraxisProfilePicture;
  private headingElement: HTMLHeadingElement;
  private template = `
    <style>${styles}</style>
    <praxis-button id="menu" alt="Menu" icon="global-navigation-button"></praxis-button>
    <h1></h1>
    <praxis-button id="search" alt="Search" icon="search" hidden></praxis-button>
    <praxis-profile-picture id="profile-picture" hidden></praxis-profile-picture>
  `;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.profilePictureElement = shadowRoot.querySelector('#profile-picture') as PraxisProfilePicture;
    this.menuElement = shadowRoot.querySelector('#menu') as PraxisButton;
    this.searchElement = shadowRoot.querySelector('#search') as PraxisButton;
    this.headingElement = shadowRoot.querySelector('h1') as HTMLHeadingElement;

    this.addEventListeners();
  }

  private addEventListeners(): void {
    this.menuElement.addEventListener('click', () => this.dispatchEvent(new Event('toggle-side', { bubbles: true })));
  }

  public updateTitle(title: string): void {
    this.headingElement.textContent = title;
  }

  public updateProfileIcon(firstName: string, lastName: string): void {
    const initials = (firstName[0] || '') + (lastName[0] || '');
    if (initials) {
      this.profilePictureElement.setAttribute('initials', initials);
      this.profilePictureElement.hidden = false;
    }
  }

  public addSearchButtonEventListener(type: string, listener: (e: Event) => void): void {
    this.searchElement.addEventListener(type, listener);
    this.searchElement.hidden = false;
  }
}

window.customElements.define('praxis-header-bar', PraxisHeaderBar);
