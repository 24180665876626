import { PraxisSelectWithAnnotation } from '@/WebComponents/Controls/PraxisSelectWithAnnotation/PraxisSelectWithAnnotation';
import { _PraxisControlElement } from '../../Controls/_PraxisControlElement/_PraxisControlElement';
import { PraxisSectionNavigationButton } from './PraxisSectionNavigationButton';
import styles from './PraxisSectionNavigationControl.shadow.css';
import { ISNCSCIControl } from '@/WebComponents/Controls/ISNCSCIControl/ISNCSCIControl';

interface IInput {
  disabled: boolean;
  element: _PraxisControlElement;
  empty: boolean;
  required: boolean;
  valid: boolean;
  sectionElement: HTMLElement;
}

interface IInputHashMap {
  [inputName: string]: IInput
}

interface ISectionHashMap {
  [sectionName: string]: {
    button: PraxisSectionNavigationButton;
    inputs: IInputHashMap;
    sectionElement: HTMLElement;
  };
}
type SupportedInputs = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | ISNCSCIControl;
export class PraxisSectionNavigationControl extends HTMLElement {
  public static get observedAttributes(): string[] {
    return [
      'readonly',
    ];
  }
  public currentSectionId = 1;
  public length = 1;
  private _navigationElement: HTMLDivElement;
  private _nameElement: HTMLDivElement;
  private _sectionHashMap: ISectionHashMap = {};
  private _allSections: string[] = [];
  private _allInputs: IInputHashMap = {};
  private _formShadowRoot: ShadowRoot|undefined;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `<style>${styles}</style><div id="name"></div><div id="navigation"></div>`;
    this._nameElement = shadowRoot.getElementById('name') as HTMLDivElement;
    this._navigationElement = shadowRoot.getElementById('navigation') as HTMLDivElement;
    this._nameElement.addEventListener('click', this._showNextInvalidInput.bind(this))
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'readonly':
        this._setReadonly(this.hasAttribute(name));
        break;
    }
  }

  public connectedCallback(): void {
    this._formShadowRoot = this.getRootNode() as ShadowRoot;
  }

  public load(sections: HTMLElement[], excludeIdentifierField?: boolean): void {
    const section = sections[0];
    if (section) {
      const name = section.getAttribute('name') || '';
      const button = new PraxisSectionNavigationButton();
      this.length = sections.length;
      this._navigationElement.appendChild(button);

      if (sections.length === 1) {
        this._nameElement.hidden = true;
        button.label = name;
        button.classList.add('single');
      } else {
        const displayIndex = 1;
        this._nameElement.textContent = `${displayIndex}: ${name}`;
        button.label = '' + displayIndex;
        button.classList.add('current');
        // button.addEventListener('click', () => this._buttonClickHandler(displayIndex));
        section.id = '' + displayIndex;
      }
      button.addEventListener('click', () => this._buttonClickHandler(1));
      this._setupSection(section, button, excludeIdentifierField);
      this._updateSectionProgress(section);

      for (let index = 1; index < sections.length; index++) {
        const sectionElement = sections[index];
        const displayIndex = index + 1;

        const button = new PraxisSectionNavigationButton();
        button.label = '' + displayIndex;
        button.addEventListener('click', () => this._buttonClickHandler(displayIndex));
        this._navigationElement.appendChild(button);

        if (sectionElement) {
          sectionElement.hidden = true;
          sectionElement.id = sectionElement.id || '' + displayIndex;
          this._setupSection(sectionElement, button, excludeIdentifierField);
          this._updateSectionProgress(sectionElement);
        }
      }
    }
  }

  private _buttonClickHandler(displayIndex: number) {
    if (this.currentSectionId === displayIndex) {
      this._showNextInvalidInput();
    } else {
      this.updateSectionSelection(displayIndex);
    }
  }

  public updateSectionSelection(displayIndex: number): void {
    const currentSection = this._sectionHashMap[this.currentSectionId];
    if (currentSection) {
      currentSection.button.classList.remove('current');
      currentSection.sectionElement.hidden = true;
    }

    const displayedSection = this._sectionHashMap[displayIndex];
    if (displayedSection) {
      const { button, sectionElement } = displayedSection;
      const name = sectionElement.getAttribute('name') || '';
      this._nameElement.textContent = `${displayIndex}: ${name}`;
      button.classList.add('current');
      sectionElement.hidden = false;
      this.currentSectionId = displayIndex;
    }

    this.dispatchEvent(new Event('change'));
  }

  public scrollToFlagSection(fieldName: string) {
    const field = this._formShadowRoot?.querySelector(`div[is="praxis-field"][name="${fieldName}"]`);
    const sectionIndex = Number(field?.closest('section')?.getAttribute('id'));
    this.updateSectionSelection(sectionIndex)
    field?.scrollIntoView({ behavior: 'smooth' });
  }

  private _setupSection(sectionElement: HTMLElement, button: PraxisSectionNavigationButton, excludeIdentifierField?: boolean) {
    location.hash === '#new' && sectionElement.setAttribute('new', '');
    const identifierFilter = excludeIdentifierField ? ':not([identifier])' : '';
    let rawInputs = Array.from(sectionElement.querySelectorAll(`input${identifierFilter},select${identifierFilter},textarea${identifierFilter}`)) as SupportedInputs[];
    const annotations = Array.from(sectionElement.querySelectorAll(`praxis-select-with-annotation${identifierFilter}`)) as PraxisSelectWithAnnotation[];
    if (annotations.length){
      const annotationInputs = annotations.map(a => a.annotationInputs);
      rawInputs = rawInputs.concat(...annotationInputs);
    }

    const inputs: IInputHashMap = {};
    for(const rawInput of rawInputs){
      const element = this._getControlElement(rawInput);
      if (element){
        const { disabled, required, validationMessage, value } = element;
        const empty = value.length === 0;
        const input: IInput = {
          disabled,
          element,
          empty,
          required,
          valid: validationMessage === '',
          sectionElement: sectionElement
        };
        inputs[rawInput.name] = input;
        this._allInputs[rawInput.name] = input;
        element.addEventListener('praxis-input-change', (e) => this.updateInput(e.target as HTMLElement));
      }
    }

    this._allSections.push(sectionElement.id);
    this._sectionHashMap[sectionElement.id] = { button, inputs, sectionElement }
  }

  public updateInput(element: HTMLElement){
    const praxisControlElement = this._getControlElement(element);
    if (praxisControlElement) {
      const input = this._allInputs[praxisControlElement.name];

      if (praxisControlElement) {
        const { disabled, required, validationMessage, value } = praxisControlElement;
        if (input) {
          input.empty = value.length === 0;
          input.valid = validationMessage === '';
          input.required = required;
          input.disabled = disabled;
        }
      }

      const sectionElement = input?.sectionElement;
      if (sectionElement) {
        this._updateSectionProgress(sectionElement);
      }
    }

    //this.updateAllSection();
  }

  // public updateAllSection(){
  //   for(const sectionName of this._allSections){
  //     const section =  this._sectionHashMap[sectionName]?.sectionElement as HTMLElement
  //     if (section.hasAttribute('new')) {
  //       section.removeAttribute('new');
  //     }
  //     this._updateSectionProgress(section);
  //   }
  // }

  private _updateSectionProgress(sectionElement: HTMLElement, e?: Event) {
    // if (sectionElement.hasAttribute('new')) {
    //   // sectionElement.removeAttribute('new');
    //   return;
    // }
    if (e) {
      const target = e.composedPath().length > 0
        ? e.composedPath()[0] as HTMLElement
        : e.target as HTMLElement;
      if (target){
        this.updateInput(target)
      }
    }

    const section = this._sectionHashMap[sectionElement.id];
    if (section) {
      const options: ButtonUpdateOptions
        = Object.values(section.inputs)
          .reduce(
            (p, input) => ({
              count: p.count + (input.required && !input.disabled && !input.empty && input.valid ? 1 : 0), // TODO && input.valid is not needed if value is correct
              totalCount: p.totalCount + (input.required && !input.disabled ? 1 : 0),
              invalidInput: p.invalidInput || (!input.valid ? input.element : null),
            }),
            { count: 0, totalCount: 0 } as ButtonUpdateOptions,
          );
      section.button.update(options);
    }
  }

  private _getControlElement(target: HTMLElement, depth = 0): _PraxisControlElement | null {
    const { tagName, parentElement } = target || {};
    if (depth > 4 || !target) {
      return null;
    } else if (tagName.startsWith('PRAXIS-SELECT')) {
      return (tagName === 'PRAXIS-SELECT-SINGLE' ? target : this._getControlElement(parentElement as HTMLElement, depth + 1)) as _PraxisControlElement;
    } else if (tagName.startsWith('ISNCSCI')){
      return (tagName === 'ISNCSCI-CONTROL' ? target : this._getControlElement(parentElement as HTMLElement, depth + 1)) as _PraxisControlElement;
    } else {
      return (tagName.startsWith('PRAXIS') ? target : this._getControlElement(parentElement as HTMLElement, depth + 1)) as _PraxisControlElement;
    }
  }

  private _showNextInvalidInput() {
    const invalidInputs = [];

    let elementToFocus: HTMLElement | undefined= undefined;
    let focusNextInvalidElement = false;

    const section = this._sectionHashMap[this.currentSectionId] || this._sectionHashMap[''];
    if (section) {
      for (const { element, valid } of Object.values(section.inputs)) {
        if (!valid) {
          if (focusNextInvalidElement) {
            elementToFocus = element;
            break;
          }
          invalidInputs.push(element);
        }
        if (element.classList.contains('highlighted')) {
          element.classList.remove('highlighted');
          focusNextInvalidElement = true;
        }
      }
    }
    elementToFocus = elementToFocus ?? invalidInputs[0];

    if (elementToFocus) {
      elementToFocus.focus();
      const scrollTo = elementToFocus.closest('[is="praxis-field"')?.querySelector('praxis-error-message') ?? elementToFocus;
      scrollTo.scrollIntoView({ block: 'center', behavior: "smooth" });
      elementToFocus.classList.add('highlight', 'highlighted');
      setTimeout(() => {
        elementToFocus?.classList.remove('highlight');
      }, 500);
    }
  }

  private _setReadonly(readonly: boolean) {
    if (readonly) {
      for (const button of this._navigationElement.children) {
        button.setAttribute('readonly', '');
      }
    } else {
      for (const button of this._navigationElement.children) {
        button.removeAttribute('readonly');
      }
    }
  }
}
type ButtonUpdateOptions = Parameters<InstanceType<(typeof PraxisSectionNavigationButton)>['update']>[0];

customElements.define('p-s-n-c', PraxisSectionNavigationControl);