import { PraxisContextMenuItem } from "./PraxisContextMenuItem";
import styles from "./PraxisContextMenu.shadow.css";

export class PraxisContextMenu extends HTMLElement {
  private blurOnTriggerElement = false;

  constructor() {
    super();
    this.hide = this.hide.bind(this);

    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.innerHTML =
    `<style>${styles}</style><slot></slot>`;

    this.hidden = true;
    this.tabIndex = 0;
  }

  public addDivider(): void {
    this.appendChild(document.createElement('div'));
  }

  public addMenuItem(label: string, icon: string, listener: () => void): PraxisContextMenuItem {
    const item = new PraxisContextMenuItem();
    item.setAttribute('icon', icon);
    item.setAttribute('label', label);
    item.addEventListener('click', e => {
      e.stopImmediatePropagation();
      this.hide();
      listener();
    });
    this.appendChild(item);

    return item;
  }

  public hide(): void {
    this.hidden = true;
  }

  /**
   * TODO: if needed, revise implementation to support to prevent context menu appearing overflow out of viewport
   */
  public show(x?: number, y?: number): void {
    if (x && y && typeof x === 'number' && typeof y === 'number') {
      this.style.left = `${x}px`;
      this.style.top = `${y}px`;
    }
    this.hidden = false;
    this.focus();
  }

  public registerTrigger(triggerElement: HTMLElement, type: 'click' | 'contextmenu' = 'click'): void {
    if (type === 'contextmenu') {
      // show on contextmenu event
      triggerElement.addEventListener(type, e => {
        e.preventDefault();
        if (this.hidden) {
          const {left, top} = triggerElement.getBoundingClientRect();
          const {borderLeftWidth, borderTopWidth} = getComputedStyle(triggerElement);
          const {x, y} = {
            x: e.clientX - left - parseInt(borderLeftWidth),
            y: e.clientY - top - parseInt(borderTopWidth),
          }
          this.show(x, y);
        } else {
          this.hide()
        }
      });
      this.addEventListener('blur', this.hide);
    } else {
      // show on click event
      triggerElement.addEventListener('mousedown', () => this.blurOnTriggerElement = !this.hidden)
      triggerElement.addEventListener(type, () => {
        if (this.hidden) {
          this.show();
        } else {
          this.hide()
        }
      });
      this.addEventListener('blur', () => {
        if (!this.blurOnTriggerElement) {
          this.hide();
        }
      })
    }
  }
}

window.customElements.define('praxis-context-menu', PraxisContextMenu);