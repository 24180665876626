import { PraxisButton } from "../PraxisButton/PraxisButton";
import styles from "./PraxisErrorMessage.shadow.css";

export class PraxisErrorMessage extends HTMLElement {
  public static CONTAINER_ATTRIBUTE = 'container';
  public static COLLAPSIBLE_ATTRIBUTE = 'collapsible';
  public static MAIN_MESSAGE_ATTRIBUTE = 'main-message';
  public static get observedAttributes(): string[] {
    return [
      PraxisErrorMessage.MAIN_MESSAGE_ATTRIBUTE,
      PraxisErrorMessage.COLLAPSIBLE_ATTRIBUTE,
      PraxisErrorMessage.CONTAINER_ATTRIBUTE,
    ];
  }
  public count = 0;
  private errorsElement: HTMLDivElement;
  private mainMessageElement: HTMLDivElement;
  private toggleCollapseButtonElement: PraxisButton;

  public set isCritical(value: boolean) {
    if (value) {
      this.setAttribute('is-critical', '');
    } else {
      this.removeAttribute('is-critical');
    }
  }

  public get isCritical(): boolean {
    return this.hasAttribute('is-critical');
  }

  private template = `
    <style>${styles}</style>
    <div primary>
      <praxis-icon name="error"></praxis-icon>
      <div id="main-message" hidden></div>
      <praxis-button icon="chevron-down" hidden></praxis-button>
    </div>
    <div id="errors"></div>
  `;

  public constructor() {
    super();
    this.toggleCollapse = this.toggleCollapse.bind(this);
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.mainMessageElement = shadowRoot.querySelector('#main-message') as HTMLDivElement;
    this.errorsElement = shadowRoot.querySelector('#errors') as HTMLDivElement;
    this.toggleCollapseButtonElement = shadowRoot.querySelector('praxis-button') as PraxisButton;

    this.toggleCollapseButtonElement.addEventListener('click', this.toggleCollapse);
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisErrorMessage.MAIN_MESSAGE_ATTRIBUTE:
        this.setMainMessage(newValue);
        break;
      case PraxisErrorMessage.COLLAPSIBLE_ATTRIBUTE:
        if (this.hasAttribute(PraxisErrorMessage.COLLAPSIBLE_ATTRIBUTE)) {
          this.errorsElement.setAttribute('hidden', '');
          this.mainMessageElement.removeAttribute('hidden');
          this.toggleCollapseButtonElement.removeAttribute('hidden');
        } else {
          this.errorsElement.removeAttribute('hidden');
          this.mainMessageElement.setAttribute('hidden', '');
          this.toggleCollapseButtonElement.setAttribute('hidden', '');
        }
        break;
      case PraxisErrorMessage.CONTAINER_ATTRIBUTE:
        if (this.hasAttribute(PraxisErrorMessage.CONTAINER_ATTRIBUTE)) {
          this.errorsElement.removeAttribute('hidden');
          this.mainMessageElement.removeAttribute('hidden');
          this.toggleCollapseButtonElement.setAttribute('hidden', '');
        } else {
          this.errorsElement.removeAttribute('hidden');
          this.mainMessageElement.setAttribute('hidden', '');
          this.toggleCollapseButtonElement.setAttribute('hidden', '');
        }
        break;
    }
  }

  private setMainMessage(text: string): void {
    this.mainMessageElement.textContent = text;
  }

  public addErrors(texts: string[]): void {
    for (const text of texts) {
      this.addError(text);
    }
  }

  public addError(text: string): void {
    const errorElement = document.createElement('div');
    errorElement.innerHTML = text;
    this.errorsElement.appendChild(errorElement);
    this.count++;
  }

  public addTrace(text: string): void{
    const errorElement = document.createElement('div');
    errorElement.classList.add('trace');
    errorElement.textContent = text;
    this.errorsElement.appendChild(errorElement);
    this.count++;
  }

  public clear(): void {
    this.count = 0;
    this.errorsElement.innerHTML = '';
  }

  public hide(): void {
    this.setAttribute('hidden', '');
  }

  public show(): void {
    this.removeAttribute('hidden');
  }

  public toggleCollapse(): void {
    if (this.errorsElement.hasAttribute('hidden')) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public expand(): void {
    this.errorsElement.removeAttribute('hidden');
    this.toggleCollapseButtonElement.setAttribute('icon','chevron-up');
  }

  public collapse(): void {
    this.errorsElement.setAttribute('hidden', '');
    this.toggleCollapseButtonElement.setAttribute('icon','chevron-down');
  }
}

window.customElements.define('praxis-error-message', PraxisErrorMessage);
