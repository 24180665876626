import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import styles from "./MarkAsCompletedView.shadow.css";
import { putFormMarkAsCompleteService } from "@/Common/services/dataCenter/form/form";
import { PraxisRightPanel } from "@/WebComponents/PraxisRightPanel/PraxisRightPanel";
import { PraxisRadio } from "@/WebComponents/Controls/PraxisRadio/PraxisRadio";
import { PraxisTextarea } from "@/WebComponents/Controls/PraxisTextarea/PraxisTextarea";

const REASONS: { [value: string]: string } = {
  "1": 'No further information was available',
  "2": 'Not permitted due to REB or IRB',
  "3": 'Lost to follow-up',
  "4": 'Participant declined to answer all questions',
}

export class MarkAsCompletedView extends HTMLElement {
  static get is(): string {
    return 'mark-as-completed-view';
  }

  private _panel: PraxisRightPanel;
  private _selectElement: PraxisRadio;
  private _textareaElement: PraxisTextarea;

  private _closeButton: PraxisButton;
  private _submitButton: PraxisButton;

  private _formInstanceId: number;
  public callback?: (e:object) => void;

  public constructor() {
    super();
    this._onChangeHandler = this._onChangeHandler.bind(this);
    this._toggleTextArea = this._toggleTextArea.bind(this);
    this._close = this._close.bind(this);
    this._submit = this._submit.bind(this);
    this._formInstanceId = parseInt(this.getAttribute('form-instance-id') || '');

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <praxis-right-panel no-loading ${PraxisRightPanel.MAIN_ERROR_MESSAGE_ATTRIBUTE}="${i18n.ERROR_MESSAGE_MARK_FORM_AS_COMPLETE}">
      <div slot='content'>
        <div class="field">
          <div class="label">${i18n.LABEL_REASON_FORM_NOT_COMPLETED}</div>
          <select is="praxis-radio" name="reason" id="reason">
            <option value="1">${REASONS['1']}</option>
            <option value="2">${REASONS['2']}</option>
            <option value="3">${REASONS['3']}</option>
            <option value="4">${REASONS['4']}</option>
            <option value="99">Other</option>
          </select>
          <div class="other-text-container">
            <textarea id="other_reason" name="other_reason" disabled branch-logic="[reason]==99" maxlength="500"></textarea>
          </div>
        </div>
      </div>
      <div slot='footer'>
        <praxis-button id="close-btn" text>${i18n.BUTTON_CLOSE}</praxis-button>
        <praxis-button id="submit-btn" disabled text>${i18n.BUTTON_MARK_AS_COMPLETED}</praxis-button>
      </div>
    </praxis-right-panel>
    `;

    this._closeButton = shadowRoot.querySelector('#close-btn') as PraxisButton;
    this._submitButton = shadowRoot.querySelector('#submit-btn') as PraxisButton;
    this._panel = shadowRoot.querySelector('praxis-right-panel') as PraxisRightPanel;
    this._selectElement = PraxisRadio.wrap(shadowRoot.querySelector('#reason') as HTMLSelectElement) as PraxisRadio;
    this._textareaElement = PraxisTextarea.wrap(shadowRoot.querySelector('#other_reason') as HTMLTextAreaElement) as PraxisTextarea;

    this._addEventListeners();
  }

  private _addEventListeners() {
    this._closeButton.addEventListener('click', this._close);
    this._submitButton.addEventListener('click', this._submit);

    this._selectElement.addEventListener('praxis-input-change', this._onChangeHandler);
    this._selectElement.addEventListener('praxis-input-change', this._toggleTextArea);
    this._textareaElement.addEventListener('praxis-input-change', this._onChangeHandler);
  }

  private _toggleTextArea() {
    this._textareaElement.disabled = this._selectElement.value !== '99';
  }

  private _onChangeHandler() {
    const reason = this._selectElement.value as string;
    const otherText = this._textareaElement.value as string;
    this._submitButton.disabled = !reason || (reason == '99' && !otherText);
  }

  private _close() {
    this.dispatchEvent(new Event('close', { bubbles: true, composed: true }));
  }

  private async _submit() {
    const reason = this._selectElement.value as string;
    const otherText = this._textareaElement.value as string;

    let note: string;
    if (reason === '99') {
      const trimmed = otherText.trim();
      note = trimmed ? `Other (${trimmed})` : 'Other';
    } else {
      note = REASONS[reason] || '';
      if (note === '') {
        this._panel.showError([`Reason (${reason}) is not defined`], '');
        return;
      }
    }

    this._panel.startProgressing();

    const {userName, dateTime} = await putFormMarkAsCompleteService(this._formInstanceId, note);

    this._panel.doneProgressing();

    if (this.callback){
      this.callback({ formInstanceId: this._formInstanceId, dateTime: dateTime, note: note || '', username: userName});
    }

    this._close();
    this.dispatchEvent(new CustomEvent("open-snack-bar",
      { bubbles: true, composed: true, detail: { text: i18n.FORM_MARKED_AS_COMPLETED } }));
  }
}

window.customElements.define(MarkAsCompletedView.is, MarkAsCompletedView);