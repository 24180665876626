import { IUser, Roles } from '@/Common/interfaces/IUser';
// import { auth } from '@/Common/reducers/auth/auth';
// import { IRole } from '@/ProjectManagerApp/interfaces/IRole';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
// import { stringify } from 'querystring';

class AuthService {
  private userManager?: UserManager;

  public configure(config: UserManagerSettings) {
    this.userManager = this.userManager || new UserManager({
      prompt: "login",
      response_type: "code",
      scope: "profile openid praxisconnect_datacenter",
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      loadUserInfo: true,
      automaticSilentRenew: false,
      ...config,
    });
    this.userManager.stopSilentRenew();
    this.userManager.clearStaleState();
  }

  public async signinRedirect(): Promise<void> {
    if (location.pathname === '/') {
      localStorage.removeItem('redirectPath');
    } else {
      localStorage.setItem('redirectPath', location.pathname);
    }
    if (this.userManager) {
      this.userManager.signinRedirect();
    } else {
      console.error('this.userManager not defined');
    }
  }

  public async signinSilent(): Promise<IUser | null>{
    if (this.userManager) {
      this.userManager.removeUser();
      await this.userManager.revokeTokens(["access_token"]);
      const user = await this.userManager.signinSilent();
      if (!user) {
        return await this.signinPopup();
      }
      return user ? this.getUserInformation(user) : null;
    } else {
      console.error('this.userManager not defined');
      return Promise.resolve(null);
    }
  }

  public async signinPopup(): Promise<IUser | null>{
    if (this.userManager){
      this.userManager.removeUser();
      await this.userManager.revokeTokens(["access_token"]);
      const user = await this.userManager.signinPopup();
      return this.getUserInformation(user);
    }else{
      console.error('this.userManager not defined');
      return Promise.resolve(null);
    }
  }

  public async retrieveUserInformation(): Promise<IUser | null> {
    if (this.userManager) {
      const user = await this.userManager.getUser();
      if (!!user && !user.expired) {
        return this.getUserInformation(user);
      }
      return Promise.resolve(null);
    } else {
      console.error('this.userManager not defined');
      return Promise.resolve(null);
    }
  }

  private getUserInformation(user: User): IUser {
    return {
      accessToken: user.access_token,
      firstName: user.profile.firstName as string,
      lastName: user.profile.lastName as string,
      email: user.profile.email ? user.profile.email : '',
      role: (typeof user.profile.role === "string")
        ? user.profile.role as Roles
        : user.profile.role as Roles[],
      expires_in: user.expires_in,
      username: `${user.profile.firstName}, ${user.profile.lastName}`
    }
  }

  public async signoutRedirect(): Promise<void> {
    if (this.userManager) {
      const user = await this.userManager.getUser();
      if (user && !user.expired) {
        return this.userManager.signoutRedirect();
      } else {
        window.location.href = `${location.href}`;
        return Promise.resolve();
      }
    } else {
      console.error('this.userManager not defined');
      return Promise.resolve();
    }
  }
}

export const authService = new AuthService();