import { PraxisErrorMessage } from "../PraxisErrorMessage/PraxisErrorMessage";
import { PraxisInputElement } from "../PraxisInputElement/PraxisInputElement";
import { PraxisButton } from "../PraxisButton/PraxisButton";
import styles from "./PraxisInputContainer.shadow.css";

const listOfSupportedInputs = ['praxis-input','praxis-select','praxis-select-with-filter'];
export class PraxisInputContainer extends HTMLElement {
  public static LABEL_ATTRIBUTE = 'label';
  public static HELPER_TEXT_ATTRIBUTE = 'helper-text';
  public static REMOVABLE_ATTRIBUTE = 'removable';
  public static get observedAttributes(): string[] {
    return [
      PraxisInputContainer.LABEL_ATTRIBUTE,
      PraxisInputContainer.HELPER_TEXT_ATTRIBUTE,
      PraxisInputContainer.REMOVABLE_ATTRIBUTE,
    ];
  }

  public get slottedInputs(): PraxisInputElement[] {
    return this.slotElement.assignedElements()
      .filter(e => listOfSupportedInputs.some(tag => new RegExp(e.tagName,'i').test(tag))) as PraxisInputElement[];
  }

  public valid?: boolean;

  private errorContainerElement: HTMLLabelElement;
  private errorMessageElement: PraxisErrorMessage;
  private labelElement: HTMLLabelElement;
  private slotElement: HTMLSlotElement;
  private helperTextElement: HTMLDivElement;
  private deleteButtonElement: PraxisButton;
  private template = `
    <style>${styles}</style>
    <div id="main">
      <label id="error-container" hidden>
        <praxis-error-message></praxis-error-message>
      </label>
      <label id="label" hidden></label>
      <slot></slot>
      <div id="helper-text" hidden></div>
    </div>
    <div>
      <praxis-button type="text" icon="delete" hidden></praxis-button>
    </div>
  `;

  public constructor() {
    super();
    this.displayErrors = this.displayErrors.bind(this);
    this.validate = this.validate.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.errorMessageElement = shadowRoot.querySelector('praxis-error-message') as PraxisErrorMessage;
    this.labelElement = shadowRoot.querySelector('#label') as HTMLLabelElement;
    this.slotElement = shadowRoot.querySelector('slot') as HTMLSlotElement;
    this.helperTextElement = shadowRoot.querySelector('#helper-text') as HTMLDivElement;
    this.errorContainerElement = shadowRoot.querySelector('#error-container') as HTMLLabelElement;
    this.deleteButtonElement = shadowRoot.querySelector('praxis-button') as PraxisButton;
  }

  public validate(): void {
    this.errorContainerElement.setAttribute('hidden', '');
    this.errorMessageElement.clear();
    for (const input of this.slottedInputs) {
      input.validate();
    }
  }

  private connectedCallback(): void {
    this.addEventListeners();
  }

  private addEventListeners(): void {
    for (const input of this.slottedInputs) {
      input.addEventListener('invalid', this.displayErrors);
      input.addEventListener('change', this.displayErrors);
    }
    this.deleteButtonElement.addEventListener('click', () => this.dispatchEvent(new Event('remove')));
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisInputContainer.LABEL_ATTRIBUTE:
        this.setLabel(newValue);
        break;
      case PraxisInputContainer.HELPER_TEXT_ATTRIBUTE:
        this.setHelperText(newValue);
        break;
      case PraxisInputContainer.REMOVABLE_ATTRIBUTE:
        this.setRemovable();
        break;
    }
  }

  private displayErrors(): void {
    this.errorContainerElement.setAttribute('hidden', '');
    this.errorMessageElement.clear();

    this.valid = true;
    for (const input of this.slottedInputs) {
      if (!input.hidden) {
        for (const errorMessage of input.errorMessages) {
          this.valid = false;
          this.errorMessageElement.addError(errorMessage);
        }
      }
    }

    if (!this.valid) {
      this.errorContainerElement.removeAttribute('hidden');
    }
  }

  private setLabel(value: string): void {
    if (value) {
      this.labelElement.removeAttribute('hidden');
      this.labelElement.textContent = value;
    } else {
      this.labelElement.setAttribute('hidden', '');
    }
  }

  private setHelperText(value: string): void {
    if (value) {
      this.helperTextElement.removeAttribute('hidden');
      this.helperTextElement.textContent = value;
    } else {
      this.helperTextElement.setAttribute('hidden', '');
    }
  }

  private setRemovable(): void {
    if (this.hasAttribute(PraxisInputContainer.REMOVABLE_ATTRIBUTE)) {
      if (this.getAttribute(PraxisInputContainer.REMOVABLE_ATTRIBUTE) === 'disabled') {
        this.deleteButtonElement.setAttribute('disabled', '');
      } else {
        this.deleteButtonElement.removeAttribute('disabled');
      }
      this.deleteButtonElement.removeAttribute('hidden');
    } else {
      this.deleteButtonElement.setAttribute('hidden', '');
    }
  }
}

window.customElements.define('praxis-input-container', PraxisInputContainer);
