import { IRouteConfig } from "@/Common/services/router/router";

const routes: IRouteConfig[] = [
  // {
  //   route: '/',
  //   title: 'Participant summary',
  //   viewComponentName: 'participant-summary-view',
  // },
  {
    route: '/DirectEntry/Form/:code',
    title: 'Participant direct entry',
    viewComponentName: 'form-view',
  },
  {
    route: '/ThankYou',
    title: 'Participant direct entry',
    viewComponentName: 'thank-you-view',
  },
];

export default routes;
