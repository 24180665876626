import { PraxisIcon } from "@/WebComponents/PraxisIcon/PraxisIcon";
import styles from "./PraxisSideBarListItem.shadow.css";

export class PraxisSideBarListItem extends HTMLElement {
  private static ICON_ATTRIBUTE = 'icon';
  private static LETTER_ICON_ATTRIBUTE = 'letter-icon';

  private static get observedAttributes(): string[] {
    return [
      PraxisSideBarListItem.ICON_ATTRIBUTE,
      PraxisSideBarListItem.LETTER_ICON_ATTRIBUTE,
    ];
  }

  // mobile keyboard design
  private iconElement: PraxisIcon;
  private letterIconElement: HTMLDivElement;
  private template = `
    <style>${styles}</style>
    <div id="letter-icon" hidden></div><praxis-icon size="small" hidden></praxis-icon><slot></slot>
  `;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.iconElement = shadowRoot.querySelector('praxis-icon') as PraxisIcon;
    this.letterIconElement = shadowRoot.querySelector('#letter-icon') as HTMLDivElement;

    this.addEventListeners();
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisSideBarListItem.ICON_ATTRIBUTE:
        this.setIcon(newValue);
        break;
      case PraxisSideBarListItem.LETTER_ICON_ATTRIBUTE:
        this.setLetterIcon(newValue);
        break;
    }
  }

  private addEventListeners(): void {
    this.addEventListener('click', () => this.dispatchEvent(new Event('select', {bubbles:true})))
  }

  private setIcon(name: string): void {
    this.iconElement.setAttribute('name', name);
    this.iconElement.removeAttribute('hidden');
  }

  private setLetterIcon(character: string): void {
    this.letterIconElement.textContent = character;
    this.letterIconElement.removeAttribute('hidden');
  }
}

window.customElements.define('praxis-side-bar-list-item', PraxisSideBarListItem);
