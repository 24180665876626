import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import styles from "./ISNCSCIControl.shadow.css";
import { IISNCSCIResult } from "./IISNCSCIResult";

export class ISNCSCIControl extends _PraxisControlElement {
  public static is = 'isncsci-control'

  // public static get observedAttributes(): string[] {
  //   return [
  //     ..._PraxisControlElement.observedAttributes,
  //   ];
  // }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    if (this._wrappedElement && this._wrappedElement.value != val) {
      this._wrappedElement.value = val;
      this._wrappedElement.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
    }

    this.formatResult();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'readonly':
      case 'disabled':{
        const readonly = newValue !== null
        this.channel.port1.postMessage({ action: 'SET_READONLY', readonly: readonly });
        this._editBtn.hidden = readonly;
        break;
      }
    }
    this._validate();
  }

  protected _wrappedElement?: HTMLInputElement;
  protected _selector = '[is="isncsci-control"]';
  private channel = new MessageChannel();
  private _iframeElement: HTMLIFrameElement;
  private _control: HTMLDivElement;
  private _result: HTMLDivElement;
  private _editBtn: PraxisButton;
  private _calculatedResult?: IISNCSCIResult;
  private _calcuResolve?: () => void = undefined;
  private _calcuReject?: () => void = undefined;

  public constructor() {
    super();

    this.initISNCSCI = this.initISNCSCI.bind(this);
    this.calcAndSet = this.calcAndSet.bind(this);

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <praxis-button icon="open_in_new" id="edit-btn">ISNCSCI editor</praxis-button>
    <div id='isncsci-control'>
      <iframe></iframe>
      <div id="footer">
        <praxis-button id="clear-btn">Clear</praxis-button>
        <praxis-button id="set-btn">Calculate and Close</praxis-button>
        <praxis-button id="cancel-btn">Cancel</praxis-button>
      </div>
    </div>
    <div id="messages"></div>
    <div id='isncsci-result'>
    </div>
    `;
    this._control = shadowRoot.querySelector('#isncsci-control') as HTMLDivElement;
    this._control.hidden = true;

    this._result = shadowRoot.querySelector('#isncsci-result') as HTMLDivElement;
    this._iframeElement = this._control.querySelector('iframe') as HTMLIFrameElement;

    this._editBtn = this.shadowRoot?.querySelector('#edit-btn') as PraxisButton;
    this._editBtn.addEventListener('click', () => {
      this.setExamData();
      this._control.hidden = false;
    });
  }

  protected _setupComponent(): void {
    const cancelBtn = this._control.querySelector('#cancel-btn') as PraxisButton;
    cancelBtn.addEventListener('click', () => { this._control.hidden = true; });
    const setBtn = this._control.querySelector('#set-btn') as PraxisButton;
    setBtn.addEventListener('click', this.calcAndSet);
    const clearBtn = this._control.querySelector('#clear-btn') as PraxisButton;
    clearBtn.addEventListener('click', () => { this.channel.port1.postMessage({action: 'CLEAR_EXAM'}); });
    // const calcBtn = this._control.querySelector('#calc-btn') as PraxisButton;
    // calcBtn.addEventListener('click', () => { this.channel.port1.postMessage({ action: 'CLASSIFY' });});

    //version
    const version = this._wrappedElement?.getAttribute('version')?.replace('_', '.');
    this._iframeElement.addEventListener('load', this.initISNCSCI);
    this._iframeElement.setAttribute('src', `${process.env.ISNCSCI_URI}/Form?version=${version}`);

    //value
    this.formatResult();
  }

  protected _validate(): void {
    let value = null;
    this._validationMessage = '';

    if (this.value){
      value = JSON.parse(this.value) as {isComplete: boolean, errors: string[], missingValues: string[]};
    }

    if (value?.missingValues && value.missingValues.length > 0){
      const msg = `<div>Missing values:</div><div>${value.missingValues.join('</br>')}</div>`;
      this._validationMessage = msg;
    }

    if (value?.errors && value.errors.length > 0){
      const msg = `<div>Errors:</div><div>${value.errors.join('</br>')}</div>`;
      this._validationMessage += msg;

    }

    if (this._validationMessage == ''){
      if (this.required && (!value || !value.isComplete)){
        this._validationMessage = i18n.ERROR_MESSAGE_FIELD_REQUIRED;
      }else{
        this._validationMessage = this._wrappedElement?.validationMessage || '';
      }
    }
  }

  private initISNCSCI() {
    // Listen for messages on `port1`
    this.channel.port1.onmessage = (e) => {
      //check the return data is JSON (object) type
      if (e.data.constructor === ({}).constructor) {
        this._calculatedResult = e.data as IISNCSCIResult;
      } else {
        alert("Error on ISNCSCI Calculation");
        if (this._calcuReject){
          this._calcuReject();
        }
      }
      if (this._calcuResolve){
        this._calcuResolve();
      }
    };

    this._iframeElement.contentWindow?.postMessage({ action: 'INITIALIZE_PORT' }, '*', [this.channel.port2]);

    const readonly = this._wrappedElement?.hasAttribute('readonly')
      || this._wrappedElement?.hasAttribute('disabled');
    this.channel.port1.postMessage({ action: 'SET_READONLY', readonly: readonly });
    this.channel.port1.postMessage({ action: 'SET_STYLE_ATTRIBUTE', styleAttribute: 'no-app-bar' });

    // this.channel.port1.postMessage({ action: 'SET_CLASSIFICATION_STYLE', classificationStyle: 'visible' });
  }

  private async setExamData(){
    const data = this.value ? JSON.parse(this.value) : null;
    if (data != null){
      data.errors = [];
      data.missingValues = [];
      this.channel.port1.postMessage({ action: 'SET_EXAM_DATA', examData: data });
    }else{
      this.channel.port1.postMessage({action: 'CLEAR_EXAM'});
    }
  }

  private calc(): Promise<void>{
    if (this._calcuReject){
      //the calcu is still running, reject the previous calc request
      this._calcuReject();
    }
    const promise = new Promise<void>((resolve, reject) => {
      this._calcuResolve = resolve;
      this._calcuReject = reject;
    });
    this.channel.port1.postMessage({ action: 'CLASSIFY' });

    return promise;
  }

  private async calcAndSet() {
    await this.calc();
    if (this._calculatedResult) {
      this.value = JSON.stringify(this._calculatedResult);
      this.formatResult();
    }
    this._validate();
    this._control.hidden = true;
  }

  private formatResult() {
    if (!this._result) { return; }
    let isncsciResult: IISNCSCIResult | undefined;
    try {
      isncsciResult = JSON.parse(this.value) as IISNCSCIResult;
    } catch {
      isncsciResult = undefined;
    }
    const html = `
      <table>
        <tbody>
          <tr>
            <td></td>
            <td colspan=3 class='border-right'>RIGHT</td>
            <td colspan=3>LEFT</td>
          </td>
          <tr>
            <td></td>
            <td>Motor Key Muscles</td>
            <td colspan=4>Sensory Key Sensory Points</td>
            <td>Motor Key Muscles</td>
          </tr>
          <tr>
            <td style="min-width:75px;width:75px;"></td>
            <td style="width:15%"></td>
            <td style="width:15%">Light Touch</td>
            <td style="width:15%" class='border-right'>Pin Prick</td>
            <td style="width:15%">Light Touch</td>
            <td style="width:15%">Pin Prick</td>
            <td style="width:15%"></td>
          </tr>
          <tr>
            <td>C2</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC2, isncsciResult.rightLightTouchC2ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC2, isncsciResult.rightPinPrickC2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC2, isncsciResult.leftLightTouchC2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC2, isncsciResult.leftPinPrickC2ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>C3</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC3, isncsciResult.rightLightTouchC3ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC3, isncsciResult.rightPinPrickC3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC3, isncsciResult.leftLightTouchC3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC3, isncsciResult.leftPinPrickC3ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>C4</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC4, isncsciResult.rightLightTouchC4ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC4, isncsciResult.rightPinPrickC4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC4, isncsciResult.leftLightTouchC4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC4, isncsciResult.leftPinPrickC4ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>C5</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorC5, isncsciResult.rightMotorC5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC5, isncsciResult.rightLightTouchC5ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC5, isncsciResult.rightPinPrickC5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC5, isncsciResult.leftLightTouchC5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC5, isncsciResult.leftPinPrickC5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorC5, isncsciResult.leftMotorC5ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>C6</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorC6, isncsciResult.rightMotorC6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC6, isncsciResult.rightLightTouchC6ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC6, isncsciResult.rightPinPrickC6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC6, isncsciResult.leftLightTouchC6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC6, isncsciResult.leftPinPrickC6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorC6, isncsciResult.leftMotorC6ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>C7</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorC7, isncsciResult.rightMotorC7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC7, isncsciResult.rightLightTouchC7ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC7, isncsciResult.rightPinPrickC7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC7, isncsciResult.leftLightTouchC7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC7, isncsciResult.leftPinPrickC7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorC7, isncsciResult.leftMotorC7ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>C8</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorC8, isncsciResult.rightMotorC8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchC8, isncsciResult.rightLightTouchC8ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickC8, isncsciResult.rightPinPrickC8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchC8, isncsciResult.leftLightTouchC8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickC8, isncsciResult.leftPinPrickC8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorC8, isncsciResult.leftMotorC8ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>T1</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorT1, isncsciResult.rightMotorT1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT1, isncsciResult.rightLightTouchT1ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT1, isncsciResult.rightPinPrickT1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT1, isncsciResult.leftLightTouchT1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT1, isncsciResult.leftPinPrickT1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorT1, isncsciResult.leftMotorT1ConsiderNormal)}</td>
          </tr>

          <tr>
            <td>T2</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT2, isncsciResult.rightLightTouchT2ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT2, isncsciResult.rightPinPrickT2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT2, isncsciResult.leftLightTouchT2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT2, isncsciResult.leftPinPrickT2ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T3</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT3, isncsciResult.rightLightTouchT3ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT3, isncsciResult.rightPinPrickT3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT3, isncsciResult.leftLightTouchT3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT3, isncsciResult.leftPinPrickC3ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T4</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT4, isncsciResult.rightLightTouchT4ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT4, isncsciResult.rightPinPrickT4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT4, isncsciResult.leftLightTouchT4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT4, isncsciResult.leftPinPrickT4ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T5</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT5, isncsciResult.rightLightTouchT4ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT5, isncsciResult.rightPinPrickT5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT5, isncsciResult.leftLightTouchT5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT5, isncsciResult.leftPinPrickT5ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T6</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT6, isncsciResult.rightLightTouchT6ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT6, isncsciResult.rightPinPrickT6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT6, isncsciResult.leftLightTouchT6ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT6, isncsciResult.leftPinPrickT6ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T7</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT7, isncsciResult.rightLightTouchT7ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT7, isncsciResult.rightPinPrickT7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT7, isncsciResult.leftLightTouchT7ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT7, isncsciResult.leftPinPrickT7ConsiderNormal)}</td>
            <td></td>
          </tr>

          <tr>
            <td>T8</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT8, isncsciResult.rightLightTouchT8ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT8, isncsciResult.rightPinPrickT8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT8, isncsciResult.leftLightTouchT8ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT8, isncsciResult.leftPinPrickT8ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T9</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT9, isncsciResult.rightLightTouchT9ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT9, isncsciResult.rightPinPrickT9ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT9, isncsciResult.leftLightTouchT9ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT9, isncsciResult.leftPinPrickT9ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T10</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT10, isncsciResult.rightLightTouchT10ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT10, isncsciResult.rightPinPrickT10ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT10, isncsciResult.leftLightTouchT10ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT10, isncsciResult.leftPinPrickT10ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T11</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT11, isncsciResult.rightLightTouchT11ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT11, isncsciResult.rightPinPrickT11ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT11, isncsciResult.leftLightTouchT11ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT11, isncsciResult.leftPinPrickT11ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>T12</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchT12, isncsciResult.rightLightTouchT12ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickT12, isncsciResult.rightPinPrickT12ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchT12, isncsciResult.leftLightTouchT12ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickT12, isncsciResult.leftPinPrickT12ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>L1</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchL1, isncsciResult.rightLightTouchL1ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickL1, isncsciResult.rightPinPrickL1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchL1, isncsciResult.leftLightTouchL1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickL1, isncsciResult.leftPinPrickL1ConsiderNormal)}</td>
            <td></td>
          </tr>

          <tr>
            <td>L2</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorL2, isncsciResult.rightMotorL2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchL2, isncsciResult.rightLightTouchL2ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickL2, isncsciResult.rightPinPrickL2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchL2, isncsciResult.leftLightTouchL2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickL2, isncsciResult.leftPinPrickL2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorL2, isncsciResult.leftMotorL2ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>L3</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorL3, isncsciResult.rightMotorL3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchL3, isncsciResult.rightLightTouchL3ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickL3, isncsciResult.rightPinPrickL3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchL3, isncsciResult.leftLightTouchL3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickL3, isncsciResult.leftPinPrickL3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorL3, isncsciResult.leftMotorL3ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>L4</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorL4, isncsciResult.rightMotorL4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchL4, isncsciResult.rightLightTouchL4ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickL4, isncsciResult.rightPinPrickL4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchL4, isncsciResult.leftLightTouchL4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickL4, isncsciResult.leftPinPrickL4ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorL4, isncsciResult.leftMotorL4ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>L5</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorL5, isncsciResult.rightMotorL5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchL5, isncsciResult.rightLightTouchL5ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickL5, isncsciResult.rightPinPrickL5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchL5, isncsciResult.leftLightTouchL5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickL5, isncsciResult.leftPinPrickL5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorL5, isncsciResult.leftMotorL5ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>S1</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightMotorS1, isncsciResult.rightMotorS1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchS1, isncsciResult.rightLightTouchS1ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickS1, isncsciResult.rightPinPrickS1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchS1, isncsciResult.leftLightTouchS1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickS1, isncsciResult.leftPinPrickS1ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftMotorS1, isncsciResult.leftMotorS1ConsiderNormal)}</td>
          </tr>
          <tr>
            <td>S2</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchS2, isncsciResult.rightLightTouchS2ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickS2, isncsciResult.rightPinPrickS2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchS2, isncsciResult.leftLightTouchS2ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickS2, isncsciResult.leftPinPrickS2ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>S3</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchS3, isncsciResult.rightLightTouchS3ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickS3, isncsciResult.rightPinPrickS3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchS3, isncsciResult.leftLightTouchS3ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickS3, isncsciResult.leftPinPrickS3ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>S4-5</td>
            <td></td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightLightTouchS4_5, isncsciResult.rightLightTouchLS4_5ConsiderNormal)}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.rightPinPrickS4_5, isncsciResult.rightPinPrickS4_5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftLightTouchS4_5, isncsciResult.leftLightTouchLS4_5ConsiderNormal)}</td>
            <td>${isncsciResult == undefined ? '' : this.getCellValue(isncsciResult.leftPinPrickS4_5, isncsciResult.leftPinPrickS4_5ConsiderNormal)}</td>
            <td></td>
          </tr>
          <tr>
            <td>Total</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightMotorTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightLightTouchTotal ?? ''}</td>
            <td class='border-right'>${isncsciResult == undefined ? '' : isncsciResult.rightPinPrickTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftLightTouchTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftPinPrickTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftMotorTotal ?? ''}</td>
          </tr>

          <tr>
            <td colspan=7 class='sub-title'></td>
          </tr>
          <tr>
            <td>VAC</td>
            <td colspan=6>${isncsciResult == undefined ? '' : isncsciResult.voluntaryAnalContraction ?? ''}</td>
          </tr>
          <tr>
            <td>DAP</td>
            <td colspan=6>${isncsciResult == undefined ? '' : isncsciResult.deepAnalPressure ?? ''}</td>
          </tr>

          <tr>
            <td colspan=7 class='sub-title'>Lowest non-key muscle with motor function</td>
          </tr>
          <tr>
            <td></td>
            <td class='border-right' colspan=3>RIGHT</td>
            <td colspan=3>LEFT</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td class='border-right' colspan=3>${isncsciResult == undefined ? '' : isncsciResult.rightLowestNonKeyMuscleWithMotorFunction ?? ''}</td>
            <td colspan=3>${isncsciResult == undefined ? '' : isncsciResult.leftLowestNonKeyMuscleWithMotorFunction ?? ''}</td>
          </tr>

          <tr>
          <td colspan=7 class='sub-title'>Comments</td>
          </tr>
          <tr>
            <td colspan=7>${isncsciResult == undefined ? '' : isncsciResult.comments ?? ''}&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <h3 style='text-align:center;max-width:700px'>Classification</h3>
      <table>
        <tbody>
          <tr>
            <td class='sub-title' colspan='3'>Neurological Levels</td>
          </tr>
          <tr>
            <td style="width: 100px;min-width:100px;"></td>
            <td style="width: 50%">R</td>
            <td style="width: 50%">L</td>
          </tr>
          <tr>
            <td>Sensory</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightSensory?.replaceAll(',', ', ') ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftSensory?.replaceAll(',', ', ') ?? ''}</td>
          </tr>
          <tr>
            <td>Motor</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightMotor?.replaceAll(',', ', ') ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftMotor?.replaceAll(',', ', ') ?? ''}</td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td style='min-width:250px;width:250px;'>Complete or Incomplete?</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.injuryComplete?.replaceAll(',', ', ') ?? ''}</td>
          </tr>
          <tr>
            <td>Asia Impairment Scale (AIS)</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.asiaImpairmentScale?.replaceAll(',', ', ') ?? ''}</td>
          </tr>
          <tr>
            <td>Neurological Level Of Injury (NLI)</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.neurologicalLevelOfInjury?.replaceAll(',', ', ') ?? ''}</td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td class='sub-title' colspan='3'>Zone of Partial Preservation</td>
          </tr>
          <tr>
            <td style="width: 100px;min-width:100px;"></td>
            <td style="width:50%">R</td>
            <td style="width:50%">L</td>
          </tr>
          <tr>
            <td>Sensory</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightSensoryZpp.replaceAll(',', ', ') ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftSensoryZpp.replaceAll(',', ', ') ?? ''}</td>
          </tr>
          <tr>
            <td>Motor</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightMotorZpp.replaceAll(',', ', ') ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftMotorZpp.replaceAll(',', ', ') ?? ''}</td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
          <td colspan=7 class='sub-title'>Sub-scores</td>
          </tr>
          <tr>
            <td style="width: 100px;min-width:100px;"></td>
            <td style="width:33.3%">RIGHT</td>
            <td style="width:33.3%">LEFT</td>
            <td style="width:33.3%">Total</td>
          </tr>
          <tr>
            <td>UEMS</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightUpperMotorTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftUpperMotorTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.upperMotorTotal ?? ''}</td>
          </tr>
          <tr>
            <td>LEMS</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightLowerMotorTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftLowerMotorTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.lowerMotorTotal ?? ''}</td>
          </tr>
          <tr>
            <td>Light touch</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightLightTouchTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftLightTouchTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.lightTouchTotal ?? ''}</td>
          </tr>
          <tr>
            <td>Pin prick</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.rightPinPrickTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.leftPinPrickTotal ?? ''}</td>
            <td>${isncsciResult == undefined ? '' : isncsciResult.pinPrickTotal ?? ''}</td>
          </tr>
        </tbody>
      </table>
    `
    this._result.innerHTML = html;
  }

  private getCellValue(value: string | null, considerNormal: boolean | null): string{
    return (considerNormal == null)
      ? value || ''
      : `${value?.replaceAll('*', '')}*${(considerNormal ? 'N' : 'A')}`;
  }
}
window.customElements.define(ISNCSCIControl.is, ISNCSCIControl);
