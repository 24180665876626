import styles from "./PraxisAnchor.shadow.css";

export class PraxisAnchor extends HTMLElement {
  private static get observedAttributes(): string[] {
    return [
      'href',
    ];
  }

  private template = `<style>${styles}</style><a><slot></slot></a>`;

  private anchorElement: HTMLAnchorElement;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.anchorElement = shadowRoot.querySelector('a') as HTMLAnchorElement;
    this.setHref(this.getAttribute('href'));

    this.anchorElement.addEventListener('click', e => {
      e.preventDefault();
      if (this.anchorElement.href) {
        window.dispatchEvent(new CustomEvent('navigate', {detail: this.anchorElement.href}));
      }
    })
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'href':
        this.setHref(newValue);
        break;
    }
  }

  private setHref(value: string | null) {
    if (value) {
      this.anchorElement.href = value;
    } else {
      this.anchorElement.removeAttribute('href');
    }
  }
}

window.customElements.define('praxis-anchor', PraxisAnchor);
