import { hasDifferentValues } from "@/Common/utils/hasDifferentValues";
import { _PraxisSelectSingleSelection } from "./_PraxisSelectSingleSelection";

export abstract class _PraxisSelectMultipleSelection extends _PraxisSelectSingleSelection {
  protected _values: string[] = [];

  public get values(): string[] {
    return this._values;
  }

  public set values(val: string[]) {
    if (hasDifferentValues(this._values, val)) {
      this._values = val;
      for (const input of this._inputElements || []) {
        input.checked = val.includes(input.value);
      }
      if (this._wrappedElement) {
        for (const option of this._wrappedElement.options) {
          option.selected = val.includes(option.value);
        }
        this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
      }
    }
  }
}