import { _PraxisControlElement } from "../../../Controls/_PraxisControlElement/_PraxisControlElement";
import { EvaluateLogic, SupportedInputs } from "../EvaluateLogic";

export class BranchLogic extends EvaluateLogic {
  constructor(elements: SupportedInputs[]) {
    super(elements, 'branch-logic', true, function(element, result) {
      if (typeof result != 'boolean'){
        console.error(`Branch logic for ${element.name} doesnt return boolean!!!`);
        return;
      }

      if (element.disabled === result) {
        const { parentElement } = element as { parentElement: _PraxisControlElement | null };
        if (parentElement && (parentElement.tagName.startsWith('PRAXIS') || parentElement.tagName == 'ISNCSCI-CONTROL')) {
          parentElement.disabled = !result;
        } else {
          element.disabled = !result;
        }
        parentElement?.touch();
        element.dispatchEvent(new Event('praxis-input-change', { bubbles: true, composed: true }));
      }
    });
  }
}