import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
import styles from "./PraxisContextMenuItem.shadow.css";

export class PraxisContextMenuItem extends HTMLElement {
  public static ICON_ATTRIBUTE = 'icon';
  public static LABEL_ATTRIBUTE = 'label';
  public static get observedAttributes(): string[] {
    return [
      PraxisContextMenuItem.ICON_ATTRIBUTE,
      PraxisContextMenuItem.LABEL_ATTRIBUTE,
    ];
  }

  public get disabled(): boolean {
    return this.hasAttribute('disabled');
  }

  public set disabled(value: boolean) {
    if (value) {
      this.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
    }
  }

  private iconElement: PraxisIcon;

  constructor() {
    super();
    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.innerHTML =
    `<style>${styles}</style><praxis-icon></praxis-icon><slot></slot>`;
    this.iconElement = shadowRoot.querySelector('praxis-icon') as PraxisIcon;

    this.addEventListener('click', e => {
      if (this.hasAttribute('disabled')) {
        e.stopImmediatePropagation();
      }
    })
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisContextMenuItem.ICON_ATTRIBUTE:
        this.setIcon(newValue);
        break;
      case PraxisContextMenuItem.LABEL_ATTRIBUTE:
        this.setLabel(newValue);
        break;
    }
  }

  private setIcon(icon: string): void {
    this.iconElement.setAttribute('name', icon);
  }

  private setLabel(text: string): void {
    this.textContent = text;
  }
}

window.customElements.define('praxis-context-menu-item', PraxisContextMenuItem);
