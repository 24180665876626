import { debug } from "console";
import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import styles from "./PraxisNeurologyPicker.shadow.css";

export class PraxisNeurologyPicker extends _PraxisControlElement {
  public static is = 'praxis-neurology-picker';

  private static unknownGroup: { [name: number]: number[] } = {
    10: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    30: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
    50: [51, 52, 53, 54, 55, 56],
    60: [61, 62, 63, 64, 65, 66]
  };

  public static get observedAttributes(): string[] {
    return [
      ..._PraxisControlElement.observedAttributes,
    ];
  }
  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'hidden':
        if (newValue === null) {
          this._neurologyLabel.classList.remove('hidden');
        } else {
          this._neurologySelect.hidden = true;
          this._neurologyLabel.hidden = false;
          this._neurologyLabel.classList.add('hidden');
        }
        break;
      case 'readonly':
        if (newValue === null) {
          this._neurologyLabel.classList.remove('readonly', 'specify');
        }else{
          if (this._neurologyLabel.textContent == 'Specify'){
            this._neurologyLabel.classList.add('specify');
          }
          this._neurologyLabel.classList.add('readonly');
        }
        break;
      case 'disabled':
        if (newValue == null){
          this._neurologyLabel.classList.remove('disabled');
        }else{
          this._neurologyLabel.classList.add('disabled');
        }
        break;
    }
    this._validate();
  }

  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    if (this._wrappedElement) {
      this._wrappedElement.value = val;
      this.setAttribute('value', val);
    }
  }

  protected _selector = 'select';
  protected _wrappedElement?: HTMLSelectElement;
  private _neurologyLabel: HTMLElement;
  private _neurologySelect: HTMLElement;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });

    // Occiput | 1,
    // 0 | 11, Above C2 | 12, C1 | 13, C2 | 14, C3 | 15, C4 | 16, C5 | 17, C6 | 18, C7 | 19, C8 | 20, Cervical unknown | 10,
    // T1 | 31, T2 | 32, T3 | 33, T4 | 34, T5 | 35, T6 | 36, T7 | 37, T8 | 38, T9 | 39, T10 | 40, T11 | 41, T12 | 42, Thoracic unknown | 30,
    // L1 | 51, L2 | 52, L3 | 53, L4 | 54, L5 | 55, L6 | 56, Lumbar unknown | 50,
    // S1 | 61, S2 | 62, S3 | 63, S4 | 64, S4-5 | 65, S5 | 66, Sacrum unknown | 60,
    // Coccyx | 81, Ilium | 82, Intact | 83,
    // Normal | 97,
    // Not Applicable | 98,
    // Unknown | 99
    shadowRoot.innerHTML = `
      <style>${styles}</style>
      <div id='neurology-label'>Specify</div>
      <div id='neurology-select-container'>
        <div id='neurology-select'>
          <div class="clearSelection">
            <span>Clear selection</span>
          </div>
          <ul>
            <li class="normal hidden">
              <input type="checkbox" id="nmsNormal" value="97" class="null-favor-unknown">
              <label for="nmsNormal">Normal</label>
            </li>
            <li class="unknown hidden">
              <input type="checkbox" id="nmsUnknown" value="99" class="null-favor-unknown">
              <label for="nmsUnknown">Unknown</label>
            </li>
            <li class="unknown hidden">
              <input type="checkbox" id="nmsNotApplicable" value="98" class="null-favor-unknown">
              <label for="nmsNotApplicable">Not applicable</label>
            </li>
            <li class="long location hidden" value="1">Occiput</li>
            <li class="sectionUnknown">
              <input type="checkbox" id="nmsCervicalUnknown" value="10" class="hidden section-unknown">
              <label for="nmsCervicalUnknown" class="hidden">Cervical unknown</label>
            </li>
            <li class="location cervical hidden" value="11">0</li>
            <li class="location cervical hidden" value="12">Above C2</li>
            <li class="location cervical hidden" value="13">C1</li>
            <li class="location cervical hidden" value="14">C2</li>
            <li class="location cervical hidden" value="15">C3</li>
            <li class="location cervical hidden" value="16">C4</li>
            <li class="location cervical hidden" value="17">C5</li>
            <li class="location cervical hidden" value="18">C6</li>
            <li class="location cervical hidden" value="19"">C7</li>
            <li class="location cervical hidden" value="20">C8</li>
            <li class="sectionUnknown">
              <input type="checkbox" id="nmsThoracicUnknown" value="30" class="hidden section-unknown">
              <label for="nmsThoracicUnknown" class="hidden">Thoracic unknown</label>
            </li>
            <li class="location thoracic hidden" value="31">T1</li>
            <li class="location thoracic hidden" value="32">T2</li>
            <li class="location thoracic hidden" value="33">T3</li>
            <li class="location thoracic hidden" value="34">T4</li>
            <li class="location thoracic hidden" value="35">T5</li>
            <li class="location thoracic hidden" value="36">T6</li>
            <li class="location thoracic hidden" value="37">T7</li>
            <li class="location thoracic hidden" value="38">T8</li>
            <li class="location thoracic hidden" value="39">T9</li>
            <li class="location thoracic hidden" value="40">T10</li>
            <li class="location thoracic hidden" value="41">T11</li>
            <li class="location thoracic hidden" value="42">T12</li>
            <li class="sectionUnknown">
              <input type="checkbox" id="nmsLumbarUnknown" value="50" class="hidden section-unknown">
              <label for="nmsLumbarUnknown" class="hidden">Lumbar unknown</label>
            </li>
            <li class="location lumbar hidden" value="51">L1</li>
            <li class="location lumbar hidden" value="52">L2</li>
            <li class="location lumbar hidden" value="53">L3</li>
            <li class="location lumbar hidden" value="54">L4</li>
            <li class="location lumbar hidden" value="55">L5</li>
            <li class="location lumbar hidden" value="56">L6</li>
            <li class="sectionUnknown">
              <input type="checkbox" id="nmsSacrumUnknown" value="60" class="hidden section-unknown">
              <label for="nmsSacrumUnknown" class="hidden">Sacrum unknown</label>
            </li>
            <li class="location sacral hidden" value="61">S1</li>
            <li class="location sacral hidden" value="62">S2</li>
            <li class="location sacral hidden" value="63">S3</li>
            <li class="location sacral hidden" value="64">S4</li>
            <li class="location sacral hidden" value="65">S4-5</li>
            <li class="location sacral hidden" value="66">S5</li>
            <li class="divider"><span>&nbsp;</span></li>
            <li class="long location hidden" value="81">Coccyx</li>
            <li class="long location hidden" value="82">Ilium</li>
            <li class="long location hidden" value="83">Intact</li>
          </ul>
          <div class="actions">
            <praxis-button id="cancel-btn">Cancel</praxis-button>
            <praxis-button id="set-btn">Set</praxis-button>
          </div>
        </div>
      </div>`;

    this._neurologyLabel = shadowRoot.querySelector('#neurology-label') as HTMLElement;
    this._neurologySelect = shadowRoot.querySelector('#neurology-select-container') as HTMLElement;
    this._neurologySelect.hidden = true;
  }

  public focus(): void {
    this._wrappedElement?.focus();
  }

  protected _setupComponent(): void {
    this.initNeurologySelect();
    this.drawNeurologySelect();

    this._neurologyLabel.addEventListener('click', () => {
      if (this._neurologyLabel.classList.contains('hidden') || this._neurologyLabel.classList.contains('readonly') || this._neurologyLabel.classList.contains('disabled')){
        return;
      }
      this.drawNeurologySelect();
      this._neurologySelect.hidden = false;
      this._neurologyLabel.hidden = true;
    })

    this._neurologySelect.querySelector('.clearSelection')?.addEventListener('click', () => {
      this.clearAllSelect();
    });

    for (const unknown of this._neurologySelect.querySelectorAll('.null-favor-unknown')) {
      (unknown as HTMLInputElement).addEventListener('click', (e: MouseEvent) => {
        this.setUnknownsAndLocation((e.currentTarget as HTMLInputElement).checked);
        for(const otherUnknown of this._neurologySelect.querySelectorAll(`.null-favor-unknown:not(#${unknown.id})`)){
          (otherUnknown as HTMLInputElement).checked = false;
        }
      })
    }

    for (const unknown of this._neurologySelect.querySelectorAll('input.section-unknown')) {
      (unknown as HTMLInputElement).addEventListener('click', (e: MouseEvent) => {
        const checkbox = e.currentTarget as HTMLInputElement;
        if (checkbox.hasAttribute('hidden')) {
          return;
        }
        const value = checkbox.getAttribute('value');
        if (value) {
          this.setLocationActivity(PraxisNeurologyPicker.unknownGroup[+value] || [], !checkbox.checked);
        }

        if (!this.hasAttribute('multiple')) {
          for (const otherCheckbox of this._neurologySelect.querySelectorAll(`input.section-unknown:not(#${checkbox.id})`)) {
            (otherCheckbox as HTMLInputElement).checked = false;
          }
          for (const cell of this._neurologySelect.querySelectorAll('li.location')) {
            cell.classList.remove('selected');
          }
          for (const key in PraxisNeurologyPicker.unknownGroup) {
            if (key != value) {
              this.setLocationActivity(PraxisNeurologyPicker.unknownGroup[+key] || [], true);
            }
          }
        }
      });
    }

    for(const location of this._neurologySelect.querySelectorAll('li.location')){
      location.addEventListener('click', () => {
        if (location.classList.contains('hidden') || location.classList.contains('inactive')) { return; }

        if (!this.hasAttribute('multiple')){
          const notSelected = this._neurologySelect.querySelectorAll(`li.location:not([value="${location.getAttribute('value')}"])`);
          notSelected.forEach((e) => {
            e.classList.remove('selected');
          })
          for (const unknown of this._neurologySelect.querySelectorAll('input.section-unknown')) {
            (unknown as HTMLInputElement).checked = false;
          }
          for (const key in PraxisNeurologyPicker.unknownGroup) {
            this.setLocationActivity(PraxisNeurologyPicker.unknownGroup[+key] || [], true);
          }
        }

        location.classList.toggle('selected');
      })
    }

    this._neurologySelect.querySelector('#set-btn')?.addEventListener('click', () => {
      this._neurologySelect.hidden = true;
      this._neurologyLabel.hidden = false;
      this.setNeurologyOptions();
    });

    this._neurologySelect.querySelector('#cancel-btn')?.addEventListener('click', () => {
      this._neurologySelect.hidden = true;
      this._neurologyLabel.hidden = false;
    });
  }

  private initNeurologySelect() {
    for (const option of (this._wrappedElement?.options || []) as HTMLOptionElement[]) {
      const location = this._neurologySelect.querySelector(`[value="${option.value}"]`) as HTMLElement;
      if (!location) {
        console.error(`praxis-neurology-picker: ${option.label}: ${option.value} not found`);
        continue;
      }

      switch (+option.value) {
        case 10:
        case 30:
        case 50:
        case 60:
          location.classList.remove('hidden')
          if (location.nextElementSibling) {
            location.nextElementSibling.classList.remove('hidden');
            location.nextElementSibling.textContent = option.label;
          }
          break;

        case 97:
        case 98:
        case 99:
          (location.parentNode as HTMLElement).classList.remove('hidden');
          if (location.nextElementSibling) {
            location.nextElementSibling.textContent = option.label;
          }
          break;

        default:
          location.classList.remove('hidden');
          location.textContent = option.label;
      }
    }
  }

  private drawNeurologySelect(){
    this.setUnknownsAndLocation(false);

    const selected: string[] = [];
    for (const option of (this._wrappedElement?.options || []) as HTMLOptionElement[]) {
      const location = this._neurologySelect.querySelector(`[value="${option.value}"]`) as HTMLElement;
      if (!location) { continue; }

      switch (+option.value) {
        case 10:
        case 30:
        case 50:
        case 60:
          (location as HTMLInputElement).checked = option.hasAttribute('selected');
          if (option.hasAttribute('selected')) {
            if (location.nextElementSibling?.textContent){
              selected.push(location.nextElementSibling.textContent);
            }
            this.setLocationActivity(PraxisNeurologyPicker.unknownGroup[+option.value] || [], false);
          }
          break;

        case 97:
        case 98:
        case 99:
          (location as HTMLInputElement).checked = option.hasAttribute('selected');
          if (option.hasAttribute('selected')){
            this.setUnknownsAndLocation(true);
            if (location.nextElementSibling?.textContent) {
              selected.push(location.nextElementSibling.textContent);
            }
          }
          break;

        default:
          if (option.hasAttribute('selected')) {
            location.classList.add('selected');
            if (location.textContent) {
              selected.push(location.textContent);
            }
          }else{
            location.classList.remove('selected');
          }
      }
    }
    this._neurologyLabel.textContent = selected.length > 0 ? selected.join(', ') : 'Specify';
  }

  private setNeurologyOptions()
  {
    const selections = [];
    for (const option of (this._wrappedElement?.options || []) as HTMLOptionElement[]) {
      const location = this._neurologySelect.querySelector(`[value="${option.value}"]`) as HTMLElement;
      switch (+option.value) {
        case 10:
        case 30:
        case 50:
        case 60:
        case 97:
        case 98:
        case 99:
          if ((location as HTMLInputElement).checked){
            option.setAttribute('selected', '');
            selections.push(option.label);

          }else{
            option.removeAttribute('selected');
          }
          break;
        default:
          if (location.classList.contains('selected')){
            option.setAttribute('selected', '');
            selections.push(option.label);
            this._neurologyLabel.textContent += `${location.textContent}, `;
          }else{
            option.removeAttribute('selected');
          }
      }
    }
    const value = selections.length == 0 ? 'Specify' : selections.join(', ');
    if (this._neurologyLabel.textContent != value){
      this._neurologyLabel.textContent = value;
      this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
    }
  }

  private clearAllSelect(){
    for(const location of this._neurologySelect.querySelectorAll('li.location')){
      location.classList.remove('selected');
    }
    for(const checkbox of this._neurologySelect.querySelectorAll('input[type="checkbox"]')){
      (checkbox as HTMLInputElement).checked = false;
    }
    this.setUnknownsAndLocation(false);
  }

  private setLocationActivity(values: number[], isActive: boolean) {
    for (const value of values) {
      const location = this._neurologySelect.querySelector(`[value="${value}"]`) as HTMLElement
      if (location.hasAttribute('hidden')) { return; }
      if (isActive) {
        location.classList.remove('inactive');
      } else {
        location.classList.add('inactive');
        location.classList.remove('selected');
      }
    }
  }

  private setUnknownsAndLocation(hidden: boolean){
    for (const unknown of this._neurologySelect.querySelectorAll('input.section-unknown')) {
      (unknown as HTMLInputElement).hidden = hidden;
      (unknown as HTMLInputElement).checked = false;
    }
    for(const location of this._neurologySelect.querySelectorAll('li.location')){
      if (hidden){
        (location as HTMLElement).classList.add('inactive');
        (location as HTMLElement).classList.remove('selected');
      }else{
        (location as HTMLElement).classList.remove('inactive');
      }
    }

  }

  protected _validate(): void {
    this._validationMessage = this._wrappedElement?.validationMessage || '';
  }
}
window.customElements.define(PraxisNeurologyPicker.is, PraxisNeurologyPicker);
