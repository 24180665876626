import { PraxisDialog } from "@/WebComponents/PraxisDialog/PraxisDialog";
import { PraxisHeaderBar } from "@/Common/views/PraxisShell/PraxisHeaderBar/PraxisHeaderBar";
import { PraxisSideBar } from "@/Common/views/PraxisShell/PraxisSideBar/PraxisSideBar";
import { PraxisSnackbar } from "@/WebComponents/PraxisSnackbar/PraxisSnackbar";
import { CommonRootState } from "@/Common/reducers";
import { IDialog } from "@/Common/interfaces/IDialog";
import styles from "./PraxisShell.shadow.css";
import { router } from "@/Common/services/router/router";
import { authService } from "@/Common/services/auth/auth";
import { EulaSignView } from "../Eula/EulaSignView/EulaSignView";
import { getProjectsAction } from "@/Common/actions/project/project";
import { isLatestEulaSigned, signEula } from "@/Common/services/dataCenter/user/user";
import { getLatestEula } from "@/Common/services/dataCenter/eula/eula";
import { Store } from "redux";
import { IErrors } from "@/Common/interfaces/IErrors";

export default class PraxisShell<RootState extends CommonRootState> extends HTMLElement {
  public static is = 'praxis-shell';
  public dispatch: (action: any) => void = () => console.error('dispatch undefined');
  public store?: Store<RootState>;
  public sideBarLabels: { homeLabel: string, subtitle: string } = { homeLabel: '', subtitle: '' };
  private coverElement: HTMLDivElement;
  private headerBarElement: PraxisHeaderBar;
  private sideBarElement: PraxisSideBar;
  private mainElement: HTMLDivElement;
  private viewContainerElement: HTMLDivElement;
  private dialogElement?: PraxisDialog;
  private snackbar: PraxisSnackbar;

  public constructor() {
    super();
    this.dispatchOpenSideEvent = this.dispatchOpenSideEvent.bind(this);
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `
      <style>${styles}</style>
      <div cover>
        <div>
          <div content><strong style='color:#888'>Gathering Information</strong></div>
          <div class="lds-facebook" loader><div></div><div></div><div></div></div>
        </div>
      </div>
      <div id="side"><praxis-side-bar main-title="Praxis Connect" ${window.innerWidth < 640 ? 'collapsed' : ''}></praxis-side-bar></div>
      <div id="main">
        <div id="top"><praxis-header-bar><praxis-header-bar></div>
        <div id="view-container"></div>
      </div>
      <slot></slot>
      <praxis-snackbar align='center'></praxis-snackbar>
    `;

    this.coverElement = shadowRoot.querySelector('div[cover]') as HTMLDivElement;
    this.headerBarElement = shadowRoot.querySelector('praxis-header-bar') as PraxisHeaderBar;
    this.sideBarElement = shadowRoot.querySelector('praxis-side-bar') as PraxisSideBar;
    this.viewContainerElement = shadowRoot.querySelector('#view-container') as HTMLDivElement;
    this.mainElement = shadowRoot.querySelector('#main') as HTMLDivElement;
    this.snackbar = shadowRoot.querySelector('praxis-snackbar') as PraxisSnackbar;

    this.sideBarElement.addEventListener('signout', () => authService.signoutRedirect());
    this.sideBarElement.addEventListener('navigate', (e) => router.navigate((e as CustomEvent).detail));
    this.headerBarElement.addEventListener('toggle-side', this.dispatchOpenSideEvent);
    this.mainElement.addEventListener('action', (e) => this.dispatchEvent(new CustomEvent('action', {bubbles: true, detail: (e as CustomEvent).detail})))
  }

  public setSideBarLabels({authenticated, homeLabel, subtitle}: { authenticated: boolean, homeLabel: string; subtitle: string; }): void {
    this.sideBarElement.setAttribute(PraxisSideBar.SUBTITLE_ATTRIBUTE, subtitle);
    this.sideBarElement.addFooterItems(homeLabel, authenticated);
  }

  public closeSiderBar(){
    this.sideBarElement.close();
  }

  public enableSearch(){
    this.headerBarElement.addSearchButtonEventListener('click',
      () => { this.openDialog({ title: 'Search', viewComponentName: 'participant-search-view', attributes: [{ name: 'dark', value: ''}]}) });
  }
  public selectProject(projectCode?: string){
    this.sideBarElement.selectProject(projectCode);
  }

  private dispatchOpenSideEvent(): void {
    this.sideBarElement.toggleCollapse();
  }

  public loadView(title: string, viewContainerName: string): void {
    this.headerBarElement.updateTitle(title);
    document.title = "Praxis Connect - " + title;
    const viewElement = document.createElement(viewContainerName);
    this.viewContainerElement.innerHTML = '';
    this.viewContainerElement.append(viewElement);

    viewElement.addEventListener('open-snack-bar', ((e: CustomEvent) => {this.openSnackbar(e.detail)}) as EventListener);
    viewElement.addEventListener('open-dialog', ((e: CustomEvent) => { this.openDialog(e.detail) }) as EventListener);
    viewElement.addEventListener('new-project', ((e: CustomEvent) => {this.newProject(e.detail)}) as EventListener);
  }

  private openSnackbar(detail: {text: string}): void{
    if (detail && detail.text){
      this.snackbar.toast(detail.text);
    }
  }

  private async openDialog({attributes, title, viewComponentName, data, callback}: IDialog): Promise<void> {
    if (this.dialogElement)
    {
      //Only one dialog can be shown
      this.dialogElement.remove();
    }
    this.dialogElement = new PraxisDialog();
    if (title) {
      this.dialogElement.setAttribute('data-title', title);
    }
    this.dialogElement.justify = 'right';
    if (viewComponentName) {
      this.dialogElement.loadView(viewComponentName, attributes, data, callback);
    }
    attributes?.forEach(a => this.dialogElement?.setAttribute(a.name, a.value));
    this.dialogElement.addEventListener('close', () => this.closeDialog());
    this.dialogElement.addEventListener('open-snack-bar', ((e: CustomEvent) => {this.openSnackbar(e.detail) }) as EventListener);
    this.dialogElement.addEventListener('new-project', ((e: CustomEvent) => {this.newProject(e.detail)}) as EventListener);
    // dialogElement.addEventListener('hide', () => this.dispatch(hideDialog(dialogElement.id)));
    this.appendChild(this.dialogElement);
  }

  private closeDialog() {
    if (this.dialogElement) {
      this.dialogElement.remove();
      this.dialogElement = undefined;
    }
  }

  private timeout(ms: number){
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public newProject(detail:{code:string, name:string}): void{
    this.sideBarElement.newProject(detail);
  }

  public async showExpiredWarningIn(expires_in: number): Promise<void>{
    if (expires_in > 65){
      //pop up dialog 65 sec before the token expiry and the dialog will count down for 60 secs
      const dialogTime =  new Date((new Date()).getTime() + ((expires_in - 65) * 1000));
      await new Promise<void>(resolve => {
        const checkExpiryIntervalId = setInterval(() => {
          if(new Date() > dialogTime){
            clearInterval(checkExpiryIntervalId);
            resolve();
          }
        }, 1000);
      })
    }

    PraxisDialog.expiringUser(expires_in > 65 ? 60 : Math.min(0, expires_in - 5),
      async (dialog, expiring) => {
        if (expiring){
          const user = await authService.signinSilent();
          if (user && user.expires_in && user.expires_in > 100){
            dialog.remove();
            this.showExpiredWarningIn(user.expires_in);
          }else{
            dialog.remove();
            alert("Login fail, please click OK to refresh the page");
            location.reload();
          }
        }else{
          const user = await authService.signinPopup();
          if (user && user.expires_in && user.expires_in > 100){
            dialog.remove();
            this.showExpiredWarningIn(user?.expires_in);
          }else{
            dialog.remove();
            alert("Login fail, please click OK to refresh the page");
            location.reload();
          }
        }
      });
  }

  public async connectedCallback(): Promise<void>{
    try {
      if (!this.store) {
        throw {msgs:["Store hasnt been initialized"]};
      }
      const state = this.store.getState();
      const { authenticated, user } = state.auth;

      //participant application
      if (!authenticated) {
        this.coverElement.remove();
        this.dispatchEvent(new CustomEvent("shell-ready", { bubbles: false }));
        return;
      }

      this.headerBarElement.updateProfileIcon(user.firstName, user.lastName);
      this.setSideBarLabels({ ...this.sideBarLabels, authenticated: authenticated });

      //authenticated application (pm or crc or monitor)
      const isEulaSigned = await isLatestEulaSigned();

      if (!isEulaSigned) {
        const eula = await getLatestEula();
        await this.showSignEula(eula.markup);
      }

      if (state.projects.status == 'loading') {
        await this.dispatch(getProjectsAction());
      }

      const { projects } = this.store.getState();
      if (projects.status == 'loaded') {
        const { collection, sortedProjectCodes } = projects;
        if (sortedProjectCodes.length > 0) {
          this.sideBarElement.updateProjectList(collection, sortedProjectCodes);
        }
      }

      this.coverElement.remove();
      this.dispatchEvent(new CustomEvent("shell-ready", { bubbles: false }));
    } catch (e) {
      const error = e as IErrors;
      const loader = this.coverElement.querySelector('[loader]');
      loader?.remove();
      const content = this.coverElement.querySelector('[content]');
      if (content) {
        content.textContent = `${error.msgs.join()}, please try again later`;
      }
    }
  }

  private async showSignEula(eulaMarkup: string){
    const signEulaView = new EulaSignView();
    signEulaView.Markup = eulaMarkup;
    this.shadowRoot?.append(signEulaView);
    await signEulaView.onEulaSign;
    await signEula();
    signEulaView.remove();
  }
}

window.customElements.define(PraxisShell.is, PraxisShell);
