// TODO: support characters with accents
export const sortAlphabetically = (a: string, b: string, caseSensitive = false): number => {
  const x = caseSensitive ? a : a.toLowerCase();
  const y = caseSensitive ? b : b.toLowerCase();
  if (x < y) {
    return -1;
  } else if (x > y) {
    return 1;
  } else {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
}