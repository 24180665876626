import { sortAlphabetically } from "@/Common/utils/sortCompareFn";
import { _PraxisSelectSingleSelection } from "../_PraxisSelectCore/_PraxisSelectSingleSelection";
import styles from "./_PraxisSelectSingle.shadow.css";

/**
 * TODO: rework focusout/tabindex event
 */
export class _PraxisSelectSingle extends _PraxisSelectSingleSelection {
  public static is = 'praxis-select-single';

  public get options(): HTMLOptionsCollection {
    if (this._wrappedElement) {
      return this._wrappedElement.options;
    } else {
      throw new Error('no wrapped select element found');
    }
  }

  public get value(): string {
    return super.value;
  }

  public set value(val: string) {
    super.value = val;
    this._updateSelectionText();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'disabled':
      case 'readonly':
        this._toggleDisableInputs();
        break;
    }
  }

  public updateOptions(): void {
    const { _labelElements, options } = this;
    if (_labelElements) {
      for (let i = 0; i < _labelElements.length; i++) {
        const labelElement = _labelElements[i];
        if (labelElement) {
          labelElement.textContent = options[i]?.textContent || '';
        }
      }
    }
  }

  protected _style = styles;

  protected _setupSelectComponent(): void {
    this._setupFieldSet();

    this._inputElements = this._fieldSetElement.querySelectorAll('input');
    this._selectionTextElement = this._fieldSetElement.querySelector('#selection') || null;
    this._options = this._fieldSetElement.querySelector('#options') || null;
    this._optionsContainer = this._fieldSetElement.querySelector('#options-container') || null;
    this._labelElements = this._fieldSetElement.querySelectorAll('label');
    this._labelElements.forEach(l => {
      const valueAttribute = l.getAttribute('value') || '';
      this._labels[valueAttribute] = l;
    });

    this._updateSelectionText();

    this._toggleDisableInputs();

    this._addEventListeners();
  }

  protected _validate(): void {
    this._validationMessage = this._wrappedElement?.validationMessage || '';
    // TODO: avoid invalid attribute in preference to use :invalid pseudo-class
    if (this._validationMessage) {
      this.setAttribute('invalid', '');
    } else {
      this.removeAttribute('invalid');
    }
  }

  private _labelElements?: NodeListOf<HTMLLabelElement>;
  private _labels: {[value: string]: HTMLLabelElement | undefined} = {};
  private _options: HTMLDivElement | null = null;
  private _optionsContainer: HTMLDivElement | null = null;
  private _placeholder = '';
  private _selectionTextElement: HTMLDivElement | null = null;

  private _addEventListeners(): void {
    this._fieldSetElement.addEventListener('focusout', this._collapse.bind(this));
    this._fieldSetElement.addEventListener('focusin', this._resizeOptionsContainer.bind(this));
    this._fieldSetElement.addEventListener('click', this._onClick.bind(this));
  }

  // gets called twice when click on option; one from label and other from input
  private _onClick(e: Event) {
    if (this.disabled || this.readOnly) {
      return;
    }
    const target = e.target as HTMLInputElement;
    const { type, value } = target;
    if (type === 'radio')
    {
      if (this.value !== value) {
        this.value = value;
        //this._updateSelectionText();
        this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true }));
      }
      this._collapse();
    }
    // TODO: figure out how to blur properly to avoid follow lines
    if (target.id === 'options-container') {
      this._collapse();
    }
  }

  private _collapse() {
    if (this.shadowRoot?.activeElement) {
      (this.shadowRoot.activeElement as HTMLElement).blur();
    }
  }

  /**
   * Resize options container from left position of the element to right side of the screen with 16px padding
   * TODO: investigate designs for edge case positions such as options container overflow to left instead of right
   */
  private _resizeOptionsContainer() {
    const { left, width } = this.getBoundingClientRect();
    if (this._optionsContainer) {
      this._optionsContainer.style.width = `${visualViewport.width - left - 16}px`;
      this._optionsContainer.style.maxWidth = `${visualViewport.width - 16}px`;
    }
    if (this._options) {
      this._options.style.minWidth = `${width}px`;
    }
  }

  // TODO: revisit idea of using label[tabindex="-1"] for accessibility using keypress
  private _setupFieldSet(): void {
    if (!this._wrappedElement) {
      return;
    }

    const required = this._wrappedElement.required ? ' required' : '';
    const sort = this._wrappedElement.getAttribute('sort');
    const compareFn = sort === 'asc'
      ? (a: HTMLOptionElement, b: HTMLOptionElement) => sortAlphabetically(a.textContent?.trim() || '', b.textContent?.trim() || '')
      : sort === 'desc'
        ? (b: HTMLOptionElement, a: HTMLOptionElement) => sortAlphabetically(a.textContent?.trim() || '', b.textContent?.trim() || '')
        : null;
    const rawOptions = Array.from(this._wrappedElement.options);
    const options = compareFn ? rawOptions.sort(compareFn) : rawOptions;
    let placeholder = this.getAttribute('placeholder') || '';

    const mainMarkup = '<div id="selection"></div><praxis-icon name="drop-down"></praxis-icon>';
    let optionsMarkup = '';
    let emptyLabel = '';

    let id = 1;
    let noSelected = true;
    for (const o of options) {
      if (o.value) {
        const icon = o.getAttribute('icon');
        const backgroundColor = o.getAttribute('background-color');
        const checked = o.hasAttribute('selected') ? ' checked' : '';
        optionsMarkup += `
          <input type="radio" value="${o.value}" id="${id}" name="_"${checked}${required}>
          <label for="${id}" value="${o.value}" tabindex="0"${icon ? ` icon=${icon}`:''}${backgroundColor?` background-color=${backgroundColor}`:''}>
            ${backgroundColor ? `<div class="background-color" style="background-color:${backgroundColor}"></div>`: ''}
            ${icon ? `<praxis-icon name="${icon}" size="small"></praxis-icon>` : ''}
            ${o.textContent}
          </label>
        `;
        id++;

        if (checked) {
          this.value = o.value;
          noSelected = false;
        }
      } else {
        placeholder = placeholder || o.textContent || '';
        emptyLabel = emptyLabel || o.textContent || '';
      }
    }

    if (this.hasAttribute('no-empty')) {
      this._fieldSetElement.innerHTML = `${mainMarkup}<div id="options-container"><div id="options">${optionsMarkup}</div></div>`;
      this._fieldSetElement.tabIndex = 1;
    } else {
      this._placeholder = placeholder;

      this._fieldSetElement.innerHTML = `
        ${mainMarkup}
        <div id="options-container"><div id="options"><input type="radio" id="0" name="_" value="-1"${noSelected ? ' checked' : ''}${required}>
          <label for="0" tabindex="0">${emptyLabel || '&nbsp;'}</label>
          ${optionsMarkup}
        </div>
        </div>`;
      if (noSelected){
        //set the original input (type = select) to no selection, otherwise the first one will be selected
        //the underlining value will be difference from the selection and causing error in branch logic and saving the form
        this._wrappedElement.value = '-1';
      }
      this._fieldSetElement.tabIndex = 0;
    }
  }

  private _toggleDisableInputs() {
    const disabled = this.disabled || this.readOnly;
    if (disabled) {
      this._fieldSetElement.removeAttribute('tabindex');
    } else {
      this._fieldSetElement.tabIndex = 0;
    }
    if (this._inputElements) {
      for (const input of this._inputElements) {
        input.disabled = disabled;
      }
    }
  }

  private _updateSelectionText(): void {
    if (this._selectionTextElement) {
      const { value } = this;
      if (value) {
        const backgroundColor = this._labels[value]?.getAttribute('background-color');
        const icon = this._labels[value]?.getAttribute('icon');
        const html = `
            ${backgroundColor ? `<div class="background-color" style="background-color:${backgroundColor}"></div>`: ''}
            ${icon ? `<praxis-icon name="${icon}" size="small"></praxis-icon>` : ''}
            ${this._labels[value]?.textContent || ''}
        `;

        this._selectionTextElement.innerHTML = html;
        //this._selectionTextElement.textContent = this._labels[value]?.textContent || '';
        this._selectionTextElement.classList.remove('empty');
      } else {
        this._selectionTextElement.textContent = this._placeholder;
        this._selectionTextElement.classList.add('empty');
      }
    }
  }
}
window.customElements.define(_PraxisSelectSingle.is, _PraxisSelectSingle);
