import { PraxisTag } from "../PraxisTag/PraxisTag";
import styles from "./PraxisFormFieldComment.shadow.css";

export class PraxisFormFieldComment extends HTMLElement{
  public static get observedAttributes(): string[] {
    return [
      'status',
      'role'
    ];
  }

  private commentsSlotElement: HTMLSlotElement;
  public flagTag: PraxisTag;

  private template = `
    <style>${styles}</style>
    <praxis-tag>flag</praxis-tag>
    <slot name='comments' id='comments' hidden></slot>
  `;

  public constructor() {
    super();
    this.openFlagField = this.openFlagField.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.commentsSlotElement = shadowRoot.querySelector('#comments') as HTMLSlotElement;

    this.flagTag = shadowRoot.querySelector("praxis-tag") as PraxisTag;
    this.flagTag.setAttribute('clickable', '');
    this.flagTag.addEventListener("click", this.openFlagField);
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'status':
        this.setButtonBg(newValue);
        break;
    }
  }

  setButtonBg(newValue: string | null) {
    switch (newValue) {
      case 'OpenQueryFromMonitor':
      case 'OpenQueryFromResearchCoordinator':
        this.flagTag.removeAttribute('success');
        this.flagTag.setAttribute('danger', '');
        this.flagTag.textContent = 'Flagged';
        break;
      case 'Resolved':
        this.flagTag.setAttribute('success', '');
        this.flagTag.textContent = 'Resolved';
        break;
    }

  }

  public addComment(note: string, user: string, date: string, status: string) {
    this.setAttribute('status', status);
    const commentElement = document.createElement('div');
    commentElement.setAttribute('slot', 'comments');
    commentElement.setAttribute('user', user);
    commentElement.setAttribute('date', date);
    commentElement.textContent = note;

    this.append(commentElement);
  }

  public openFlagField() {
    const comments = this.commentsSlotElement.assignedElements()
      .map(c => {
        return {
          user: c.attributes.getNamedItem('user')?.value,
          date: c.attributes.getNamedItem('date')?.value,
          note: c.textContent
        }
      });
    this.dispatchEvent(new CustomEvent("open-flag-field", {
      bubbles: true,
      composed: true,
      detail: { name: this.getAttribute('name'), status: this.getAttribute('status'), comments}
    }));
  }

}

window.customElements.define('praxis-form-field-comments', PraxisFormFieldComment);
