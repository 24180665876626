import i18n from "./interface";

const dateFormat = (dateISOString: string | null) => {
  if (!dateISOString) {
    return 'Inconnue';
  }
  const date = new Date(dateISOString);
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const dd = date.getDate().toString().padStart(2, '0');
  return `${dd}/${mm}/${yyyy}`;
}

/* eslint sort-keys: "error" */
export const fr: i18n = {
  ADD_DATA_COLLECTION_POINT_FORM_DESCRIPTION: (minOccurrence, maxOccurrence) => `${minOccurrence === 0 ? 'Optionnel' : 'Requis'}${maxOccurrence > 1 ? ` - Peut être ajouté ${maxOccurrence === 2147483647 ? 'plusieurs fois' : `jusqu'à ${maxOccurrence}`} fois` : ''}`,
  BUTTON_ADD: 'Ajouter',
  BUTTON_ADD_NEW_COLLECTION_POINT: 'Ajouter un point de collecte',
  BUTTON_ALL_DONE_SAVE: 'Terminé - Enregistrer et ',
  BUTTON_CANCEL: 'Annuler',
  BUTTON_CHOOSE_FILE: 'Choisir un fichier',
  BUTTON_CLOSE: 'Fermer',
  BUTTON_COPY_LINK_TO_CLIPBOARD: 'Copier le lien dans le presse-papiers',
  BUTTON_CREATE: 'Créer',
  BUTTON_CREATE_PROJECT: 'Créer un nouveau projet',
  BUTTON_DELETE: "Delete",
  BUTTON_DIRECT_ENTRY: 'Entrée directe',
  BUTTON_EDIT: 'Éditer',
  BUTTON_ENROLL: 'Inscrire',
  BUTTON_ENROLL_PARTICIPANT: 'Inscrire un nouveau participant',
  BUTTON_FLAG: 'Signaler',
  BUTTON_FLAGGED: 'Signalé',
  BUTTON_GO_TO_NEXT_SECTION: 'Aller à la section suivante',
  BUTTON_IMPORT_LANGUAGE: 'Importer la langue',
  BUTTON_IMPORT_NEW_FORM_VERSION: 'Importer la nouvelle version du formulaire',
  BUTTON_INVITE: 'Inviter',
  BUTTON_INVITE_NEW_MEMBER: 'Inviter un nouveau membre à ce projet',
  BUTTON_MARK_AS_COMPLETED: 'Marquer comme "terminé"',
  BUTTON_MARK_AS_RESOLVED: 'Marquer comme "résolu"',
  BUTTON_MARK_AS_UNVERIFIED: 'Marquer comme "non vérifié"',
  BUTTON_MARK_AS_VERIFIED: 'Marquer comme "vérifié"',
  BUTTON_POST_NOTE: 'Publier une note',
  BUTTON_REVIEW_SHARE_REQUEST: 'Examiner la demande de partage',
  BUTTON_SAVE: 'Enregistrer',
  BUTTON_SAVE_GO_TO_NEXT_SECTION: 'Enregistrer et passer à la section suivante',
  BUTTON_SHARE_PARTICIPANT: 'Partager le participant',
  BUTTON_SUBMIT: 'Soumettre',
  CONFIRMATION_CLEAR_FORM: 'Effacer un formulaire est une action permanente et ne peut pas être annulée. Toutes les données du formulaire seront supprimées et le formulaire reviendra à son état incomplet.\n\nVoulez-vous continuer ?',
  CONFIRMATION_DELETE_FILE: 'Supprimer un fichier est une action permanente et ne peut pas être annulée.\n\nVoulez-vous continuer ?',
  CONFIRMATION_DELETE_FORM: 'Supprimer un formulaire est une action permanente et ne peut pas être annulée. Toutes les données du formulaire seront supprimées et le formulaire reviendra à son état incomplet.\n\nVoulez-vous continuer ?',
  CONFIRMATION_DELETE_FORMGROUP: 'Souhaitez-vous supprimer ce point de collecte de données ? Toutes les données seront supprimées pour ce point de collecte de données.',
  CONFIRMATION_LOGOUT_MESSAGE: 'Vous avez des modifications non enregistrées sur cette page. Vous pouvez procéder à la déconnexion sans enregistrer ou annuler.',
  CONFIRMATION_SUBMIT_FORM_0: 'Vous n\'avez pas rempli tous les champs obligatoires.',
  CONFIRMATION_SUBMIT_FORM_1: 'Êtes-vous sûr de vouloir soumettre vos réponses ?',
  CONFIRMATION_SUBMIT_FORM_2: 'Sinon, appuyez sur "Annuler" pour revenir au formulaire ou appuyez sur "OK" pour soumettre vos réponses.',
  CONSENT_BANNER_CONSENTED: dateISOString => `<span>Consentement obtenu</span> le ${dateFormat(dateISOString)}`,
  CONSENT_BANNER_NOT_CONSENTED: dateISOString => `<span>Consentement non obtenu</span> le ${dateFormat(dateISOString)}`,
  CONSENT_BANNER_PENDING: '<span>Consentement en attente</span>',
  DELETE_PARTICIPANT: 'Supprimer',
  DIRECT_ENTRY_BUTTON_GENERATE_LINK: 'Générer le lien',
  DIRECT_ENTRY_BUTTON_REMOVE_LINK: 'Supprimer le lien',
  DIRECT_ENTRY_DESCRIPTION_GENERATE_LINK: 'Appuyez sur le bouton "générer le lien" pour créer un lien Web que vous pouvez partager avec le participant pour qu\'il puisse remplir le formulaire.',
  DIRECT_ENTRY_DESCRIPTION_REMOVE_LINK: 'Appuyez sur le bouton "supprimer le lien" pour supprimer le lien. Le lien deviendra inactif et le participant perdra la possibilité de remplir le formulaire.',
  DIRECT_ENTRY_HEADING_GENERATE_LINK: 'Partager un lien d\'entrée directe avec le participant',
  DIRECT_ENTRY_HEADING_REMOVE_LINK: 'Supprimer le lien d\'entrée directe et annuler la demande d\'entrée directe',
  ERROR_404_PAGE_NOT_FOUND: 'Le contenu que vous recherchez n\'a pas pu être récupéré depuis nos serveurs. Veuillez vérifier votre demande et réessayer ultérieurement. Si le problème persiste, veuillez contacter le support de Praxis Connect.',
  ERROR_ADD_DCP: 'Nous n\'avons pas pu ajouter le point de collecte de données au projet. Nous avons rencontré les problèmes suivants :',
  ERROR_CONTENT_UNAVAILABLE: 'Contenu indisponible.',
  ERROR_EDIT_DCP: 'Nous n\'avons pas pu modifier le point de collecte de données. Nous avons rencontré les problèmes suivants :',
  ERROR_MESSAGE_ADD_COMMENT: 'Nous n\'avons pas pu ajouter de commentaire. Nous avons trouvé les problèmes suivants:',
  ERROR_MESSAGE_CHOOSE_FACILITIES: 'Sélectionner les établissements',
  ERROR_MESSAGE_CREATE_PROJECT: 'Nous n\'avons pas pu créer le projet. Nous avons trouvé les problèmes suivants:',
  ERROR_MESSAGE_ERRORS_IN_FORM: 'Nous avons trouvé les erreurs suivantes dans le formulaire:',
  ERROR_MESSAGE_FIELD_BAD_INPUT: 'Le format de l\'entrée n\'est pas valide',
  ERROR_MESSAGE_FIELD_INVALID: 'Ce champ n\'est pas valide',
  ERROR_MESSAGE_FIELD_PATTERN_NOT_MATCH: 'Le motif d\'entrée n\'est pas valide',
  ERROR_MESSAGE_FIELD_RANGE_OVERFLOW: val => val ? `Valeur non valide. Veuillez fournir un nombre inférieur ou égal à ${val}` : 'Valeur non valide. Veuillez fournir une valeur inférieure',
  ERROR_MESSAGE_FIELD_RANGE_UNDERFLOW: val => val ? `Valeur non valide. Veuillez fournir un nombre supérieur ou égal à ${val}` : 'Valeur non valide. Veuillez fournir une valeur supérieure',
  ERROR_MESSAGE_FIELD_REQUIRED: 'Ce champ est requis',
  ERROR_MESSAGE_FIELD_TOO_LONG: val => val ? `L'entrée ne peut pas dépasser ${val}` : 'L\'entrée est trop longue',
  ERROR_MESSAGE_FIELD_TOO_SHORT: val => val ? `L'entrée doit être plus longue que ${val}` : 'L\'entrée est trop courte',
  ERROR_MESSAGE_FILE_TOO_LARGE: val => `Le fichier est trop volumineux. Veuillez sélectionner un fichier plus petit que ${val} Mo`,
  ERROR_MESSAGE_FILE_TYPE_INVALID: val => `Le fichier doit être de type ${val}`,
  ERROR_MESSAGE_LOGIN_AGAIN: `Votre session a expiré. Veuillez cliquer sur OK pour vous reconnecter.<br><small>Une boîte de dialogue de connexion s'ouvrira dans une fenêtre contextuelle après avoir cliqué sur OK.</small>`,
  ERROR_MESSAGE_MARK_FORM_AS_COMPLETE: 'Nous n\'avons pas pu marquer le formulaire comme complet. Nous avons trouvé les problèmes suivants:',
  ERROR_MESSAGE_MARK_FORM_AS_VERIFIED: 'Nous n\'avons pas pu marquer le formulaire comme vérifié. Nous avons trouvé les problèmes suivants:',
  ERROR_MESSAGE_NO_RESULTS: 'Aucun résultat trouvé.',
  ERROR_MESSAGE_SESSION_EXPIRES_IN: val => `<p>Votre session expire dans <span id="expiresIn" style="font-weight:bold">${val}</span> secondes. Veuillez cliquer sur OK pour continuer.</p>`,
  ERROR_MESSAGE_UNEXPECTED: 'Une erreur inattendue s\'est produite',
  ERROR_SERVER_404_NOT_FOUND: 'La ressource demandée n\'a pas été trouvée sur notre serveur',
  ERROR_SERVER_PROCESS_DEFAULT: val => `Une erreur inattendue s'est produite lors du traitement de ${val} sur notre serveur`,
  ERROR_SERVER_RETRIEVE_DEFAULT: val => `Une erreur inattendue s'est produite lors de la récupération de ${val} sur notre serveur`,
  ERROR_SERVER_WITH_TRACEID: val => `Une erreur inattendue s'est produite, veuillez contacter l'administrateur de Praxis Connect avec cet ID (${val})`,
  FORM_COMPLETED: 'Complété',
  FORM_DIRECT_ENTRY_REQUESTED_ON: dateISOString => `Demande d'entrée directe faite le ${dateFormat(dateISOString)}`,
  FORM_INCOMPLETE: 'Incomplet',
  FORM_MARKED_AS_COMPLETED: 'Marqué comme complété',
  FORM_MARKED_AS_COMPLETED_MESSAGE: (username, dateISOString) => `Ce formulaire a été marqué comme "Complété" par ${username} le ${dateFormat(dateISOString)}`,
  FORM_MARKED_AS_COMPLETED_REASON: (reason) => `Raison : ${reason}`,
  FORM_MARKED_AS_UNVERIFIED: 'Marqué comme non vérifié',
  FORM_MARKED_AS_UNVERIFIED_MESSAGE: (username, dateISOString) => `Ce formulaire a été marqué comme "Non vérifié" par ${username} le ${dateFormat(dateISOString)}`,
  FORM_MARKED_AS_UNVERIFIED_REASON: (reason) => `Raison : ${reason}`,
  FORM_MARKED_AS_VERIFIED_MESSAGE: (username, dateISOString) => `Ce formulaire a été marqué comme "Vérifié" par ${username} le ${dateFormat(dateISOString)}. Vous ne pouvez pas le modifier ou le supprimer.`,
  FORM_PARTIALLY_COMPLETED: 'Partiellement complété',
  FORM_SAVE_DISCARD_CHANGES: '<p>Vous avez des modifications non enregistrées. Si vous continuez, les modifications apportées seront perdues. Êtes-vous sûr de vouloir continuer ?</p>',
  FORM_SAVE_WARNING_DUPLICATE_FIELDS: 'Le participant que vous essayez d\'inscrire contient des attributs clés similaires à ceux des participants existants. Voulez-vous continuer ?',
  FORM_VERIFIED: 'Vérifié',
  FORM_VERSION_CHANGE: '<div>En ouvrant une version différente du formulaire, vous perdrez les informations que vous avez déjà saisies.</div><br/><div>Voulez-vous continuer ?</div>',
  IDENTITY_ROLE: {
    'Praxis Connect Coordinator': 'Coordinateur',
    'Praxis Connect Monitor': 'Superviseur',
    'Praxis Connect Project Manager': 'Chef de projet',
  },
  LABEL_ABOUT_ROLES_DESCRIPTION: "Praxis Connect prend en charge trois rôles d'utilisateurs différents. Chaque rôle aura ses propres interfaces :<br/> <b>Coordinateur</b>: Collecte des données pour le projet en les saisissant dans Praxis Connect. Peut également demander aux participants de saisir eux-mêmes des données en générant des \"demandes d'entrée directe\". <br /> <b>Chef de projet</b>: Peut modifier les détails du projet et inviter d'autres membres au projet. <br /> <b>Superviseur</b>: Peut consulter les données déidentifiées du projet en lecture seule pour en vérifier la qualité. Peut également générer des signalements pour demander une vérification par le coordinateur.",
  LABEL_ABOUT_ROLES_HIDE: 'En savoir plus sur les rôles',
  LABEL_ABOUT_ROLES_SHOW: 'Masquer les informations sur les rôles',
  LABEL_ACTIVE_USERS: 'utilisateur(s) actif(s)',
  LABEL_ACTIVITY_SUMMARY: 'Mon résumé d\'activité',
  LABEL_ACTIVITY_SUMMARY_HEADING: 'Inscriptions dans vos établissements avec des dossiers ouverts :',
  LABEL_ADD_ANOTHER_ROLE: 'Ajouter un autre rôle',
  LABEL_ADD_DCP: 'Ajouter un point de collecte de données',
  LABEL_ADD_FILES: 'Ajouter des fichiers',
  LABEL_ADD_NOTE: 'Ajouter une note:',
  LABEL_ADD_OPTIONAL_FORMS: 'Ajouter des formulaires optionnels',
  LABEL_ALL_CAUGHT_UP: 'Vous êtes à jour!',
  LABEL_BEING_SHARED: 'est partagé avec',
  LABEL_CHOOSE_ROLE: 'Choisir un rôle',
  LABEL_CONFIRM_CLOSE_MESSAGE: 'Il y a des modifications non enregistrées sur la page. Êtes-vous sûr de vouloir quitter ?',
  LABEL_CREATE_PROJECT: 'Créer un projet',
  LABEL_DATE_OF_ENROLLMENT:'Date d\'inscription',
  LABEL_DCP_NOT_MATCHING:'Points de collecte de données ne correspondant pas au statut de consentement actuel',
  LABEL_DCP_SELECT_FACILITY:'Sélectionnez l\'établissement ou le groupe responsable de la collecte des données',
  LABEL_DCP_START_DATE: 'Date à laquelle la collecte de données est prévue pour commencer',
  LABEL_DELETE_BTN: 'Supprimer cette étiquette',
  LABEL_DELETE_PARTICIPANT: 'Delete this participant',
  LABEL_DISABLED_USERS: 'utilisateur(s) désactivé(s)',
  LABEL_EDIT_DCP: 'Modifier le point de collecte de données',
  LABEL_END_USER_AGREEMENT: 'Contrat d\'utilisateur final',
  LABEL_END_USER_LICENSE_AGREEMENT: 'Contrat de Licence Utilisateur Final',
  LABEL_ENROLLING_FACILITY_OR_GROUP: 'Établissement ou groupe d\'inscription',
  LABEL_ENROLLMENT_DATE: 'Date d\'inscription',
  LABEL_EXISTING_PARTICIPANT_ID: 'Identifiant du participant existant',
  LABEL_FACILITY_RECEIVING_PARTICIPANT: 'Établissement recevant le participant',
  LABEL_FACILITY_SHARING_PARTICIPANT: 'Établissement partageant le participant',
  LABEL_FILES: ' fichier',
  LABEL_FILTER_FACILITIES_GROUPS: 'Filtrer établissements et groupes',
  LABEL_FLAG_FIELD_FOR: 'Signaler les notes pour ',
  LABEL_FLAG_REQUIRING_ATTENTION: (flag) => `Vous avez ${flag} élément(s) signalé(s) nécessitant votre attention.`,
  LABEL_FOLLOW_PARTICIPANT_INFO: 'Suivre un participant vous permet d\'y accéder rapidement depuis le tableau de bord. Vous pouvez commencer à suivre un participant en accédant à sa page et en appuyant sur le bouton Suivre <praxis-icon name="add-user"></praxis-icon> situé en haut à droite de l\'interface.',
  LABEL_FORMS: 'Formulaires',
  LABEL_FORM_SUBMITTED_ON: date => `Formulaire soumis le: ${date}`,
  LABEL_FOR_COORDINATORS: 'For Coordinateurs',
  LABEL_FOR_PROJECT_MANAGERS: 'For Chefs de projet',
  LABEL_FOR_PROJECT_MONITORS: 'For Projet Superviseurs',
  LABEL_GATHERING_INFORMATION: 'Collecte d\'informations',
  LABEL_HAS_ACCESS_TO_PARTICIPANT: 'A accès à ce participant',
  LABEL_IMPORT_FORM_VERSION: 'Importer les versions du formulaire',
  LABEL_IMPORT_PROJECT_LANGUAGE: 'Importer la langue du projet',
  LABEL_INCOMING_SHARE_REQUEST: 'Demandes de partage entrantes',
  LABEL_INVITE_MEMBER: 'Inviter un membre au projet',
  LABEL_I_AGREE: 'Je suis d\'accord',
  LABEL_LABEL_TEXT: 'Texte de l\'étiquette',
  LABEL_LANGUAGES: 'Langues',
  LABEL_LAST_UPDATED: 'Date de dernière mise à jour',
  LABEL_LAST_UPDATED_ON: date => `Dernière mise à jour le : ${date}`,
  LABEL_LOADING: 'Chargement',
  LABEL_LOADING_FORM: 'Chargement du formulaire',
  LABEL_LOADING_PRAXIS_CONNECT: 'Chargement du Praxis Connect',
  LABEL_LOGOUT: 'Déconnexion',
  LABEL_MANAGE_LABEL: 'Gérer les étiquettes',
  LABEL_MARK_AS_OUTSTANDING: 'Marquer comme en attente',
  LABEL_MARK_FORM_AS_COMPLETED: 'Marquer le formulaire comme "terminé"',
  LABEL_MARK_FORM_AS_UNVERIFIED: 'Marquer comme "non vérifié"',
  LABEL_MARK_FORM_AS_VERIFIED: 'Marquer le formulaire comme "vérifié"',
  LABEL_NOTIFICATIONS: 'Notifications:',
  LABEL_NOT_FOLLOWING_PARTICIPANTS: 'Vous ne suivez actuellement aucun participant.',
  LABEL_NOT_FOUND: 'Pas trouvé',
  LABEL_NO_DCP: 'Il n\'y a aucun point de collecte de données disponible pour ce participant',
  LABEL_NO_FILE_CHOSEN: 'Aucun fichier sélectionné',
  LABEL_NO_INCOMING_SHARE_REQUEST: 'Aucune demande de partage entrante.',
  LABEL_NO_NEW_TYPE_TO_REVIEW: type => `Il n'y a pas de nouveaux ${type} à examiner pour vous.`,
  LABEL_NO_OUTGOING_SHARE_REQUEST: 'Aucune demande de partage sortante.',
  LABEL_OUTGOING_SHARE_REQUEST: 'Demandes de partage sortantes',
  LABEL_OUTSTANDING_FLAGS: 'Signalements en attente:',
  LABEL_PARTICIPANT_CONTACT_ADDITIONAL_PHONE_NUMBER: 'Numéro de téléphone supplémentaire',
  LABEL_PARTICIPANT_CONTACT_ADDRESS: 'Adresse',
  LABEL_PARTICIPANT_CONTACT_CITY: 'Ville',
  LABEL_PARTICIPANT_CONTACT_COMMENTS: 'Commentaires',
  LABEL_PARTICIPANT_CONTACT_COUNTRY: 'Pays',
  LABEL_PARTICIPANT_CONTACT_EMAIL: 'Email',
  LABEL_PARTICIPANT_CONTACT_NAME: 'Nom',
  LABEL_PARTICIPANT_CONTACT_PHONE_NUMBER: 'Numéro de téléphone',
  LABEL_PARTICIPANT_CONTACT_POSTAL_CODE: 'Code postal',
  LABEL_PARTICIPANT_CONTACT_PROVINCE: 'Province',
  LABEL_PARTICIPANT_CONTACT_TYPE: 'Type',
  LABEL_PARTICIPANT_ID: 'Participant Id:',
  LABEL_PARTICIPANT_SHARE_DESC: `Lorsque des participants sont partagés par ou avec votre établissement ou groupe, ils sont affichés ici jusqu'à ce que la demande de partage soit traitée. Les dossiers des participants sont généralement partagés lorsqu'un participant passe à un autre établissement ou groupe et que les règles de votre projet permettent le partage d'informations.`,
  LABEL_PARTICIPANT_SUBMITTED_FORM: participantId => `Un participant (${participantId}) a soumis le formulaire`,
  LABEL_PARTICIPANT_TO_BE_DELETED: "Deleting a participant is a permanent action. Once you delete the record you will NOT be able to recoer the information.",
  LABEL_PENDING_SHARE_REQUESTS: 'Demandes de partage en attente:',
  LABEL_PENDING_USERS: 'utilisateur(s) en attente',
  LABEL_PROJECTS: 'Projets',
  LABEL_PROJECT_CODE: 'Code du projet',
  LABEL_PROJECT_DESCRIPTION: 'Description (optionnelle)',
  LABEL_PROJECT_MEMBERS: 'Membres du projet',
  LABEL_PROJECT_NAME: 'Nom du projet',
  LABEL_QUESTIONNAIRE_NOT_FOUND: 'Questionnaire non trouvé',
  LABEL_QUESTIONNAIRE_NOT_FOUND_MESSAGE: 'Le questionnaire que vous essayez d\'accéder n\'est plus disponible pour une entrée directe',
  LABEL_QUESTIONNAIRE_SUBMIT_MESSAGE: `Merci d'avoir pris le temps <br/> de remplir le questionnaire.`,
  LABEL_REASON_FORM_NOT_COMPLETED: 'Veuillez sélectionner la raison pour laquelle le formulaire n\'a pas été initialement rempli',
  LABEL_SEARCH: 'Rechercher',
  LABEL_SELECT_DCP: 'Sélectionnez les points de collecte de données que vous souhaitez ajouter: ',
  LABEL_SELECT_EXCEL_FORM_VERSION_FILE: 'Sélectionner le fichier Excel définissant la version du formulaire',
  LABEL_SELECT_EXCEL_LANGUAGE_FILE: 'Sélectionner le fichier Excel contenant la définition de la langue',
  LABEL_SELECT_EXCEL_PROJECT_FILE: 'Sélectionner le fichier Excel contenant la définition du projet',
  LABEL_SELECT_FACILITIES_GROUPS: 'Sélectionner des installations ou des groupes',
  LABEL_SELECT_FILES_TO_BE_ADDED: 'Sélectionnez les fichiers à ajouter à',
  LABEL_SELECT_FORMS_TO_BE_ADDED: 'Sélectionnez les formulaires à ajouter à',
  LABEL_SEND_FORM_TO_PARTICIPANT: 'Envoyer le formulaire au participant',
  LABEL_SHARE_PARTICIPANT: 'Partager le participant',
  LABEL_SHARE_REQUEST_NOTIFICATION: notifications =>  `Vous avez ${notifications} offre(s) à partager avec votre établissement.`,
  LABEL_SHARE_REQUEST_SENT: 'La demande de partage a été envoyée',
  LABEL_SPECIFY_ROLE: 'Veuillez spécifier au moins un rôle.',
  LABEL_USE_EXISTING_PARTICIPANT_ID: 'Utiliser l\'identifiant d\'un participant existant (par exemple, pour un participant à qui un identifiant d\'étude a déjà été attribué par une autre application ou un administrateur d\'étude)',
  LABEL_YOU_HAVE_NOTIFICATIONS: notifications =>  `Vous avez ${notifications} notification(s).`,
  MENU_ADD_FILES: 'Ajouter des fichiers',
  MENU_ADD_OPTIONAL_FORMS: 'Ajouter des formulaires optionnels',
  MENU_ASSIGN_FORM: 'Assignez à un membre de l\'équipe',
  MENU_CLEAR_FORM: 'Effacer tous les contenus du formulaire',
  MENU_CLOSE_FORM: 'Fermer ce formulaire',
  MENU_DELETE_DCP: 'Supprimer ce point de collecte de données',
  MENU_DELETE_FORM: 'Supprimer ce formulaire',
  MENU_DIRECT_ENTRY: 'Entrée directe',
  MENU_EDIT_DETAILS: 'Modifier les détails',
  MENU_EDIT_FORM: 'Modifier ce formulaire',
  MENU_MARK_COMPLETED: 'Marquez comme complété',
  MENU_MARK_VERIFIED: 'Marquez comme vérifié',
  MENU_PRINT_FORM: 'Imprimez ce formulaire',
  MENU_SAVE_FORM: 'Enregistrer ce formulaire',
  MENU_SET_LABEL: 'Définir l\'étiquette',
  OPTION_CHOOSE_A_FACILITY_OR_GROUP: 'Choisir un établissement ou groupe',
  PARTICIPANT_SEARCH_ENROLLMENT_DATE: dateISOString => `Date d'inscription : ${dateFormat(dateISOString)}`,
  PARTICIPANT_SEARCH_LAST_UPDATED: dateISOString => `Dernière mise à jour : ${dateFormat(dateISOString)}`,
  SECTION_DEFAULT_ENROLLMENT_DETAIL: 'Détail de l\'inscription',
  SECTION_DEFAULT_PARTICIPANT_CONTACT_DETAIL: 'Coordonnées',
  SNACKBAR_ALL_FILE_UPLOAD_SUCCESS: 'Les fichiers ont été téléchargés avec succès',
  SNACKBAR_COPIED_TEXT: 'Texte copié',
  SNACKBAR_ERROR_WHILE_SAVING: 'Erreur lors de l\'enregistrement',
  SNACKBAR_FAILED_TO_SUBMIT_CHECK_ERROR_MESSAGE: 'Échec de la soumission. Veuillez vérifier le message d\'erreur en haut de l\'écran.', // TODO: was not in mock up
  SNACKBAR_FAILED_TO_UPDATE_SHARE_REQUEST: errorMessage => `Échec de la mise à jour. ${errorMessage}`,
  SNACKBAR_FILE_HAS_BEEN_DELETED: 'Le fichier a été supprimé',
  SNACKBAR_FILE_HAS_BEEN_UPDATED: 'Le fichier a été mis à jour',
  SNACKBAR_FILE_NAME_EXISTS: 'Le nom de fichier existe déjà',
  SNACKBAR_FLAG_FIELD_COMMENT_POSTED: 'Signaler le commentaire de champ publié',
  SNACKBAR_FORM_GROUP_HAS_BEEN_DELETED: 'Le point de collecte de données a été supprimé',
  SNACKBAR_FORM_HAS_BEEN_CLEARED: 'Le formulaire a été effacé',
  SNACKBAR_FORM_HAS_BEEN_DELETED: 'Le formulaire a été supprimé',
  SNACKBAR_FORM_HAS_BEEN_SAVED: 'Le formulaire a été enregistré',
  SNACKBAR_FORM_LABEL_HAS_BEEN_CREATED: 'L\'étiquette a été créée',
  SNACKBAR_NEW_ENROLLMENT_HAS_BEEN_ADDED: 'Nouvelle inscription ajoutée',
  SNACKBAR_NEW_FORM_HAS_BEEN_ADDED: 'Nouveau formulaire ajouté',
  SNACKBAR_NEW_PARTICIPANT_HAS_BEEN_ADDED: 'Nouveau participant ajouté',
  SNACKBAR_PROJECT_LANGUAGE_IMPORTED: 'La langue a été importée',
  SNACKBAR_PROJECT_LANGUAGE_IMPORT_FAILED: 'Échec de l\'importation de la langue',
  SNACKBAR_SHARE_REQUEST_ACCEPTED: 'Demande de partage acceptée.',
  SNACKBAR_SHARE_REQUEST_REJECTED: 'Demande de partage rejetée.',
  SNACKBAR_SHARE_REQUEST_SENT: 'Demande de partage envoyée avec succès',
  SNACKBAR_SHARE_REQUEST_WITHDRAWN: 'Demande de partage retirée.',
  SNACKBAR_UNKNOWN_ERROR: 'Erreur inconnue',
  TITLE_ENROLLMENT_DETAILS: 'Détails de l\'inscription',
}