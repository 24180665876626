import { PraxisButton } from "../../PraxisButton/PraxisButton";
import styles from "./PraxisCalendar.shadow.css";

// Not tested for years lower than 1000

// Look at http://cldr.unicode.org/ for locale codes

interface ICalendarDictionary {
  [locale: string]: {
    full: string[];
    short: string[];
  }
}

interface IExtrema {
  year: number;
  month: number;
  date: number;
}

export class PraxisCalendar extends HTMLElement{
  public static is = 'praxis-calendar';
  public static get observedAttributes(): string[] {
    return [
      'max',
      'min',
      'value',
    ];
  }

  private static Month: ICalendarDictionary = {
    'en-CA': {
      full: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  };

  private static Days: ICalendarDictionary = {
    'en-CA': {
      full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    }
  };

  public get max(): string | null {
    return this.getAttribute('max');
  }

  public set max(val: string | null) {
    if (val) {
      this.setAttribute('max', val);
    } else {
      this.removeAttribute('max');
    }
  }

  public get min(): string | null {
    return this.getAttribute('min');
  }

  public set min(val: string | null) {
    if (val) {
      this.setAttribute('min', val);
    } else {
      this.removeAttribute('min');
    }
  }

  public get value(): string {
    return this.getAttribute('value') || this._defaultValue;
  }

  public set value(val: string) {
    this.setAttribute('value', val);
  }

  /**
   * Default value: today or closest date to today in the min/max range
   */
  private get _defaultValue(): string {
    const today = new Date(this._today.getTime() - (this._today.getTimezoneOffset() * 60000))
    const todayISO = today.toISOString().substring(0,10);
    return (this._belowMin(today) && this.min)
      || (this._aboveMax(today) && this.max)
      || todayISO;
  }

  private _maxDateTime?: number;
  private _aboveMax(val: Date): boolean {
    if (this._maxDateTime) {
      return val.getTime() > this._maxDateTime;
    } else {
      return false;
    }
  }

  private _minDateTime?: number;
  private _belowMin(val: Date): boolean {
    if (this._minDateTime) {
      return val.getTime() < this._minDateTime;
    } else {
      return false;
    }
  }

  private get _selectedDay(): number {
    const date = new Date(this.value);
    return isNaN(date.getTime()) ? -1 : date.getUTCDay();
  }

  private _dayTable: HTMLTableElement;
  private _monthTable: HTMLTableElement;
  private _yearTable: HTMLTableElement;
  private _selectedDayElement: HTMLDivElement;
  private _selectedDateElement: HTMLDivElement;
  private _todayButton: PraxisButton;
  private _monthButton: PraxisButton;
  private _yearButton: PraxisButton;
  private _cancelButton: PraxisButton;
  private _setButton: PraxisButton;
  private _upButton: PraxisButton;
  private _downButton: PraxisButton;
  private _unknownButton: PraxisButton;
  private _dayCells: NodeListOf<HTMLTableDataCellElement>;
  private _monthCells: NodeListOf<HTMLTableDataCellElement>;
  private _yearCells: NodeListOf<HTMLTableDataCellElement>;

  private _locale = 'en-CA';
  private _currentView: 'day' | 'month' | 'year' = 'day';
  private _selectedYear?: number | '????';
  private _selectedMonth?: number | '??';
  private _selectedDate?: number | '??';
  private _firstViewingYear: number;
  private _viewingYear: number;
  private _viewingMonth: number | '??';
  private _today: Date;
  private _todayYear: number;
  private _todayMonth: number;
  private _todayDate: number;
  private _min?: IExtrema;
  private _max?: IExtrema;

  constructor() {
    super();
    this._todayButtonClickHandler = this._todayButtonClickHandler.bind(this);
    this._dayTableClickHandler = this._dayTableClickHandler.bind(this);
    this._monthTableClickHandler = this._monthTableClickHandler.bind(this);
    this._yearTableClickHandler = this._yearTableClickHandler.bind(this);
    this._selectUnknown = this._selectUnknown.bind(this);
    this._close = this._close.bind(this);
    this._set = this._set.bind(this);
    const shadowRoot = this.attachShadow({mode:'open'});
    shadowRoot.innerHTML = `
      <style>${styles}
        @media(max-width:639px) and (orientation: portrait){
          #calendar{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          #grid{
            height:${visualViewport.width - 24}px;
            width:${visualViewport.width - 24}px;
          }
          #day tbody td{
            height: ${(visualViewport.width - 24)/7}px;
            width: ${(visualViewport.width - 24)/7}px;
          }
          #month tr, #year tr{ height: ${(visualViewport.width - 24)/4}px; }
          #month td, #year td{ width: ${(visualViewport.width - 24)/4}px; }
        }
      </style>
      <div id="calendar">
        <div id="head">
          <div id="selected-day"></div>
          <div id="selected-date"></div>
        </div>
        <div id="body">
          <div id="today">
            <praxis-button id="today-btn"></praxis-button>
          </div>
          <div id="navigation">
            <praxis-button id="year-btn"></praxis-button>
            <praxis-button id="month-btn"></praxis-button>
            <praxis-button id="up-btn" icon="chevron-up"></praxis-button>
            <praxis-button id="down-btn" icon="chevron-down"></praxis-button>
          </div>
          <div id="grid">
            <table id="day">
              <thead>
                <tr>
                  <td>Su</td><td>Mo</td><td>Tu</td><td>We</td><td>Th</td><td>Fr</td><td>Sa</td>
                </tr>
              </thead>
              <tbody>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
              </tbody>
            </table>
            <table id="month" hidden>
              <tr>
                <td data-value="1">Jan</td><td data-value="2">Feb</td><td data-value="3">Mar</td><td data-value="4">Apr</td>
              </tr>
              <tr>
                <td data-value="5">May</td><td data-value="6">Jun</td><td data-value="7">Jul</td><td data-value="8">Aug</td>
              </tr>
              <tr>
                <td data-value="9">Sep</td><td data-value="10">Oct</td><td data-value="11">Nov</td><td data-value="12">Dec</td>
              </tr>
            </table>
            <table id="year" hidden>
              <tr><td></td><td></td><td></td><td></td></tr>
              <tr><td></td><td></td><td></td><td></td></tr>
              <tr><td></td><td></td><td></td><td></td></tr>
              <tr><td></td><td></td><td></td><td></td></tr>
            </table>
          </div>
          <div id="unknown">
            <praxis-button id="unknown-btn">Day Unknown</praxis-button>
          </div>
        </div>
        <div id="footer">
          <praxis-button id="cancel-btn">Cancel</praxis-button>
          <praxis-button id="set-btn">Set</praxis-button>
        </div>
      </div>
    `;

    this._dayTable = shadowRoot.querySelector('#day') as HTMLTableElement;
    this._monthTable = shadowRoot.querySelector('#month') as HTMLTableElement;
    this._yearTable = shadowRoot.querySelector('#year') as HTMLTableElement;
    this._selectedDayElement = shadowRoot.querySelector('#selected-day') as HTMLDivElement;
    this._selectedDateElement = shadowRoot.querySelector('#selected-date') as HTMLDivElement;
    this._todayButton = shadowRoot.querySelector('#today-btn') as PraxisButton;
    this._monthButton = shadowRoot.querySelector('#month-btn') as PraxisButton;
    this._yearButton = shadowRoot.querySelector('#year-btn') as PraxisButton;
    this._cancelButton = shadowRoot.querySelector('#cancel-btn') as PraxisButton;
    this._setButton = shadowRoot.querySelector('#set-btn') as PraxisButton;
    this._upButton = shadowRoot.querySelector('#up-btn') as PraxisButton;
    this._downButton = shadowRoot.querySelector('#down-btn') as PraxisButton;
    this._unknownButton = shadowRoot.querySelector('#unknown-btn') as PraxisButton;

    this._dayCells = this._dayTable.querySelectorAll('tbody td');
    this._monthCells = this._monthTable.querySelectorAll('td');
    this._yearCells = this._yearTable.querySelectorAll('td');

    this._today = new Date();
    this._todayYear = this._today.getFullYear();
    this._todayMonth = this._today.getMonth() + 1;
    this._todayDate = this._today.getDate();

    this._todayButton.textContent = `Today is ${PraxisCalendar.Month[this._locale]?.full[this._todayMonth - 1]} ${this._todayDate}, ${this._todayYear}`;

    this._viewingYear = !this._selectedYear || this._selectedYear === '????' ? this._todayYear : this._selectedYear;
    this._viewingMonth = !this._selectedMonth || this._selectedMonth === '??' ? this._todayMonth : this._selectedMonth;

    this._firstViewingYear = this._viewingYear - 4;

    this._addEventListeners();
    this._update();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'max':
      case 'min':
        this._setExtrema(name, newValue);
        this._setValue(this.value);
        break;
      case 'value':
        this._setValue(newValue || this._defaultValue);
        break;
    }
    this._update();
  }

  public connectedCallback(): void {
    document.body.style.overflow = 'hidden';
  }

  public disconnectedCallback(): void {
    document.body.style.overflow = '';
  }

  private _setExtrema(type: 'max' | 'min', value: string | null) {
    if (!value) {
      return;
    }

    const [year, month, date] = value.split('-').map(v => parseInt(v)) as [number, number, number];

    if (!(isNaN(year) || isNaN(month) || isNaN(date))) {
      if (type === 'max') {
        this._max = { year, month, date };
        this._maxDateTime = new Date(value).getTime();
      }
      if (type === 'min') {
        this._min = { year, month, date };
        this._minDateTime = new Date(value).getTime();
      }
    }
  }

  private _addEventListeners() {
    this._cancelButton.addEventListener('click', this._close);
    this._setButton.addEventListener('click', this._set);
    this._monthButton.addEventListener('click', this._updateView.bind(this, 'month'));
    this._yearButton.addEventListener('click', this._updateView.bind(this, 'year'));
    this._upButton.addEventListener('click', this._navigate.bind(this, 'up'));
    this._downButton.addEventListener('click', this._navigate.bind(this, 'down'));

    this._todayButton.addEventListener('click', this._todayButtonClickHandler);
    this._dayTable.addEventListener('click', this._dayTableClickHandler);
    this._monthTable.addEventListener('click', this._monthTableClickHandler);
    this._yearTable.addEventListener('click', this._yearTableClickHandler);

    this._unknownButton.addEventListener('click', this._selectUnknown);
  }

  private _dayTableClickHandler(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'td' && !target.hasAttribute('disabled') && target.textContent) {
      this._selectedDate = parseInt(target.textContent);
      this._selectedMonth = this._viewingMonth;
      this._selectedYear = this._viewingYear;
      this.value = `${this._selectedYear}-${this._selectedMonth.toString().padStart(2, '0')}-${this._selectedDate.toString().padStart(2, '0')}`;
      this._update();
    }
  }

  private _monthTableClickHandler(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'td' && !target.hasAttribute('disabled') && target.dataset.value) {
      this._viewingMonth = parseInt(target.dataset.value);
      this._updateView('day');
    }
  }

  private _yearTableClickHandler(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'td' && !target.hasAttribute('disabled') && target.textContent) {
      this._viewingYear = parseInt(target.textContent);
      this._updateView('month');
    }
  }

  private _todayButtonClickHandler() {
    if (!this._belowMin(this._today) && !this._aboveMax(this._today)) {
      this._selectedDate = this._todayDate;
      this._selectedMonth = this._todayMonth;
      this._selectedYear = this._todayYear;
      this.value = this._defaultValue;
    }
    this._viewingMonth = this._todayMonth;
    this._viewingYear = this._todayYear;
    this._updateView('day');
  }

  private _selectUnknown() {
    if (this._currentView === 'year') {
      this._selectedYear = '????';
      this._selectedMonth = '??';
    } else if (this._currentView === 'month') {
      this._selectedYear = this._viewingYear;
      this._selectedMonth = '??';
    } else {
      this._selectedYear = this._viewingYear;
      this._selectedMonth = this._viewingMonth;
    }
    this._selectedDate = '??';
    this.value = `${this._selectedYear}-${this._selectedMonth.toString().padStart(2, '0')}-??`;
    this._unknownButton.classList.add('selected');
    this._update();
  }

  private _updateView(type: 'day' | 'month' | 'year') {
    this._currentView = type;
    this._dayTable.hidden = type !== 'day';
    this._monthTable.hidden = type !== 'month';
    this._yearTable.hidden = type !== 'year';
    this._update();
  }

  private _close() {
    this.dispatchEvent(new Event('close'));
    this.remove();
  }

  private _navigate(type: 'up' | 'down') {
    switch (this._currentView) {
      case 'year':
        this._firstViewingYear += type === 'up' ? -16 : 16;
        break;
      case 'month':
        this._viewingYear += type === 'up' ? -1 : 1;
        break;
      case 'day':
        if (this._viewingMonth !== '??') {
          this._viewingMonth += type === 'up' ? -1 : 1;
        }
        if (this._viewingMonth === 13) {
          this._viewingMonth = 1;
          this._viewingYear += 1;
        } else if (this._viewingMonth === 0) {
          this._viewingMonth = 12;
          this._viewingYear -= 1;
        }
        break;
    }

    this._update();
  }

  private _set() {
    const value = this.value;
    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: { value } }));
    this._close();
  }

  private _setValue(val: string) {
    const [year, month, day] = val.split('-').map(Number) as [number, number, number];
    this._selectedDate = isNaN(day) ? '??' : day;
    this._selectedMonth = isNaN(month) ? '??' : month;
    this._selectedYear = isNaN(year) ? '????' : year;

    const [yyyy, mm] = this._defaultValue.split('-').map(Number) as [number, number];
    if (this._selectedYear === '????') {
      this._viewingYear = yyyy;
      this._viewingMonth = mm;
      this._updateView('year');
    } else {
      this._viewingYear = this._selectedYear;
      if (this._selectedMonth === '??') {
        this._viewingMonth = mm;
        this._updateView('month');
      } else {
        this._viewingMonth = this._selectedMonth;
      }
    }
    // (first year on grid) % 16 = 1
    this._firstViewingYear = this._viewingYear - ((this._viewingYear - 1) % 16);
  }

  private _update() {
    switch (this._currentView) {
      case 'day': this._updateDayTableValues(); break;
      case 'month': this._updateMonthViewLabels(); break;
      case 'year': this._updateYearViewLabels(); break;
    }

    if (this._selectedYear === '????') {
      this._selectedDayElement.textContent = `Date unknown`;
      this._selectedDateElement.textContent = ``;
    } else {
      if (this._selectedMonth === '??') {
        this._selectedDayElement.textContent = `Day and month unknown`;
        this._selectedDateElement.textContent = `${this._selectedYear}`;
      } else {
        if (this._selectedMonth) {
          if (this._selectedDate === '??') {
            this._selectedDayElement.textContent = `Day unknown`;
            this._selectedDateElement.textContent = `${PraxisCalendar.Month[this._locale]?.full[this._selectedMonth - 1]}, ${this._selectedYear}`;
          } else {
            this._selectedDayElement.textContent = PraxisCalendar.Days[this._locale]?.full[this._selectedDay] || '';
            this._selectedDateElement.textContent = `${PraxisCalendar.Month[this._locale]?.full[this._selectedMonth - 1]} ${this._selectedDate}, ${this._selectedYear}`;
          }
        }
      }
    }
  }

  private _updateDayTableValues() {
    if (this._viewingMonth === '??') {
      return;
    }
    this._yearButton.textContent = this._viewingYear.toString();
    this._monthButton.textContent = PraxisCalendar.Month[this._locale]?.full[this._viewingMonth - 1] || '';
    this._unknownButton.textContent = 'Day unknown';

    const firstDateOfSelectedMonth = new Date(`${this._viewingYear}-${this._viewingMonth.toString().padStart(2,'0')}-01`);
    const lastDateOfSelectedMonth = new Date(
      (
        this._viewingMonth === 12
          ? new Date(`${this._viewingYear + 1}-01-01`)
          : new Date(`${this._viewingYear}-${(this._viewingMonth + 1).toString().padStart(2,'0')}-01`)
      ).getTime() - 1
    );

    const firstDay = firstDateOfSelectedMonth.getUTCDay();
    const lastDate = lastDateOfSelectedMonth.getUTCDate();

    const couldBeToday = this._viewingYear === this._todayYear && this._viewingMonth === this._todayMonth;
    const couldBeSelected = this._viewingYear === this._selectedYear && this._viewingMonth === this._selectedMonth;
    const couldBeOverMax = this._max && this._viewingYear === this._max.year && this._viewingMonth === this._max.month;
    const couldBeUnderMin = this._min && this._viewingYear === this._min.year && this._viewingMonth === this._min.month;
    const isOverMax = this._max && (
      this._viewingYear > this._max.year ||
      (this._viewingYear === this._max.year && this._viewingMonth > this._max.month)
    );
    const isUnderMin = this._min && (
      this._viewingYear < this._min.year ||
      (this._viewingYear === this._min.year && this._viewingMonth < this._min.month)
    );
    this._dayCells.forEach((e, i) => {
      const value = i - firstDay + 1;
      if (i >= firstDay && value <= lastDate) {
        e.textContent = `${value}`;
        if (couldBeToday && this._todayDate === value) {
          e.classList.add('today');
        } else {
          e.classList.remove('today');
        }

        if (couldBeSelected && this._selectedDate === value) {
          e.classList.add('selected');
        } else {
          e.classList.remove('selected');
        }

        if (
          this._max && (isOverMax || (couldBeOverMax && value > this._max.date)) ||
          this._min && (isUnderMin || (couldBeUnderMin && value < this._min.date))
        ) {
          e.setAttribute('disabled', '');
        } else {
          e.removeAttribute('disabled');
        }
      } else {
        e.textContent = '';
        e.classList.remove('today');
        e.classList.remove('selected');
      }
    })

    this._yearButton.textContent = this._viewingYear.toString();
    this._monthButton.textContent = PraxisCalendar.Month[this._locale]?.full[this._viewingMonth - 1] || '';

    if (this._selectedYear !== '????' && this._selectedMonth !== '??' && this._selectedDate === '??') {
      this._unknownButton.classList.add('selected');
    } else {
      this._unknownButton.classList.remove('selected');
    }
  }

  private _updateMonthViewLabels() {
    this._yearButton.textContent = this._viewingYear.toString();
    this._monthButton.textContent = '';
    this._unknownButton.textContent = 'Month unknown';

    if (this._selectedYear !== '????' && this._selectedMonth === '??') {
      this._unknownButton.classList.add('selected');
    } else {
      this._unknownButton.classList.remove('selected');
    }

    this._monthCells.forEach(e => {
      if (e.dataset.value) {
        const value = parseInt(e.dataset.value);

        if (this._selectedYear === this._viewingYear && this._selectedMonth === value) {
          e.classList.add('selected');
        } else {
          e.classList.remove('selected');
        }

        if (
          this._max && (this._viewingYear > this._max.year || this._viewingYear === this._max.year && value > this._max.month) ||
          this._min && (this._viewingYear < this._min.year || this._viewingYear === this._min.year && value < this._min.month)
        ) {
          e.setAttribute('disabled', '');
        } else {
          e.removeAttribute('disabled');
        }
      }
    })
  }

  private _updateYearViewLabels() {
    this._yearButton.textContent = '';
    this._monthButton.textContent = '';
    this._unknownButton.textContent = 'Date unknown';

    if (this._selectedYear === '????') {
      this._unknownButton.classList.add('selected');
    } else {
      this._unknownButton.classList.remove('selected');
    }

    this._yearCells.forEach((e, i) => {
      const value = this._firstViewingYear + i;
      e.textContent = `${value}`;

      if (this._selectedYear === value) {
        e.classList.add('selected');
      } else {
        e.classList.remove('selected');
      }

      if (
        this._max && value > this._max.year ||
        this._min && value < this._min.year
      ) {
        e.setAttribute('disabled', '');
      } else {
        e.removeAttribute('disabled');
      }
    })
  }
}

window.customElements.define(PraxisCalendar.is, PraxisCalendar);
