import { AnyAction, combineReducers } from 'redux';
import { ThunkAction } from "redux-thunk";
import { commonReducers } from '@/Common/reducers';
import { ErrorActionTypes } from "@/Common/reducers/errors/errors";

/**
 * Combine all of the reducers here
 */
export const rootReducer = combineReducers({
  ...commonReducers
})

export type RootParticipantState = ReturnType<typeof rootReducer>

export type ParticipantThunkAction<A extends AnyAction = AnyAction> = ThunkAction<void, RootParticipantState, unknown, A | ErrorActionTypes>;
