import { PraxisProgressBar } from "../PraxisProgressBar/PraxisProgressBar";
import styles from "./PraxisProgressScreen.shadow.css";

/**
 * Shows progress status and block user from editing the screen.
 */
export class PraxisProgressScreen extends HTMLElement {
  public static is = 'praxis-progress-screen';

  public static get observedAttributes(): string[] {
    return [
      'label',
    ];
  }

  private progressBar: PraxisProgressBar;

  private template = `
    <style>${styles}</style>
    <div id="body"></div>
    <div id="foot"><praxis-progress-bar></praxis-progress-bar></div>
  `;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.progressBar = shadowRoot.querySelector('praxis-progress-bar') as PraxisProgressBar;
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'label':
        this.setLabel(newValue);
        break;
    }
  }

  public setLabel(text: string): void {
    this.progressBar.setLabel(text);
  }
}

window.customElements.define(PraxisProgressScreen.is, PraxisProgressScreen);
