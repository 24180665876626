import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
import styles from "./PraxisButton.shadow.css";

/**
 * Display button
 *
 * Example:
 * ```html
 * <praxis-button>sample text</praxis-button>
 * ```
 */
export class PraxisButton extends HTMLElement {
  public static get observedAttributes(): string[] {
    return [
      'icon',
      'icon-right',
      'size',
    ];
  }

  public get disabled(): boolean {
    return this.hasAttribute('disabled');
  }

  public set disabled(value: boolean) {
    if (value) {
      this.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
    }
  }

  private iconElement: PraxisIcon;
  private iconRightElement: PraxisIcon;

  private template = `
    <style>${styles}</style>
    <div icon><praxis-icon></praxis-icon></div>
    <div loader class="lds-ring"><div></div><div></div><div></div><div></div></div>
    <div text><slot></slot></div>
    <div icon-right><praxis-icon></praxis-icon></div>
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
  `;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.iconElement = shadowRoot.querySelector('praxis-icon') as PraxisIcon;
    this.iconElement.hidden = true;
    this.iconRightElement = shadowRoot.querySelector('[icon-right] praxis-icon') as PraxisIcon;
    this.iconRightElement.hidden = true;
    this.addEventListener('click', e => {
      if (this.hasAttribute('disabled')) {
        e.stopImmediatePropagation();
      }
    })
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'icon':
        this.setIcon(newValue);
        break;
      case 'icon-right':
        this.setIconRight(newValue);
        break;
      case 'size':
        this.setSize(newValue);
        break;
    }
  }
  public setIcon(text: string | null): void {
    if (text) {
      this.iconElement.setAttribute('name', text);
      this.iconElement.hidden = false;
    } else {
      this.iconElement.removeAttribute('name');
      this.iconElement.hidden = true;
    }
  }
  public setIconRight(text: string | null): void {
    if (text) {
      this.iconRightElement.setAttribute('name', text);
      this.iconRightElement.hidden = false;
    } else {
      this.iconRightElement.removeAttribute('name');
      this.iconElement.hidden = true;
    }
  }
  public setSize(text: string | null): void {
    if (text) {
      this.iconElement.setAttribute('size', text);
      this.iconRightElement.setAttribute('size', text);
    } else {
      this.iconElement.removeAttribute('size');
      this.iconRightElement.removeAttribute('size');
    }
  }
}

window.customElements.define('praxis-button', PraxisButton);
