import styles from "./PraxisIcon.shadow.scss";

/**
 * Display icon using name
 *
 * Example:
 * ```html
 * <praxis-icon name="example"></praxis-icon>
 * ```
 */
export class PraxisIcon extends HTMLElement {
  public static is = 'praxis-icon';
  public static NAME_ATTRIBUTE = 'name';
  public static get observedAttributes(): string[] {
    return [
      PraxisIcon.NAME_ATTRIBUTE,
    ];
  }

  // TODO: update sign-out icon when available
  private template = `<style>${styles}</style><i></i><slot></slot>`;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
  }
}

customElements.define(PraxisIcon.is, PraxisIcon);
