import styles from "./PraxisProfilePicture.shadow.css";

export class PraxisProfilePicture extends HTMLElement {
  private static INITIALS_ATTRIBUTE = 'initials';

  private template = `<style>${styles}</style><slot></slot>`;

  private static get observedAttributes(): string[] {
    return [PraxisProfilePicture.INITIALS_ATTRIBUTE];
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisProfilePicture.INITIALS_ATTRIBUTE:
        this.setInitials(newValue);
        break;
    }
  }

  public setInitials(text: string): void {
    this.textContent = text;
  }
}

window.customElements.define('praxis-profile-picture', PraxisProfilePicture);
