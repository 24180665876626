export const getCert = (token: string): Promise<void> => {

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("X-Qlik-xrfkey", "0000000000000000")
  headers.set('Content-Type', 'application/json; charset=utf-8');

  const requestOptions: RequestInit = {
    headers,
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  };

  return fetch(`${process.env.PRAXIS_CONNECT_REPORT_ORIGIN}/qrs/about?xrfkey=0000000000000000`, requestOptions)
    .then(res => {
      if (res.ok) {
        return;
      } else {
        throw {errors:['Unexpected error has occurred while retrieving report token']};
      }
    });
}
