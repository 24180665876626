import { IParticipantSearch } from '@/Common/interfaces/IParticipant';
import { secureRequest } from '../secureRequest';
import { IProject } from '@/Common/interfaces/IProject';

export const getProjects = (): Promise<{projects:IProject[]}> => {
  return secureRequest.request('GET', 'Projects')
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('project')));
}

export const getParticipants = (projectCode?: string, sort?: ParticipantSort, limit?: number, offset?: number, sortDesc?: boolean, searchstring?: string): Promise<{ totalCount: number, participants: IParticipantSearch[] }> => {

  const url = projectCode ? `Project/${projectCode}/Enrollments` : 'Enrollments';
  let params = '';
  params += sort != null ? `sort=${sort}&` : '';
  params += limit != null ? `limit=${limit}&` : '';
  params += offset != null ? `offset=${offset}&` : '';
  params += sortDesc != null ? `sortDesc=${sortDesc}&` : '';
  params += searchstring ? `searchstring=${searchstring}&` : '';
  return secureRequest.request(
    'GET',
    `${url}?${params == '' ? '' : params.substring(0, params.length - 1)}`,
  )
    .then(response => response.json())
    .catch(secureRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('participant')));
}

export enum ParticipantSort {
  ParticipantId = 'ParticipantId',
  LastUpdatedDate = 'LastUpdatedDate',
  Date = 'Date'
}