export type PraxisError = string;
export type ErrorType = keyof(IErrorState);

export interface IErrorState {
  auth: PraxisError[];
  snackbar: PraxisError[];
  view: PraxisError[];
}

/**
 * action types
 */
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PUSH_ERROR = 'PUSH_ERROR';

interface IPushErrorAction {
  type: typeof PUSH_ERROR;
  error: PraxisError;
  errorType: ErrorType;
}

interface IClearErrorsAction {
  type: typeof CLEAR_ERRORS;
  errorType: ErrorType;
}

export type ErrorActionTypes = IPushErrorAction | IClearErrorsAction;

const initState = {
  auth: [],
  snackbar: [],
  view: [],
}

/**
 * reducer
 */
export const errors = (state: IErrorState = initState, action: ErrorActionTypes): IErrorState => {
  switch (action.type) {
    case CLEAR_ERRORS: {
      const { errorType } = action;
      return {
        ...state,
        [errorType]: [],
      };
    }
    case PUSH_ERROR: {
      const { error, errorType } = action;
      const newState = {...state};
      newState[errorType].push(error);
      return newState;
    }
    default: {
      return state;
    }
  }
}
