import { _PraxisSelectCore } from "./_PraxisSelectCore";

export abstract class _PraxisSelectSingleSelection extends _PraxisSelectCore {
  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  public set value(val: string) {
    if (this.value !== val) {
      for (const input of this._inputElements || []) {
        if (input.value === val) {
          input.checked = true;
          break;
        }
      }
      if (this._wrappedElement && this._wrappedElement.value != val) {
        this._wrappedElement.value = val;
        if (!val) {
          //Deselect select dont trigger the input event, therefore manual fire is needed
          this._wrappedElement?.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
        }
      }
    }
  }
}