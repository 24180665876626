import { PraxisErrorMessage } from "../PraxisErrorMessage/PraxisErrorMessage";
import styles from "./PraxisRightPanel.shadow.css";
import { PraxisProgressBar } from "../PraxisProgressBar/PraxisProgressBar";
import { PraxisSnackbar } from "../PraxisSnackbar/PraxisSnackbar";

/**
 * Example
 * ```html
 * <praxis-right-panel>
 *   <div slot="content">Some content</div>
 *   <div slot="footer">
 *     <praxis-button id="cancel-btn">${i18n.BUTTON_CANCEL}</praxis-button>
 *     <praxis-button id="add-btn" disabled>${i18n.BUTTON_ADD}</praxis-button>
 *   </div>
 * </praxis-right-panel>
 * ```
 * ```html
 */
export class PraxisRightPanel extends HTMLElement {
  public static MAIN_ERROR_MESSAGE_ATTRIBUTE = 'main-error-message';

  private template = `
    <style>${styles}</style>
    <div id='loader'>
      <div class="lds-facebook"><div></div><div></div><div></div></div>
    </div>
    <div id="container" hidden>
      <praxis-error-message collapsible main-message="We found the following issues:" hidden></praxis-error-message>
      <div id="content"><slot name="content"></slot></div>
      <div id="sticky"><slot name="sticky"></slot></div>
      <div id="footer"><slot name="footer"></slot></div>
    </div>
    <praxis-progress-bar id='working-progress-bar' class='no-border thin' hidden></praxis-progress-bar>
  `;
  private _loadingProgressBar: PraxisProgressBar;
  private _workingProgressBar: PraxisProgressBar;
  private _errorMessage: PraxisErrorMessage;
  private _container: HTMLDivElement;

  // private static get observedAttributes(): string[] {
  //   return [
  //   ];
  // }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this._container = shadowRoot?.querySelector('#container') as HTMLDivElement;
    this._loadingProgressBar = shadowRoot.querySelector('#loader') as PraxisProgressBar;
    if (this.hasAttribute('no-loading')){
      this._loadingProgressBar.remove();
      this._container.hidden = false;
    }
    this._workingProgressBar = shadowRoot.querySelector('#working-progress-bar') as PraxisProgressBar;
    this._errorMessage = shadowRoot.querySelector('praxis-error-message') as PraxisErrorMessage;
    const mainErrorMessage = this.getAttribute(PraxisRightPanel.MAIN_ERROR_MESSAGE_ATTRIBUTE);
    if (mainErrorMessage){
      this._errorMessage.setAttribute(PraxisErrorMessage.MAIN_MESSAGE_ATTRIBUTE, mainErrorMessage);
    }
  }

  // private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
  //   if (oldValue === newValue) return;
  //   switch (name) {
  //   }
  // }

  public loadComplete(){
    if (this._loadingProgressBar) {
      this._loadingProgressBar.hidden = true;
    }
    this._container.hidden = false;
  }

  public startProgressing(){
    this._workingProgressBar.hidden = false;
  }

  public doneProgressing(){
    this._workingProgressBar.hidden = true;
  }

  public showError(errors: string[], trace: string){
    this._errorMessage.addErrors(errors);
    this._errorMessage.addTrace(trace);
    this._errorMessage.show();
  }

  public clearError(){
    this._errorMessage.clear();
    this._errorMessage.hide();
  }
}

window.customElements.define('praxis-right-panel', PraxisRightPanel);
