// import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
// import styles from "./PraxisButton.shadow.css";

/**
 * Display button
 *
 * Example:
 * ```html
 * <praxis-button>sample text</praxis-button>
 * ```
 */
export class PraxisFieldPerRowGrid extends HTMLElement {
  public static is = 'praxis-question-per-row-grid';
  public static get observedAttributes(): string[] {
    return [
    ];
  }

  public constructor() {
    super();
    // const shadowRoot = this.attachShadow({ mode: "open" });
    const name = this.getAttribute('name');
    const header = this.querySelector('grid-header');
    if (header){
      let templateColumns = '';
      let selectColumns = '';
      let selectionWidth = 0;
      let questionWidth = 0;
      const children = header.children;
      for(let i=0; i< children.length; i++)
      {
        const width = Number.parseInt(children[i]?.getAttribute('data-width') || '0');
        if (i == 0){
          questionWidth = width;
        }else
        {
          selectionWidth += width;
          selectColumns += `${width}px `;
        }
        templateColumns += `${width}px `;
      }

      const style = document.createElement('style');
      style.setAttribute('scoped', '');
      style.innerHTML = `
        praxis-question-per-row-grid[name='${name}'] grid-header
        {
          grid-template-columns:${templateColumns}
        }
        praxis-question-per-row-grid[name='${name}'] [praxis-field-row]
        {
          grid-template-columns: ${questionWidth}px ${selectionWidth}px;
        }

        praxis-question-per-row-grid[name='${name}'] [praxis-row-control]::part(fieldset){
          grid-template-columns: ${selectColumns};
        }
        praxis-question-per-row-grid[name='${name}'] [praxis-row-control]::part(option column-${children.length-1}){
          border-right: 0;
        }
      `;

      this.prepend(style);
    }
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
  }
}

window.customElements.define(PraxisFieldPerRowGrid.is, PraxisFieldPerRowGrid);
