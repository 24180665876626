import { PraxisDate } from '../Controls/PraxisDate/PraxisDate';
import { PraxisInput } from '../Controls/PraxisInput/PraxisInput';
import { PraxisTime } from '../Controls/PraxisTime/PraxisTime';
import { PraxisErrorMessage } from '../PraxisErrorMessage/PraxisErrorMessage';
import { _PraxisControlElement } from '../Controls/_PraxisControlElement/_PraxisControlElement';
import { BranchLogic } from './EvaluateLogic/BranchLogic/BranchLogic';
import { SupportedInputs } from './EvaluateLogic/EvaluateLogic';
import { PraxisRadio } from '../Controls/PraxisRadio/PraxisRadio';
import { PraxisCheckbox } from '../Controls/PraxisCheckbox/PraxisCheckbox';
import styles from "./PraxisForm.shadow.scss";
import { PraxisSelect } from '../Controls/PraxisSelect/PraxisSelect';
import { PraxisSelectWithAnnotation } from '../Controls/PraxisSelectWithAnnotation/PraxisSelectWithAnnotation';
import { PraxisSectionNavigationControl } from './PraxisSectionNavigationControl/PraxisSectionNavigationControl';
import { PraxisButton } from '../PraxisButton/PraxisButton';
import { PraxisTextarea } from '../Controls/PraxisTextarea/PraxisTextarea';
import { ISNCSCIControl } from '../Controls/ISNCSCIControl/ISNCSCIControl';
import { PraxisNeurologyPicker } from '../Controls/PraxisNeurologyPicker/PraxisNeurologyPicker';
import { PraxisFormFieldComment } from '../PraxisFormFieldComment/PraxisFormFieldComment';
import { PraxisTag } from '../PraxisTag/PraxisTag';
import { CalculateLogic } from './EvaluateLogic/CalculateLogic/CalculateLogic';

const PRAXIS_ELEMENTS = {
  [PraxisDate.is]: PraxisDate,
  [PraxisTime.is]: PraxisTime,
  [PraxisInput.is]: PraxisInput,
  [PraxisRadio.is]: PraxisRadio,
  [PraxisCheckbox.is]: PraxisCheckbox,
  [PraxisSelect.is]: PraxisSelect,
  [PraxisTextarea.is]: PraxisTextarea,
  [PraxisNeurologyPicker.is]: PraxisNeurologyPicker,
  [ISNCSCIControl.is]: ISNCSCIControl,
}

export class PraxisForm extends HTMLElement {
  private _endOfSectionButton: PraxisButton;
  private allInputElements!: SupportedInputs[];
  private branchLogic!: BranchLogic;
  private calculateLogic!: CalculateLogic;
  private _shadowRoot: ShadowRoot;
  private _errorMessageSet: { [name: string]: PraxisErrorMessage | undefined } = {};
  private template = `<style>${styles}</style><form></form><div id="esb" hidden><praxis-button></praxis-button></div>`;

  private errorContainer?: HTMLDivElement;
  private errorMessageElement?: PraxisErrorMessage;
  private formElement: HTMLFormElement;

  public get formData() {
    const slot = this.formElement.querySelector('slot');
    const formElement = slot ? this : this.formElement;
    const formData = new FormData();
    formElement.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(`
      input:not([disabled]),
      select:not([with-annotation]):not([disabled]),
      textarea:not([disabled])`)
      .forEach(e => {
        const name = e.getAttribute('name');
        if (name) {
          if (e.tagName.toLowerCase() === 'select') {
            e.querySelectorAll('option').forEach(o => {
              if (o.selected) {
                formData.append(name, o.value);
              }
            })
          } else {
            if ((e.type === 'checkbox' && (e as HTMLInputElement)?.checked) || (e.type !== 'checkbox')) {
              formData.append(name, e.value || '');
            }
          }
        }
      });
    formElement.querySelectorAll<PraxisSelectWithAnnotation>('praxis-select-with-annotation')
      .forEach(e => {
        const select = e.selectInput;
        if (select && !select.disabled) {
          const name = select?.getAttribute('name');
          if (name) {
            select?.querySelectorAll('option').forEach(o => {
              if (o.selected) { formData.append(name, o.value); }
            })
          }
          const inputs = e.annotationInputs;
          inputs.forEach(e => {
            if (e && !e.disabled) {
              const name = e.getAttribute('name');
              if (name) {
                formData.append(name, e.value || '');
              }
            }
          })
        }
      });
    return formData;

    //before select-with-classification, all input element is under the form
    //after select-with-classification, the annotation input moved to select shadow dom
    //therefore new FormData(this.formElement) is not going to work. (The annotation input wil be missed)
    // if (slot) {
    //   const formData = new FormData();
    //   ...get all input element and add to fomr data
    //   return formData;
    // } else {
    //   return new FormData(this.formElement);
    // }
  }

  public get hasChange() {
    const lastEntries = this._lastSavedFormData.entries();
    const currentEntries = this.formData.entries();

    let a = lastEntries.next();
    let b = currentEntries.next();

    while (!a.done) {
      if (!b.value || a.value[0] !== b.value[0] || a.value[1] !== b.value[1]) {
        return true;
      }
      a = lastEntries.next();
      b = currentEntries.next();
    }

    // return Promise.resolve(b.done === false);
    return b.done === false;
  }

  public get valid(): boolean {
    return !Object.values(this._errorMessageSet).some(e => e && e.count > 0);
  }
  public get hasCriticalError(): boolean{
    return Object.values(this._errorMessageSet).some(e => e && e.isCritical);
  }

  public get readOnly(): boolean {
    return this.hasAttribute('readonly');
  }

  public set readOnly(val: boolean) {
    if (this.allInputElements) {
      for (const e of this.allInputElements) {
        const p = e.parentElement as _PraxisControlElement;
        if (!p.hasAttribute('calculate-logic')){
          p.readOnly = val;
        }
      }
      this.branchLogic.applyLogicToAllInputs();
    }
    if (val) {
      this.setAttribute('readonly', '');
    } else {
      this.removeAttribute('readonly');
    }
    if (this._sectionNavigationControl) {
      if (val) {
        this._sectionNavigationControl.setAttribute('readonly', '');
      } else {
        this._sectionNavigationControl.removeAttribute('readonly');
      }
    }
    if (!val){
      this._updateEndOfSectionButton();
    }
    this._endOfSectionButton.hidden = val;
  }

  public get isPartial(): boolean {
    return this.hasAttribute('ispartial');
  }
  public set isPartial(val: boolean) {
    if (val) {
      this.setAttribute('ispartial', '');
    } else {
      this.removeAttribute('ispartial');
    }
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public get disabled(): boolean {
    return this.hasAttribute('disabled');
  }

  public set disabled(val: boolean) {
    if (val) {
      for (const e of this.allInputElements) {
        e.disabled = true;
      }
      this.setAttribute('disabled', '');
    } else {
      for (const e of this.allInputElements) {
        e.disabled = false;
      }
      this.branchLogic.applyLogicToAllInputs();
      this.removeAttribute('disabled');
    }
  }

  public constructor() {
    super();
    this.clear = this.clear.bind(this);
    this._onChange = this._onChange.bind(this);
    //this._addInputEventListeners = this._addInputEventListeners.bind(this);
    this._updateValidationMessage = this._updateValidationMessage.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.formElement = shadowRoot.querySelector('form') as HTMLFormElement;
    this._endOfSectionButton = shadowRoot.querySelector('praxis-button') as PraxisButton;

    //the change event of the input inside the select with annotation DOM doesnt propagate
    //therefore a new event is needed 'praxis-input-change'
    //TODO: implement the new event 'praxis-input-change' to across all praxis input to combine both 'change' and 'annotation-change' event
    this.formElement.addEventListener('praxis-input-change', this._onChange);

    //update event is broadcasted by BranchLogic.ts
    //in order to trigger the branch logic for the subsequence input
    //(e.g. [a] and [b] depends on [a]), when input control [a] is focused and changed
    //change event which is a native event for that input will be broadcasted by the DOM
    //then the affected input [b] will be broadcasting a change event from the coding of BranchLogic.ts

    this.formElement.addEventListener('submit', e => e.preventDefault());
    this._endOfSectionButton.addEventListener('click', this._onClickEndOfSectionButton.bind(this))

    this._shadowRoot = shadowRoot;

    if (this.innerHTML.trim()) {
      //TODO: do we have slotted view???
      //Not form view form (e.g. dialog view?)
      this.load(this.innerHTML, true);
    }
  }

  public load(innerHTML: string, isIdentifierFieldHidden?: boolean, slotted?: boolean): void {
    if (this._loaded) {
      //reload
      if (this._sectionNavigationControl) {
        this._sectionNavigationControl.remove();
      }
      this._errorMessageSet = {};
    } else {
      this._loaded = true;
    }

    this.formElement.innerHTML = `<div id="error-container" hidden><praxis-error-message main-message="${i18n.ERROR_MESSAGE_ERRORS_IN_FORM}" container hidden></praxis-error-message></div>${slotted ? '<slot style="display:block;width:100%"></slot>' : innerHTML}`;
    this._setupFormElements(slotted);
    this._setupSections(isIdentifierFieldHidden);
    this.updateLastSavedFormData();

    // trigger setter again to apply the readonly mode on loaded inputs
    if (this.readOnly) {
      this.readOnly = true;
    }

    if (isIdentifierFieldHidden){
      this.showIdentifierTag();
    }
  }

  public scrollToFlaggedField(fieldName: string) {
    if(fieldName) {
      this._sectionNavigationControl?.scrollToFlagSection(fieldName);
    }
  }

  public getFormFieldComments(status: 'all' | 'open' | 'resolved' ): PraxisFormFieldComment[] {
    switch (status){
      case 'all': {
        return Array.from(this.formElement.querySelectorAll<PraxisFormFieldComment>('praxis-form-field-comments'));
      }
      case 'open': {
        return Array.from(this.formElement.querySelectorAll<PraxisFormFieldComment>(
          'praxis-form-field-comments[status="OpenQueryFromMonitor"], praxis-form-field-comments[status="OpenQueryFromResearchCoordinator"]'
        ));
      }
      case 'resolved': {
        return Array.from(this.formElement.querySelectorAll<PraxisFormFieldComment>(
          'praxis-form-field-comments:not([status="OpenQueryFromMonitor"]):not([status="OpenQueryFromResearchCoordinator"])'
        ));
      }
      default:
        return [];
    }
  }

  public getFormFieldComment(name: string): PraxisFormFieldComment | null {
    return this.formElement.querySelector<PraxisFormFieldComment>(`praxis-form-field-comments[name=${name}]`);
  }

  private showIdentifierTag(){
    const identifierFields = this.formElement.querySelectorAll('[is="praxis-field"][identifier]');
    for(const f of identifierFields){
      const tag = document.createElement("praxis-tag") as PraxisTag;
      tag.setAttribute('info', '');
      tag.textContent = 'identifier';
      const tags = f.querySelector('div[tags]') as HTMLDivElement;
      tags.prepend(tag);
    }
    this._sectionNavigationControl
  }

  public addFieldToChangeEvent(id: string) {
    const target = this.formElement.querySelector(`#${id}`) as HTMLElement;
    if (!target) { return; }

    target.addEventListener('praxis-input-change', () => {
      this.dispatchEvent(new CustomEvent(`${id}_change`, { detail: { target } }));
    })
  }

  public markFieldFlagComment(name: string, status: string){
    const commentContainer = this.formElement.querySelector<PraxisFormFieldComment>(`praxis-form-field-comments[name=${name}]`);
    commentContainer?.setAttribute('status', status);
  }

  public displayErrors(errors: string[]): void {
    if (this.errorMessageElement && this.errorContainer) {
      this.errorMessageElement.clear();
      errors.forEach(e => {
        if (this.errorMessageElement) {
          this.errorMessageElement.addError(e);
        } else {
          console.error('no errorMessageElement found');
        }
      });
      this.errorMessageElement.show();
      this.errorContainer.hidden = false;
    } else {
      console.error('no errorMessageElement found');
    }
  }

  public clear(): void {
    for (const e of this.allInputElements) {
      if (e.type === 'checkbox' || e.type === 'radio') {
        e.removeAttribute('checked');
      } else if (e.type === 'select') {
        for (const option of e.children) {
          option.removeAttribute('selected');
        }
        e.dispatchEvent(new Event('change'));
      } else {
        e.value = '';
      }
    }
  }

  public updateLastSavedFormData(): void {
    this._lastSavedFormData = this.formData;
  }

  private _loaded = false;
  private _sectionNavigationControl?: PraxisSectionNavigationControl;
  private _lastSavedFormData = new FormData();

  private _setupFormElements(slotted?: boolean) {
    // setup custom elements us [is] selector
    this._setupCustomElements(slotted);

    const formControlsContainer = slotted ? this : this.formElement;
    //exclude the input in classification with anootation
    //because the input will be pushed to the shadow dom while building the control in _setupCustomElements function
    //the classifcation with annotation controls (select and input) will be exposed by the control itself.
    this.allInputElements = Array.from(formControlsContainer.querySelectorAll(`
      input:not([annotation-field]),
      select:not([with-annotation]),
      textarea:not([annotation-field])`));

    formControlsContainer.querySelectorAll('praxis-select-with-annotation')
      .forEach(e => {
        const classificationWithAnnotationControl = e as PraxisSelectWithAnnotation;
        if (classificationWithAnnotationControl.selectInput) {
          this.allInputElements.push(classificationWithAnnotationControl.selectInput);
        }
        this.allInputElements.push(
          ...classificationWithAnnotationControl.annotationInputs);
      });

    // setup branch logic
    this.branchLogic = new BranchLogic(this.allInputElements);

    // setup calculate logic
    this.calculateLogic = new CalculateLogic(this.allInputElements);

    // evaluate all inputs with branch logic
    this.allInputElements.forEach(e => {
      this.branchLogic.evaluate(e);
      //this._addInputEventListeners(e);
    });

    this.errorContainer = formControlsContainer.querySelector('#error-container') as HTMLDivElement;
    this.errorMessageElement = formControlsContainer.querySelector('praxis-error-message') as PraxisErrorMessage;
    formControlsContainer.querySelectorAll('button,input[type="submit"]').forEach(e => {
      e.addEventListener('click', () => {
        if (!this.disabled) {
          this.dispatchEvent(new Event('submit'));
        }
      });
    })
  }

  private _onChange(e: Event) {
    const supportInput = e.composedPath().length > 0
      ? e.composedPath()[0] as SupportedInputs
      : e.target as SupportedInputs;
    const p = (supportInput.parentElement as _PraxisControlElement);

    //apply branch logic
    if (this.branchLogic) {
      this.branchLogic.evaluate(supportInput);
      p?.touch();
    }

    if (this.calculateLogic){
      this.calculateLogic.evaluate(supportInput);
    }

    this._updateValidationMessage(p, supportInput);

    // this._sectionNavigationControl?.updateInput(p);
    // this._sectionNavigationControl?.updateAllSection();

    this.dispatchEvent(new Event('change'));
  }

  public touchAndUpdateValidateMessage(): void {
    if (this.allInputElements) {
      for (const element of this.allInputElements) {
        const p = (element.parentElement as _PraxisControlElement);
        if (!p.disabled) {
          p.touch();
          this._updateValidationMessage(p, element);
          // this._sectionNavigationControl?.updateInput(p);
        }
      }
    }
    // this._sectionNavigationControl?.updateAllSection();
  }

  private _onClickEndOfSectionButton() {
    if (this._sectionNavigationControl) {
      const { currentSectionId, length } = this._sectionNavigationControl;
      if (currentSectionId < length) {
        if (this.isPartial) {
          this.dispatchEvent(new Event('save'));
        }
        this._sectionNavigationControl.updateSectionSelection(currentSectionId + 1);
      } else {
        this.dispatchEvent(new Event('save-and-close'));
      }
    }
  }

  private _setupCustomElements(slotted?: boolean) {
    const formControlsContainer = slotted ? this : this.formElement;
    formControlsContainer.querySelectorAll<HTMLInputElement | HTMLSelectElement>(`
      input:not([annotation-field]),
      select:not([with-annotation]),
      textarea:not([annotation-field])`)
      .forEach(e => {
        const is = e.getAttribute('is');
        const wrappingElement = PRAXIS_ELEMENTS[is || ''];
        if (wrappingElement) {
          wrappingElement.wrap(e);
        } else if (e.tagName === 'TEXTAREA') {
          PraxisTextarea.wrap(e);
        } else {
          PraxisInput.wrap(e);
        }
      });

    formControlsContainer.querySelectorAll<HTMLDivElement>('div[is="select-with-annotation"]')
      .forEach(e => {
        PraxisSelectWithAnnotation.wrap(e);
      });
  }

  private _updateValidationMessage(parentElement: _PraxisControlElement, childElement: SupportedInputs) {
    const { validationMessage } = parentElement;
    if (!validationMessage || parentElement.disabled) {
      //remove error message if any
      const errorMessage = this._errorMessageSet[childElement.name];
      if (errorMessage) {
        errorMessage.remove();
        delete this._errorMessageSet[childElement.name];
      }
    } else {
      if (!this._errorMessageSet[childElement.name]) {
        this._errorMessageSet[childElement.name] = new PraxisErrorMessage();
      }
      const errorMessage = this._errorMessageSet[childElement.name] as PraxisErrorMessage;
      errorMessage.clear();
      errorMessage.addError(validationMessage);
      errorMessage.isCritical = parentElement.hasCriticalError;

      const field = childElement.closest('[is="praxis-field"]');
      field?.prepend(errorMessage);
    }

    this._endOfSectionButton.disabled = !(
      !this.hasCriticalError &&
      (this.isPartial || this.valid || (this._sectionNavigationControl && this._sectionNavigationControl.length != this._sectionNavigationControl.currentSectionId))
    );
  }

  private _updateEndOfSectionButton() {
    if (this._sectionNavigationControl) {
      const { currentSectionId, length } = this._sectionNavigationControl;
      if (currentSectionId === length) {
        this._endOfSectionButton.removeAttribute('icon-right');
        this._endOfSectionButton.setAttribute('icon', 'completed');
        this._endOfSectionButton.textContent = `${i18n.BUTTON_ALL_DONE_SAVE} ${this.hasAttribute('participant') ? i18n.BUTTON_SUBMIT : i18n.BUTTON_CLOSE}`;
        if (!this.isPartial && !this.valid) {
          this._endOfSectionButton.disabled = true;
        }
      } else {
        this._endOfSectionButton.removeAttribute('icon');
        this._endOfSectionButton.setAttribute('icon-right', 'forward');
        this._endOfSectionButton.textContent = this.isPartial ? i18n.BUTTON_SAVE_GO_TO_NEXT_SECTION : i18n.BUTTON_GO_TO_NEXT_SECTION;
        this._endOfSectionButton.disabled = false;
      }
    }
  }

  private _setupSections(excludeIdentifierField?: boolean) {
    const sections = Array.from(this.formElement.querySelectorAll('section'));
    if (this._endOfSectionButton.parentElement) {
      this._endOfSectionButton.parentElement.hidden = false;
    }
    if (sections.length) {
      this._sectionNavigationControl = new PraxisSectionNavigationControl();
      this._sectionNavigationControl.load(sections, excludeIdentifierField);
      this._updateEndOfSectionButton();
      this._sectionNavigationControl.addEventListener('change', this._updateEndOfSectionButton.bind(this));
      this._sectionNavigationControl.addEventListener('change', () => this.dispatchEvent(new CustomEvent('section-change')));
      this._shadowRoot.appendChild(this._sectionNavigationControl);

      // if (sections.length > 1) {
      //   // TODO add next button
      //   this._sectionNavigationControl.currentSectionId
      // }
    }
  }
}

window.customElements.define('praxis-form', PraxisForm);
