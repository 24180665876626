import { CommonRootState } from "@/Common/reducers";
import PraxisShell from "@/Common/views/PraxisShell/PraxisShell";

type Params = {[key: string]: string};

interface IRouteResult {
  title: string;
  viewComponentName: string;
  projectCode?: string;
}

export interface IRouteConfig extends IRouteResult{
  /**
   * Pattern for routes
   * Use `:` for variable sub-path
   * Examples:
   * - /about
   * - /:projectCode
   */
  route: string;
  /**
   * RegExp used for match the path to provided `route`
   */
  matcher?: RegExp;
  /**
   * Experimental callback
   * TODO: avoid using it and remove if not needed
   */
  callback?: (params?: Params) => void;
  /**
   * Experimental extraction of params from route
   * TODO: avoid using it and remove if not needed
   */
  paramKeys?: (string|null)[];
}

// https://tools.ietf.org/html/rfc3986#appendix-A
// https://tools.ietf.org/html/rfc3986#page-50
const unreserved = "\\w\\-\\.\\_\\~"
const pct_encoded = '(\\%[0-9A-fa-f][0-9A-fa-f])'
const sub_delims = "\\!\\$\\&\\'\\(\\)\\*\\+\\,\\;\\=";
const pchar = `([${unreserved}${sub_delims}\\:\\@]|${pct_encoded})`;

export class Router {
  private static _instance: Router;

  private _routes?: IRouteConfig[];
  private _defaultRoute: IRouteResult;
  private _shellElement!: PraxisShell<CommonRootState>;

  private _projectCode = '';
  public get projectCode() { return this._projectCode; }
  private _enrollmentId = Number.MIN_VALUE;
  public get enrollmentId() { return this._enrollmentId; }
  private _instanceId = Number.MIN_VALUE;
  public get instanceId() { return this._instanceId; }

  private constructor() {
    Router._instance = this;
    this._defaultRoute = {
      title: 'Not found',
      viewComponentName: 'not-found-view',
    };
  }
  public static instance(): Router {
    return this._instance ? this._instance : new Router();
  }

  public init(routes: IRouteConfig[], shell: PraxisShell<CommonRootState>, defaultView = 'not-found-view'): void {
    this._shellElement = shell;
    this._routes = routes.map(this._initRoute);
    this._defaultRoute = {
      title: 'Not found',
      viewComponentName: defaultView,
    };
  }

  public navigate(path: string): IRouteResult {
    if (this._routes && this._routes.length) {
      for (const route of this._routes) {
        if (route.matcher && route.matcher.test(path)) {
          const params = route.paramKeys ? this._getParamValues(path, route.paramKeys) : { projectCode : '' };
          if (route.callback) {
            route.callback(params);
          }
          if (location.pathname !== path && path !== '/notfound') {
            history.pushState('', '', path);
          }

          const { projectCode, enrollmentId, instanceId } = params;
          this._projectCode = projectCode || '';
          this._enrollmentId = enrollmentId ? parseInt(enrollmentId) : Number.MIN_VALUE;
          this._instanceId = instanceId ? parseInt(instanceId) : Number.MIN_VALUE;
          const { title, viewComponentName } = route;

          this._shellElement.loadView(title, viewComponentName);
          this._shellElement.selectProject(projectCode);

          return { title, viewComponentName, projectCode };
        }
      }
    } else {
      //TODO: route to generic error page (sun)
      console.error('routes not initialized');
    }

    this._shellElement.loadView(this._defaultRoute.title, this._defaultRoute.viewComponentName);

    return this._defaultRoute;
  }

  private _initRoute(config: IRouteConfig): IRouteConfig {
    const pattern = config.route
      .replace(/:\w*$/i, `${pchar}*`)
      .replace(/:\w*\//gi, `${pchar}*\\/`);
    const matcher = new RegExp(`^${pattern}(#${pchar}*)?$`, 'i');

    const pathNames = config.route.match(/(\/:?)(\w+)/gi);
    const paramKeys = pathNames
      ? pathNames.map(e => e.startsWith('/:') ? e.replace('/:', '') : null)
      : undefined;
    return {...config, matcher, paramKeys}
  }

  private _getParamValues(path: string, paramKeys: (string|null)[]): Params {
    const params: Params = {};
    const values = path.match(new RegExp(`${pchar}+`, 'gi'));
    if (values) {
      for (let i = 0; i < paramKeys.length; i++) {
        const key = paramKeys[i];
        const value = values[i];
        if (key && value) {
          params[key] = value;
        }
      }
    }
    return params;
  }
}

export const router = Router.instance();
