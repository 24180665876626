
import { PraxisIcon } from "@/WebComponents/PraxisIcon/PraxisIcon";
import { _PraxisSelectMultipleSelection } from "../_PraxisSelectCore/_PraxisSelectMultipleSelection";
import styles from "./PraxisCheckbox.shadow.css";

export class PraxisCheckbox extends _PraxisSelectMultipleSelection {
  public static is = 'praxis-checkbox';

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'disabled':
      case 'readonly':
        this._toggleDisabledInputs();
        break;
    }
  }

  public focus(): void {
    const input = this._inputElements && this._inputElements[0];
    if (input) {
      input.focus();
    }
  }

  protected get _style(): string {
    let fieldsetStyle = '';
    if (this.hasAttribute('horizontal') && this._wrappedElement){
      fieldsetStyle = `
        .fieldset{
          grid-template-columns: repeat(${this._wrappedElement.options.length}, minmax(75px, 1fr))
        }
      `;
    }
    return styles + fieldsetStyle;
  }

  protected _setupSelectComponent(): void {
    this._setupFieldSet();
    this._inputElements = this._fieldSetElement.querySelectorAll('input');
    this._toggleDisabledInputs();
    this._addEventListeners();
  }

  protected _validate(): void {
    this._validationMessage = this._wrappedElement?.validationMessage || '';
  }

  private _addEventListeners(): void {
    this._fieldSetElement.addEventListener('click', e => {
      const input = e.target as HTMLInputElement;
      const { type, value } = input;
      if (type === 'checkbox' ) {
        if (this.values.includes(value)) {
          this.values = this.values.filter(v => v !== value);
        } else {
          this.values = [...this.values, value];
        }
      }
    });
  }

  private _setupFieldSet(): void {
    if (!this._wrappedElement) {
      return;
    }

    const options = Array.from(this._wrappedElement.options);
    const required = this.hasAttribute('required') ? ' required' : '';
    const values = [];
    const isRow = this.hasAttribute("praxis-row-control");

    let checkboxesMarkup = '';
    let id = 0;
    let noSelect = true;

    for (const o of options) {
      if (o.value) {
        let rowStyle = '';
        let columnPart = '';
        if (isRow){
          const position = Number.parseInt(o.getAttribute('data-position') || '0')+1;
          rowStyle = `grid-column:${position};`;
          columnPart = ` column-${position}`;
        }

        const selected = o.hasAttribute('selected');
        const checked = selected ? ' checked' : '';
        checkboxesMarkup += `
        <div
          part="option${columnPart}"
          class="option${columnPart}${o.disabled ? " disabled" : ""}"
          style="${rowStyle}"
          value="${o.value}">
          <input
            type="checkbox"
            value="${o.value}"
            id="${id}"
            name="_"
            ${checked}${required}${o.disabled ? " disabled" : ""}>
          <label for="${id}">${o.textContent}</label>
        </div>`;

        id++;

        if (selected) {
          noSelect = false;
          values.push(o.value);
        }
      }
    }

    this._values = values;

    if (noSelect){
      //set the original input (type = select) to no selection, otherwise the first one will be selected
      //the underlining value will be difference from the selection and causing error in branch logic and saving the form
      //at the same time should not inject an empty option because the value of the wrapped element will become [''] insetad of []
      this._wrappedElement.value = '-1';
    }

    this._fieldSetElement.innerHTML = checkboxesMarkup;
  }

  private _toggleDisabledInputs() {
    if (this._inputElements) {
      const disabled = this.disabled || this.readOnly;
      for (const input of this._inputElements) {
        input.disabled = disabled;
        const option = input.parentElement as HTMLDivElement;
        const existingIcon = option.querySelector('praxis-icon') as PraxisIcon;
        existingIcon?.remove();
        input.hidden = false;
        if(this.readOnly && (this.values.includes(input.value))) {
          const icon = document.createElement('praxis-icon') as PraxisIcon;
          icon.setAttribute('name', 'checkbox-checked');
          input.before(icon);
          input.hidden = true;
        }
      }
    }
  }
}
window.customElements.define(PraxisCheckbox.is, PraxisCheckbox);
