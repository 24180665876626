import { anonymousRequest } from "@/Common/services/dataCenter/anonymousRequest";

interface IDirectEntryFormRequest {
  markup: string;
  /**
   * formName
   */
  name: string;
  projectName: string;
}

export const getDirectEntryForm = (code: string): Promise<IDirectEntryFormRequest> => {
  return anonymousRequest.customRequest({
    additionalHeaders: { 'PC-Direct-Entry-Code': code },
    method: 'GET',
    path: `DirectEntry/${code}`,
  })
    .then(response => response.json())
    .catch(anonymousRequest.errorHandler(i18n.ERROR_SERVER_RETRIEVE_DEFAULT('direct entry')));
}

export const saveDirectEntryForm = (code: string, body: FormData): Promise<void> => {
  return anonymousRequest.customRequest({
    additionalHeaders: { 'PC-Direct-Entry-Code': code },
    body,
    formData: true,
    method: 'PUT',
    path: `DirectEntry/${code}`,
  })
    .then(() => undefined)
    .catch(anonymousRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('direct entry')));
}

export const submitDirectEntryForm = (code: string): Promise<void> => {
  //const body: BodyInit = JSON.stringify({ code });
  return anonymousRequest.customRequest({
    additionalHeaders: { 'PC-Direct-Entry-Code': code },
    method: 'POST',
    path: `DirectEntry/${code}`,
  })
    .then(() => undefined)
    .catch(anonymousRequest.errorHandler(i18n.ERROR_SERVER_PROCESS_DEFAULT('direct entry')));
}
