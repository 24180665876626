import { AbstractRequest } from "./AbstractRequest";
import {authService} from "@/Common/services/auth/auth";

class SecureRequest extends AbstractRequest {
  constructor() {
    super();
  }

  protected async _newHeaders(): Promise<Headers> {
    const headers = new Headers();
    const user = await authService.retrieveUserInformation();
    if (user){
      headers.append("Authorization", `Bearer ${user.accessToken}`);
    }else{
      console.log("Not user information retrieved");
    }
    return headers;
  }
}

export const secureRequest = new SecureRequest();