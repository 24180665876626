import defineCustomElement from "@/Common/utils/defineCustomElement";
import styles from "./PraxisOutstandingFlagINotification.shadow.scss";
import { router } from "@/Common/services/router/router";

export class PraxisOutstandingFlagNotification extends HTMLElement {
  public static get is(): string {
    return 'praxis-outstanding-flag-item';
  }

  private _shadowRoot: ShadowRoot;

  private href = '';
  private contentElement: HTMLDivElement;

  public constructor() {
    super();

    this._navigate = this._navigate.bind(this);

    const shadowRoot = this.attachShadow({ mode: 'open' });

    shadowRoot.innerHTML = `
    <style>${styles}</style>
    <praxis-icon name="flag"></praxis-icon>
    <div content>
    </div>
    `;
    this._shadowRoot = shadowRoot;
    this.contentElement = shadowRoot.querySelector('[content]') as HTMLDivElement;

    this.addEventListener('click', this._navigate);
  }

  public init(
    enrollmentId: string,
    participantId: string,
    projectCode: string,
    facilityName: string,
    formName: string,
    formType: string,
    formInstanceId: number,
    fieldName: string,
    lastUpdated: string){
    this.contentElement.innerHTML = `
      <div>Participant ID <span class="heading">(${participantId})</span></div>
      <div>${formName}</div>
      <div>${facilityName}</div>
      <div>${i18n.LABEL_LAST_UPDATED_ON(lastUpdated)}</div>
    `;

    if (formType == 'Enrollment') {
      this.href = `/${projectCode}/${enrollmentId}/enrollment#${fieldName}`;
    } else {
      this.href = `/${projectCode}/${enrollmentId}/${formInstanceId}#${fieldName}`;
    }
  }

  // public async connectedCallback(): Promise<void> { }

  private async _navigate() {
    router.navigate(this.href);
  }
}

defineCustomElement(PraxisOutstandingFlagNotification);
