import { PraxisIcon } from "@/WebComponents/PraxisIcon/PraxisIcon";
import { _PraxisSelectSingleSelection } from "../_PraxisSelectCore/_PraxisSelectSingleSelection";
import styles from "./PraxisRadio.shadow.css";

export class PraxisRadio extends _PraxisSelectSingleSelection {
  public static is = 'praxis-radio';

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'disabled':
      case 'readonly':
        this._toggleDisableInputs();
        break;
    }
    // this._validate();
  }

  public focus(): void {
    const selectedIndex = this._wrappedElement?.selectedIndex;
    const indexToFocus = selectedIndex && selectedIndex > 0 ? selectedIndex - 1 : 0;
    const input = this._inputElements && this._inputElements[indexToFocus];
    if (input) {
      input.focus();
    }
  }

  protected get _style(): string {
    let fieldsetStyle = '';
    if (this.hasAttribute('horizontal') && this._wrappedElement){
      fieldsetStyle = `
        .fieldset{
          grid-template-columns: repeat(${this._wrappedElement.options.length}, minmax(75px, 1fr))
        }
      `;
    }
    return styles + fieldsetStyle;
  }

  protected _setupSelectComponent(): void {
    this._setupFieldSet();
    this._inputElements = this._fieldSetElement.querySelectorAll('input');
    this._toggleDisableInputs();
    this._addEventListeners();
  }

  protected _validate(): void {
    this._validationMessage = this._wrappedElement?.validationMessage || '';
  }

  public refresh() {
    if (this._inputElements) {
      for (const input of this._inputElements) {
        input.checked = this.value == input.value;

        if (this.readOnly){
          input.hidden = true;

          const option = input.parentElement as HTMLDivElement;
          const existingIcon = option.querySelector('praxis-icon') as PraxisIcon;
          existingIcon?.remove();
          const icon = document.createElement('praxis-icon') as PraxisIcon;
          icon.setAttribute('name', this.value == input.value ? 'radio-button-checked' : 'radio-button');
          input.before(icon);
        }
      }
    }
  }

  private _addEventListeners(): void {
    this._fieldSetElement.addEventListener('click', e => {
      if (this.disabled || this.readOnly) {
        return;
      }
      const input = e.target as HTMLInputElement;
      if (input.type === 'radio' ) {
        if (this.value === input.value) {
          input.checked = false;
          this.value = '';
        } else {
          this.value = input.value
        }
      }
    });
  }

  private _toggleDisableInputs() {
    if (this._inputElements) {
      const disabled = this.disabled || this.readOnly;
      for (const input of this._inputElements) {
        const option = input.parentElement as HTMLDivElement;
        input.disabled = disabled || option.classList.contains('disabled');
        const existingIcon = option.querySelector('praxis-icon') as PraxisIcon;
        existingIcon?.remove();
        input.hidden = false;
        if (this.readOnly && (this.value == input.value)){
          const icon = document.createElement('praxis-icon') as PraxisIcon;
          icon.setAttribute('name', 'radio-button-checked');
          input.before(icon);
          input.hidden = true;
        }
      }
    }
  }

  private _setupFieldSet(): void {
    if (!this._wrappedElement) {
      return;
    }

    const options = Array.from(this._wrappedElement.options);
    const required = this.hasAttribute('required') ? ' required' : '';
    const isRow = this.hasAttribute("praxis-row-control");

    let radioButtonsMarkup = '';
    let id = 0;
    let noSelected = true;

    for (const o of options) {
      if (o.value) {
        let rowStyle = '';
        let columnPart = '';
        if (isRow){
          const position = Number.parseInt(o.getAttribute('data-position') || '0')+1;
          rowStyle = `grid-column:${position};`;
          columnPart = ` column-${position}`;
        }
        const selected = o.hasAttribute('selected');
        const checked = selected ? ' checked' : '';
        radioButtonsMarkup += `
        <div
          part="option${columnPart}"
          class="option${columnPart}${o.disabled ? " disabled" : ""}"
          value="${o.value}"
          style="${rowStyle}">
          ${o.disabled ? (checked ? `<div class="checked-disabled">${o.textContent}</div>` : `<div class="disabled">${o.textContent}</div>`) : `
            <input
              type="radio"
              value="${o.value}"
              id="${id}"
              name="_"
              ${checked}${required}>
            <label for="${id}">${o.textContent}</label>
          `}
        </div>`;

        id++;

        if (selected) {
          this.value = o.value;
          noSelected = false;
        }
      }
    }

    // add empty option when no selection found
    if (noSelected) {
      //set the original input (type = select) to no selection by setting the value to number min value,
      //otherwise the first one will be selected (due to the nature of radio type) and that is going to cause error in branch logic and saving the form again
      //at the same time should not inject an empty option during markup generate with value to '' or no value because the value of the wrapped element will become [''] insetad of []
      this._wrappedElement.value = `${Number.MIN_VALUE}`;
    }

    this._fieldSetElement.innerHTML = radioButtonsMarkup;
  }
}

window.customElements.define(PraxisRadio.is, PraxisRadio);
