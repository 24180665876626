import { Reducer } from "redux";
import { IProject } from "@/Common/interfaces/IProject";
import { sortAlphabetically } from "@/Common/utils/sortCompareFn";

export interface IProjectCollection<T> {
  [code:string]: T | undefined;
}

export interface IProjectsState<T> {
  status: 'loading' | 'loaded' | 'failed';
  collection: IProjectCollection<T>;
  sortedProjectCodes: string[];
}
/**
 * action types
 */
const LOAD_PROJECTS = 'LOAD_PROJECTS';
const UPDATE_PROJECT = 'UPDATE_PROJECT';
interface IProjectsAction {
  type: typeof LOAD_PROJECTS;
  projects: IProject[];
}
interface IProjectUpdateAction {
  type: typeof UPDATE_PROJECT;
  projectCode: string;
  project: Partial<IProject>
}

export type ProjectsActionTypes
  = IProjectsAction
  | IProjectUpdateAction;

const initState: IProjectsState<IProject> = {
  status: 'loading',
  collection: {},
  sortedProjectCodes: [],
}

/**
 * reducer
 */
export const projects: Reducer<IProjectsState<IProject>, ProjectsActionTypes> = (state = initState, action) => {
  switch (action.type) {
    case LOAD_PROJECTS: {
      const { projects } = action;
      const collection: IProjectCollection<IProject> = {};

      //No project found
      if (!projects || projects.length == 0){
        return {...state, status: 'loaded'};
      }

      for (const project of projects) {
        collection[project.code] = project;
      }

      return {
        status: 'loaded',
        collection,
        sortedProjectCodes: projects
          .sort((a, b) => sortAlphabetically(
            a.name.toLowerCase(), b.name.toLowerCase(),
          ))
          .map(p => p.code)
      };
    }
    case UPDATE_PROJECT: {
      const { projectCode, project } = action;
      const { collection } = state;

      const updatingProject = collection[projectCode];
      if (updatingProject) {
        return {
          ...state,
          collection: {
            ...collection,
            [projectCode]: {
              ...updatingProject,
              ...project,
            }
          },
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
}
