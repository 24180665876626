import { IBlobInstance, IFormGroupInstance } from "@/Common/interfaces/IFormGroupInstance";
import { IFormGroupInstances } from "@/Common/interfaces/IFormGroupInstances";
import { Reducer } from "redux";

export const LOAD_FORM_GROUP_INSTANCES = 'LOAD_FORM_GROUP_INSTANCES';
export const DELETE_FORM_GROUP_INSTANCE = 'DELETE_FORM_GROUP_INSTANCE';
export const UPDATE_FORM_GROUP_INSTANCE = 'UPDATE_FORM_GROUP_INSTANCE';
export const ADD_FILE_INSTANCE = 'ADD_FILE_INSTANCE';
export const UPDATE_FILE_INSTANCE = 'UPDATE_FILE_INSTANCE';
export const DELETE_FILE_INSTANCE = 'DELETE_FILE_INSTANCE';
export const ADD_FORM_INSTANCE = 'ADD_FORM_GROUP_INSTANCE_FORM_INSTANCE';
export const DELETE_FORM_INSTANCE = 'DELETE_FORM_GROUP_INSTANCE_FORM_INSTANCE';

interface ILoadFormGroupInstancesAction {
  type: typeof LOAD_FORM_GROUP_INSTANCES;
  formGroupInstances: IFormGroupInstance[];
}
interface IDeleteFormGroupInstanceAction{
  type: typeof DELETE_FORM_GROUP_INSTANCE;
  formGroupInstanceId: number;
}
interface IUpdateFormGroupInstanceAction{
  type: typeof UPDATE_FORM_GROUP_INSTANCE;
  formGroupInstanceId: number;
  facilityName: string;
  date: string;
}
interface IAddFileInstance{
  type: typeof ADD_FILE_INSTANCE;
  formGroupInstanceId: number;
  bolbInstance: IBlobInstance;
}
interface IUpdateFileInstance{
  type: typeof UPDATE_FILE_INSTANCE;
  formGroupInstanceId: number;
  blobInstanceId: number;
  newFileName: string;
}
interface IDeleteFileInstance{
  type: typeof DELETE_FILE_INSTANCE;
  formGroupInstanceId: number;
  blobInstanceId: number;
}
interface IAddFormInstance{
  type: typeof ADD_FORM_INSTANCE;
  formGroupInstanceId: number;
  formInstanceIds: number[];
}
interface IDeleteFormInstance{
  type: typeof DELETE_FORM_INSTANCE;
  formGroupInstanceId: number;
  formInstanceId: number;
  replacedFormInstanceId?: number;
}

export type FormGroupInstancesActionTypes
  = ILoadFormGroupInstancesAction
  | IDeleteFormGroupInstanceAction
  | IUpdateFormGroupInstanceAction
  | IAddFileInstance
  | IUpdateFileInstance
  | IDeleteFileInstance
  | IAddFormInstance
  | IDeleteFormInstance;

export const formGroupInstances: Reducer<IFormGroupInstances, FormGroupInstancesActionTypes> = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FORM_GROUP_INSTANCES: {
      const { formGroupInstances } = action;
      for(const formGroupInstance of formGroupInstances){
        state[formGroupInstance.id] = formGroupInstance;
      }
      return state;
    }
    case DELETE_FORM_GROUP_INSTANCE:{
      const { formGroupInstanceId } = action;
      delete state[formGroupInstanceId];
      return state;
    }

    case UPDATE_FORM_GROUP_INSTANCE:{
      const { formGroupInstanceId, facilityName, date } = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      formGroupInstance.facility = facilityName;
      formGroupInstance.date = date;
      return state;
    }

    case ADD_FILE_INSTANCE:{
      const {formGroupInstanceId, bolbInstance} = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      formGroupInstance.blobInstances = formGroupInstance.blobInstances ?? {};
      formGroupInstance.blobInstances[bolbInstance.id] = bolbInstance
      return state;
    }
    case UPDATE_FILE_INSTANCE:{
      const {formGroupInstanceId, blobInstanceId, newFileName} = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      const blobInstance = formGroupInstance.blobInstances[blobInstanceId];
      if (!blobInstance){
        throw "FormGroupInstance Redux: Blob Instsance not found";
      }
      blobInstance.name = newFileName;
      return state;
    }
    case DELETE_FILE_INSTANCE:{
      const {formGroupInstanceId, blobInstanceId} = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      delete formGroupInstance.blobInstances[blobInstanceId];
      return state;
    }

    case ADD_FORM_INSTANCE:{
      const {formGroupInstanceId, formInstanceIds} = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      formGroupInstance.formInstanceIds.push(...formInstanceIds);
      return state;
    }
    case DELETE_FORM_INSTANCE:{
      const {formGroupInstanceId, formInstanceId, replacedFormInstanceId} = action;
      const formGroupInstance = state[formGroupInstanceId];
      if (!formGroupInstance){
        throw "FormGroupInstance Redux: Form Group Instance not found";
      }
      const formInstanceIds = formGroupInstance.formInstanceIds
      if (!formInstanceIds){
        throw "FormGroupInstance Redux: Form Instance Ids not found";
      }
      const index = formInstanceIds.indexOf(formInstanceId);
      if (index > -1){
        formInstanceIds.splice(index, 1);
      }else{
        throw "FormGroupInstance Redux: Form Instance Id not found";
      }
      if (replacedFormInstanceId){
        formInstanceIds.push(replacedFormInstanceId);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
