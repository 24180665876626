import { PraxisButton } from "../../PraxisButton/PraxisButton";
import { PraxisCalendar } from "../PraxisCalendar/PraxisCalendar";
import { PraxisSelect } from "../PraxisSelect/PraxisSelect";
import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";
import styles from "./PraxisDate.shadow.css";

const PARTIAL_OPTION_LABELS = {
  '1': 'Day unknown',
  '2': 'Month unknown',
  '3': 'Date unknown',
};
const PARTIAL_OPTION_VALUES: {[key: string]: string} = {
  '1': '',
  '2': '',
  '3': '',
}
const MAX_LENGTH = 10;
const defaultPattern = '\\d{4}-\\d{2}-\\d{2}';
const defaultPatternRegex = /\d{4}-\d{2}-\d{2}/;
interface IDateFormat {
  positions: {
    yyyy: 0|1|2;
    mm: 0|1|2;
    dd: 0|1|2;
    0: 0|1|2,
    1: 0|1|2,
    2: 0|1|2,
  }
  delimiters: string[];
}

const defaultDateFormat: IDateFormat = {
  positions: {
    yyyy: 0,
    mm: 1,
    dd: 2,
    0: 0,
    1: 1,
    2: 2,
  },
  delimiters: ['-', '-'],
}

/**
 * <praxis-date>
 *   <input/>
 * </praxis-date>
 *
 * format: expects each of yyyy|mm|dd with non-alphanumeric delimiters in between
 * min/max: ISO date
 * value: custom ISO date for partial date
 */
export class PraxisDate extends _PraxisControlElement {
  public static is = 'praxis-date';
  public static get observedAttributes(): string[] {
    return [
      ..._PraxisControlElement.observedAttributes,
      'format',
      'max',
      'min',
      'partial',
      'placeholder',
      'value',
    ];
  }

  public get max(): string | null {
    if (this._maxDate)  {
      return this._maxDate.toISOString().substring(0,10);
    } else {
      return null;
    }
  }

  public set max(value: string | null) {
    if (value && defaultPatternRegex.test(value)) {
      this.setAttribute('max', value);
    } else {
      this.removeAttribute('max');
    }
  }

  public get min(): string | null {
    if (this._minDate) {
      return this._minDate.toISOString().substring(0,10);
    } else {
      return null;
    }
  }

  public set min(value: string | null) {
    if (value && defaultPatternRegex.test(value)) {
      this.setAttribute('min', value);
    } else {
      this.removeAttribute('min');
    }
  }

  public get partial(): boolean {
    return this.hasAttribute('partial');
  }

  public set partial(value: boolean) {
    if (value) {
      this.setAttribute('partial', '');
    } else {
      this.removeAttribute('partial');
    }
  }

  /**
   * @return {string} string in ISO date format
   */
  public get value(): string {
    return this._wrappedElement?.value || '';
  }

  /**
   * Set date using custom ISO date format
   * If value is not a valid date then value will be empty string
   * @param {string} value - string in custom ISO date format
   */
  public set value(value: string) {
    if (this._isValidDateFormat(value)) {
      this._inputElement.value = this._formatDate(value);
      if (value){
        this._inputElement.removeAttribute('empty');
      }else{
        this._inputElement.setAttribute('empty', '');
      }
      this._isReady && this._updatePartialDateSelection(value);
      if (this._wrappedElement) {
        this._wrappedElement.value = value;
        this._wrappedElement.dispatchEvent(new Event('input', {bubbles: true, composed: true}));
      }
    } else {
      if (this._wrappedElement) {
        this._wrappedElement.value = '';
        this._wrappedElement.dispatchEvent(new Event('input', {bubbles: true, composed: true}));
      }
    }
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    switch (name) {
      case 'disabled':
        this._setDisabled();
        break;
      // TODO
      // case 'format':
      //   this._setFormat();
      //   break;
      case 'max':
        this._maxDate = newValue ? this._newDate(newValue) : null;
        this._validate();
        break;
      case 'min':
        this._minDate = newValue ? this._newDate(newValue) : null;
        this._validate();
        break;
      case 'partial':
        this._setPartial();
        this._validate();
        break;
      case 'placeholder':
        this._inputElement.placeholder = newValue || '';
        break;
      case 'readonly':
        this._setReadOnly();
        break;
      case 'required':
        this._inputElement.required = this.hasAttribute('required');
        break;
      case 'value':
        this.value = newValue || '';
        break;
    }
  }

  public closeCalendar(): void {
    if (this._calendar) {
      this._calendar.remove();
      this._calendar = undefined;
      this._inputElement.focus();
    }
  }

  public focus(): void {
    this._inputElement.focus();
  }

  public openCalendar(): void {
    if (!this._calendar) {
      const { max, min, partial, value } = this;
      this._calendar = new PraxisCalendar();
      this._calendar.setAttribute('slot', 'calendar');
      this._calendar.max = max;
      this._calendar.min = min;
      this._calendar.value = value;
      partial && this._calendar.setAttribute('partial', '');

      this._calendar.addEventListener('change', (e) => {
        const { value } = (e as CustomEvent<{value: string}>).detail;
        this.value = value;
        this._handlePossibleChange();
      });
      this._calendar.addEventListener('close', this.closeCalendar.bind(this));
      this.append(this._calendar);
    }
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = `
      <style>${styles}</style>
      <form>
        <input pattern="${defaultPattern}" maxlength="${MAX_LENGTH}" empty/>
        <praxis-button icon="calendar"></praxis-button>
      </form>
      <praxis-select>
        <select>
          <option value="">Set a partial date</option>
          <option value="1">Day unknown (1970-01-??)</option>
          <option value="2">Month unknown (1970-??-??)</option>
          <option value="3">Date unknown (????-??-??)</option>
        </select>
      </praxis-select>
      <slot name="calendar"></slot>
    `;
    this._inputElement = shadowRoot.querySelector('input') as HTMLInputElement;
    this._calendarButton = shadowRoot.querySelector('praxis-button') as PraxisButton;
    this._selectElement = shadowRoot.querySelector('praxis-select') as PraxisSelect;
  }

  protected _inputElement: HTMLInputElement;
  protected _selector = 'input';
  protected _wrappedElement?: HTMLInputElement;

  protected _setupComponent(): void {
    this._previousInputElementValue = this._wrappedElement?.value || '';
    //The checking is needed to avoid praxis-input-change event fire
    if (this.value != this._previousInputElementValue) {
      this.value = this._previousInputElementValue;
    }
    this._previousValue = this.value;
    this._addEventListeners();
  }

  protected _validate(): void {
    const { validationMessage, validity } = this._inputElement;
    const { value } = this;
    this.hasCriticalError = validity.patternMismatch;
    if (!validationMessage || validity.customError) {
      const minPossibleDate =  this._newDate(value.replace(/\?\?/g, '01'));
      const errorMessage
        = !value && this._inputElement.value.length === MAX_LENGTH && i18n.ERROR_MESSAGE_FIELD_INVALID
        || (minPossibleDate && this._aboveMax(value, minPossibleDate)) && i18n.ERROR_MESSAGE_FIELD_RANGE_OVERFLOW(this.max)
        || (minPossibleDate && this._belowMin(value, minPossibleDate)) && i18n.ERROR_MESSAGE_FIELD_RANGE_UNDERFLOW(this.min)
        || ''
      this._inputElement.setCustomValidity(errorMessage);
    }
    this._validationMessage
      = this._inputElement.validationMessage
      || this._wrappedElement?.validationMessage
      || '';
  }

  private _calendar?: PraxisCalendar;
  private _calendarButton: PraxisButton;
  private _defaultDateFormat: IDateFormat = defaultDateFormat;
  private _format: IDateFormat | null = null;
  private _maxDate: Date | null = null;
  private _minDate: Date | null = null;
  private _preventBlur = false;
  private _preventFocusOut = false;
  private _previousInputElementValue = '';
  private _previousValue = '';
  private _selectElement: PraxisSelect;

  private _aboveMax(rawISODate: string, minPossibleDate: Date) {
    if (this._maxDate && rawISODate != '') {
      if (rawISODate === '????-??-??') {
        return false;
      } else {
        return minPossibleDate > this._maxDate;
      }
    } else {
      return false;
    }
  }

  private _addEventListeners() {
    this._onInput = this._onInput.bind(this);
    this._inputElement.addEventListener('input', this._onInput);
    this._inputElement.addEventListener('blur', this._handleBlur.bind(this));
    this._inputElement.addEventListener('focusout', this._handleFocusOut.bind(this));

    this._calendarButton.addEventListener('click', this.openCalendar.bind(this));
    this._calendarButton.addEventListener('mousedown', this._handleCalendarButtonDown.bind(this));

    this._selectElement.addEventListener('click', this._updateSelectOptionsLabels.bind(this));
    this._selectElement.addEventListener('praxis-input-change', this._handlePartialDateSelection.bind(this));
    this._selectElement.addEventListener('ready', () => { this._updatePartialDateSelection(this.value) });
  }

  private _belowMin(rawISODate: string, minPossibleDate: Date) {
    if (this._minDate && rawISODate != '') {
      if (rawISODate === '????-??-??') {
        return false;
      }
      if (rawISODate.endsWith('??-??')) {
        const maxPossibleDate = new Date(rawISODate.replace('??', '12').replace('??', '31'));
        return maxPossibleDate < this._minDate;
      } else if (rawISODate.endsWith('??')) {
        if (/-12-/.test(rawISODate)) {
          const maxPossibleDate = new Date(rawISODate.replace('??', '31'));
          return maxPossibleDate < this._minDate;
        } else {
          // compare with last day of the selected month
          const temp = new Date(minPossibleDate.getTime());
          temp.setUTCMonth(temp.getUTCMonth() + 1); // add 1 month
          temp.setTime(temp.getTime() - 12 * 3600); // subtract 12 hours
          const maxPossibleDate = new Date(temp.toISOString().substr(0, MAX_LENGTH));
          return maxPossibleDate < this._minDate;
        }
      } else {
        const diff = minPossibleDate.getTime() - this._minDate.getTime();
        //Get the date diff in milliseconds and compare in order to ignore the time component
        //because the minPossibleDate can be 2020-01-01:05:00:00 and the _minDate is 2020-01-01:14:00:00
        return (diff < -86400000);
      }
    } else {
      return false;
    }
  }

  private _handleBlur(event: FocusEvent) {
    if (this._preventBlur) {
      event.stopImmediatePropagation();
      this._preventBlur = false;
    }
  }

  /**
   * Setup to prevent blur/focusout events when opening calendar
   */
  private _handleCalendarButtonDown() {
    if (this.shadowRoot?.activeElement === this._inputElement) {
      this._preventBlur = true;
      this._preventFocusOut = true;
    }
  }

  private _handleFocusOut(event: FocusEvent) {
    if (this._preventFocusOut) {
      event.stopImmediatePropagation();
      this._preventFocusOut = false;
    }
  }

  private _handlePartialDateSelection() {
    const selectValue = this._selectElement.value;
    if (selectValue) {
      this.value = PARTIAL_OPTION_VALUES[selectValue] || '';
      this._handlePossibleChange();
    }
  }

  private _handlePossibleChange(): void {
    if (this._previousValue !== this.value) {
      this._previousValue = this.value;
    }
  }

  private _isValidDateFormat(value: string): boolean {
    const temp = value.replace(/\?\?/g,'01')
    const date = new Date(temp);
    return !isNaN(date.getTime()) && date.toISOString().substring(0,10) === temp;
  }

  private _newDate(val: string) {
    //convert time to local time zone
    //new Date('2020-01-01') is going to return Tue Dec 31 2019 16:00:00 GMT-0800 (Pacific Standard Time) - 2020-01-1 of UTC time
    //therefore conversion is need
    let date = new Date(val);
    date = new Date(date.getTime() + date.getTimezoneOffset()*60000);

    if (isNaN(date.getTime())) {
      return null;
    } else {
      return date;
    }
  }

  private _onInput() {
    const { value } = this._inputElement;
    if (this._previousInputElementValue !== value) {
      this._previousInputElementValue = value;
      // TODO: translate input value to iso format
      this.value = value;
      this._validate();
      this._handlePossibleChange();
    }
  }

  private _setDisabled() {
    const { disabled } = this;
    this._calendarButton.disabled = disabled;
    this._inputElement.disabled = disabled;
    this._selectElement.disabled = disabled;
  }

  private _setPartial() {
    const { positions } = this._format || this._defaultDateFormat;
    if (this.partial) {
      if (this._format) {
        const partialYearPattern = [];
        partialYearPattern[positions.yyyy] = '(\\d{4}|\\?{4})';
        partialYearPattern[positions.mm] = '\\?{2}';
        partialYearPattern[positions.dd] = '\\?{2}';
        const partialMonthPattern = [];
        partialMonthPattern[positions.yyyy] = '\\d{4}';
        partialMonthPattern[positions.mm] = '(\\d{2}|\\?{2})';
        partialMonthPattern[positions.dd] = '\\?{2}';
        const partialDayPattern = [];
        partialDayPattern[positions.yyyy] = '\\d{4}';
        partialDayPattern[positions.mm] = '\\d{2}';
        partialDayPattern[positions.dd] = '(\\d{2}|\\?{2})';
        this._inputElement.pattern = [
          `(${partialYearPattern.join('([^A-Za-z0-9?]+)')})`,
          `(${partialMonthPattern.join('([^A-Za-z0-9?]+)')})`,
          `(${partialDayPattern.join('([^A-Za-z0-9?]+)')})`,
        ].join('|');
      } else {
        this._inputElement.pattern = [
          '((\\d{4}|\\?{4})-\\?{2}-\\?{2})',
          '(\\d{4}-(\\d{2}|\\?{2})-\\?{2})',
          '(\\d{4}-\\d{2}-(\\d{2}|\\?{2}))',
        ].join('|');
      }
    } else {
      if (this._format) {
        const partialPattern = [];
        partialPattern[positions.yyyy] = '\\d{4}';
        partialPattern[positions.mm] = '\\d{2}';
        partialPattern[positions.dd] = '\\d{2}';
        this._inputElement.pattern = partialPattern.join('([^A-Za-z0-9?]+)');
      } else {
        this._inputElement.pattern = defaultPattern;
      }
    }

    if (this._wrappedElement) {
      this._wrappedElement.pattern = this.partial
        ? [
          '((\\d{4}|\\?{4})-\\?{2}-\\?{2})',
          '(\\d{4}-(\\d{2}|\\?{2})-\\?{2})',
          '(\\d{4}-\\d{2}-(\\d{2}|\\?{2}))',
        ].join('|')
        : defaultPattern;
    }
  }

  private _setReadOnly() {
    const { readOnly } = this;
    this._inputElement.readOnly = readOnly;
  }

  private _updatePartialDateSelection(value: string) {
    if (!value.endsWith('?')) {
      this._selectElement.value = '';
    } else {
      this._updateSelectOptionsLabels();
      if (/\d{4}-\d{2}-\?{2}/.test(value)) {
        this._selectElement.value = '1';
      } else if (/\d{4}-\?{2}-\?{2}/.test(value)) {
        this._selectElement.value = '2';
      } else if (/\?{4}-\?{2}-\?{2}/.test(value)) {
        this._selectElement.value = '3';
      } else {
        this._selectElement.value = '';
      }
    }
  }

  private _updatePartialOptionValues() {
    const { positions } = this._format || this._defaultDateFormat;
    const values = this._inputElement.value.split(/[^0-9?]+/);
    const yearValue = values[positions.yyyy] || '';
    const monthValue = values[positions.mm] || '';
    const date = new Date();
    const yyyy = (/\d{4}/.test(yearValue) && yearValue) || date.getFullYear();
    const mm = (/\d{2}/.test(monthValue) && monthValue) || (date.getMonth() + 1).toString().padStart(2, '0');

    PARTIAL_OPTION_VALUES['1'] = `${yyyy}-${mm}-??`;
    PARTIAL_OPTION_VALUES['2'] = `${yyyy}-??-??`;
    PARTIAL_OPTION_VALUES['3'] = `????-??-??`;
  }

  private _updateSelectOptionsLabels() {
    this._updatePartialOptionValues();
    for (const option of this._selectElement.options) {
      const value = option.value as keyof typeof PARTIAL_OPTION_LABELS;
      if (value) {
        option.textContent = `${PARTIAL_OPTION_LABELS[value]} (${PARTIAL_OPTION_VALUES[value]})`;
      }
    }
    this._selectElement.updateOptions();
  }

  // TODO: check code below this line

  /**
   * format date based from ISO format to pattern found in the format attribute
   */
  private _formatDate(value: string) {
    if (this._format) {
      const { positions, delimiters } = this._format;
      const values = value.split('-').map(v => v.padStart(2, '0'));
      return `${values[positions[0]]}${delimiters[0]}${values[positions[1]]}${delimiters[1]}${values[positions[2]]}`;
    } else {
      return value;
    }
  }
}

window.customElements.define(PraxisDate.is, PraxisDate);