import { _PraxisControlElement } from "../_PraxisControlElement/_PraxisControlElement";

export abstract class _PraxisSelectCore extends _PraxisControlElement {
  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    this._styleElement = document.createElement('style');
    //has to change field set to div because focus event is not firing for fieldset in Safari
    this._fieldSetElement = document.createElement('div');
    this._fieldSetElement.classList.add('fieldset');
    this._fieldSetElement.setAttribute('part', 'fieldset');
    shadowRoot.append(this._styleElement);
    shadowRoot.append(this._fieldSetElement);
  }

  protected _fieldSetElement: HTMLDivElement;
  protected _inputElements?: NodeListOf<HTMLInputElement>;
  protected _selector = 'select';
  protected abstract _style: string;
  protected _styleElement: HTMLStyleElement;
  protected _wrappedElement?: HTMLSelectElement;

  protected _setupComponent(): void {
    this._styleElement.innerHTML = this._style;
    this._setupSelectComponent();
  }

  /**
   * Update `innerHTML` for `this._fieldSetElement` and initialize references to elements added to fieldset
   */
  protected abstract _setupSelectComponent(): void;
}