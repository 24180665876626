import { PraxisButton } from "../PraxisButton/PraxisButton";
import { PraxisContextMenu } from "../PraxisContextMenu/PraxisContextMenu";
import { PraxisContextMenuItem } from "../PraxisContextMenu/PraxisContextMenuItem";
import styles from "./PraxisFormMenu.shadow.css";

export type PraxisFormActions = 'save' | 'edit' | 'print' | 'set-label' | 'mark-as-complete' | 'mark-as-verified' | 'assign' | 'direct-entry' | 'clear' | 'delete' | 'close';

export class PraxisFormMenu extends HTMLElement {
  // public static get observedAttributes(): string[] { return []; }

  private template = `
    <style>${styles}</style>
    <div button-container>
      <praxis-button icon="completed" id="mark" hidden>${i18n.BUTTON_MARK_AS_COMPLETED}</praxis-button>
      <praxis-button icon="verify" id="verify" hidden>${i18n.BUTTON_MARK_AS_VERIFIED}</praxis-button>
      <praxis-button icon="mail-forward" id="send" hidden>${i18n.BUTTON_DIRECT_ENTRY}</praxis-button>
      <praxis-button icon="edit" id="edit" hidden>${i18n.BUTTON_EDIT}</praxis-button>
      <praxis-button icon="close" id="close" hidden>${i18n.BUTTON_CLOSE}</praxis-button>
      <praxis-button icon="save" id="save" hidden>${i18n.BUTTON_SAVE}</praxis-button>
    </div>
    <praxis-button icon="more" id="more" hidden></praxis-button>
    <praxis-context-menu></praxis-context-menu>
  `;

  private moreButton: PraxisButton;
  private verifyButton: PraxisButton;
  private contextMenuElement: PraxisContextMenu;
  private menuActions: { [action: string]: { menuItem: PraxisContextMenuItem, button?: PraxisButton, data?:object } } = {};

  public set menuOnly(value: boolean){
    if (value){
      const container = this.shadowRoot?.querySelector('[button-container]') as HTMLDivElement;
      container.innerHTML = '';
      const more = this.shadowRoot?.querySelector('#more') as PraxisButton;
      more.classList.add('bg-white');
    }
  }

  public set verifyButtonToggle(value: boolean) {
    if(value) {
      const labelText = i18n.LABEL_MARK_FORM_AS_VERIFIED;
      this.verifyButton.innerText = labelText;
      this.menuActions["mark-as-verified"]?.menuItem.setAttribute('label', labelText);
    } else {
      const labelText = i18n.LABEL_MARK_FORM_AS_UNVERIFIED;
      this.verifyButton.innerText = labelText;
      this.menuActions["mark-as-verified"]?.menuItem.setAttribute('label', labelText)
    }
  }

  constructor() {
    super();
    this._onClickListener = this._onClickListener.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.moreButton = shadowRoot.querySelector('#more') as PraxisButton;
    this.contextMenuElement = shadowRoot.querySelector('praxis-context-menu') as PraxisContextMenu;
    this.contextMenuElement.registerTrigger(this.moreButton);

    this.verifyButton = shadowRoot.querySelector('#verify') as PraxisButton;

    let button = this.shadowRoot?.querySelector('#save') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("save")});
    this.menuActions["save"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_SAVE_FORM, "save",
        () => { this._onClickListener("save") }),
      button
    };

    button = this.shadowRoot?.querySelector('#edit') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("edit")});
    this.menuActions["edit"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_EDIT_FORM, "edit",
        () => { this._onClickListener("edit") }),
      button
    };

    this.menuActions["print"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_PRINT_FORM, "print",
        () => { this._onClickListener("print") })
    };

    this.menuActions["set-label"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_SET_LABEL, "tag",
        () => { this._onClickListener("set-label") })
    };

    button = this.shadowRoot?.querySelector('#mark') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("mark-as-complete")});
    this.menuActions["mark-as-complete"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_MARK_COMPLETED, "completed",
        () => { this._onClickListener("mark-as-complete") }),
      button
    };

    button = this.shadowRoot?.querySelector('#verify') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("mark-as-verified")});
    this.menuActions["mark-as-verified"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_MARK_VERIFIED, "verify",
        () => { this._onClickListener("mark-as-verified") }),
      button
    };

    this.menuActions["assign"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_ASSIGN_FORM, "forward",
        () => { this._onClickListener("assign") })
    };

    button = this.shadowRoot?.querySelector('#send') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("direct-entry")});
    this.menuActions["direct-entry"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_DIRECT_ENTRY, "mail-forward",
        () => { this._onClickListener("direct-entry") }),
      button
    };

    button = this.shadowRoot?.querySelector('#close') as PraxisButton;
    button.addEventListener('click', () => {this._onClickListener("close")});
    this.contextMenuElement.addDivider();
    this.menuActions["close"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_CLOSE_FORM, "close",
        () => { this._onClickListener("close") }),
      button
    };

    this.menuActions["clear"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_CLEAR_FORM, "erase",
        () => { this._onClickListener("clear") })
    };

    this.menuActions["delete"] = {
      menuItem: this.contextMenuElement.addMenuItem(i18n.MENU_DELETE_FORM, "delete",
        () => { this._onClickListener("delete") })
    };

    //Hide all items
    for(const name in this.menuActions){
      const menuItem = this.menuActions[name]?.menuItem;
      if (menuItem){
        menuItem.hidden = true;
      }
    }
  }

  public initMenuItem(actions: { name: PraxisFormActions, disabled: boolean, hideMenu: boolean, hideBtn?: boolean, data?: object }[]) {
    const moreBtn = this.shadowRoot?.querySelector('[icon="more"]') as PraxisButton;
    if (moreBtn) {
      moreBtn.hidden = false;
    }
    for(const action in this.menuActions){
      const a = actions.find(a => a.name == action);
      if (a){
        if (a.data) {
          const t = this.menuActions[action];
          if (t != undefined){
            t.data = a.data;
          }
        }
        this.setMenuItem(a.name, a.disabled, a.hideMenu, a.hideBtn);
      }else{
        this.setMenuItem(action as PraxisFormActions, true, true, true);
      }
    }
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    console.log(newValue);
    // switch (name) {}
  }

  public setMenuItem(name: PraxisFormActions, disabled?: boolean, hideMenu?: boolean, hideBtn?: boolean){
    const action = this.menuActions[name];
    if(action){
      if (disabled !== undefined){
        action.menuItem.disabled = disabled;
      }
      if (hideMenu !== undefined){
        action.menuItem.hidden = hideMenu;
      }
      if (action.button){
        if (disabled !== undefined){
          action.button.disabled = disabled;
        }
        if (hideBtn !== undefined){
          action.button.hidden = hideBtn;
        }
      }
    }
  }

  private _onClickListener(action: PraxisFormActions) {
    const a = this.menuActions[action];
    const data = a == undefined ? {} : a.data;
    this.dispatchEvent(new CustomEvent("praxis-menu-click", { detail: { action, data } }));
  }
}

window.customElements.define('praxis-form-menu', PraxisFormMenu);