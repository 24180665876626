import { FormCompleteness, FormType } from "@/Common/interfaces/IFormInstance";
import styles from "./PraxisFormInstanceListItem.shadow.css";
import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
import { PraxisAnchor } from "../PraxisAnchor/PraxisAnchor";
import { PraxisFormMenu } from "../PraxisFormMenu/PraxisFormMenu";

export class PraxisFormInstanceListItem extends HTMLElement {

  public init(href: string, name: string, type: FormType, hasFlag?: boolean, completeness?: FormCompleteness, directEntryTime?: string) {

    const anchor = this.shadowRoot?.querySelector('praxis-anchor') as PraxisAnchor;
    if(anchor){
      anchor.setAttribute('href', href);
      anchor.textContent = name;
    }

    const completenessElement = this.shadowRoot?.querySelector('#completeness') as HTMLDivElement;
    if (completenessElement && completeness) {
      completenessElement.hidden = false;
      switch (completeness) {
        case 'Completed':
          completenessElement.textContent = i18n.FORM_COMPLETED;
          this.removeAttribute('incomplete');
          break;
        case 'MarkedAsCompleted':
          completenessElement.textContent = i18n.FORM_MARKED_AS_COMPLETED;
          this.removeAttribute('incomplete');
          break;
        case 'PartiallyCompleted':
          completenessElement.textContent = i18n.FORM_PARTIALLY_COMPLETED;
          this.setAttribute('incomplete', '');
          break
        case 'New':
        case 'Incomplete':
          completenessElement.textContent = i18n.FORM_INCOMPLETE;
          this.setAttribute('incomplete', '');
          break;
        case 'Verified':
          completenessElement.textContent = i18n.FORM_VERIFIED;
          this.removeAttribute('incomplete');
          break;
      }
    }

    const directEntryElement = this.shadowRoot?.querySelector('#directEntry') as HTMLDivElement;
    if (directEntryElement && directEntryTime) {
      directEntryElement.hidden = false;
      directEntryElement.textContent = i18n.FORM_DIRECT_ENTRY_REQUESTED_ON(directEntryTime);
    }

    const iconElement = this.shadowRoot?.querySelector('praxis-icon') as PraxisIcon;
    if (iconElement) {
      if (hasFlag) {
        iconElement.setAttribute('name', 'flag');
        if (completeness && completeness != 'Completed' && completeness != 'MarkedAsCompleted'){
          iconElement.classList.add('incomplete');
        }
      } else {
        switch (type) {
          case "Enrollment":
            iconElement.setAttribute('name', 'assignment_turned_in');
            break;
          case "Contact":
            iconElement.setAttribute('name', 'contact_page');
            break;
          case "Consent":
          case "Default":
            if (directEntryTime) {
              iconElement.setAttribute('name', 'open-file');
            } else {
              switch (completeness) {
                case 'Completed':
                case 'MarkedAsCompleted':
                  iconElement.setAttribute('name', 'page-filled');
                  break;
                case 'PartiallyCompleted':
                  iconElement.setAttribute('name', 'page-filled-solid');
                  break;
                case 'New':
                case 'Incomplete':
                  iconElement.setAttribute('name', 'page-solid');
                  break;
                case 'Verified':
                  iconElement.setAttribute('name', 'verify');
                  break;
                default:
                  iconElement.setAttribute('name', 'help');
              }
            }
            break;
        }
      }
    }
  }

  public formMenu: PraxisFormMenu;

  public set formId(value: number){
    this.setAttribute('form-id', value.toString());
  }
  public set formInstanceId(value: number){
    this.setAttribute('form-instance-id', value.toString());
  }
  public set formGroupInstanceId(value: number){
    this.setAttribute('form-group-instance-id', value.toString());
  }
  public set formIndex(value: number){
    this.setAttribute('form-index', value.toString());
  }

  public addLabel(note: string, color?: string, backgroundColor?: string, icon?: string) {
    const noteContainer = this.shadowRoot?.querySelector('#labels') as HTMLDivElement;
    if (!noteContainer) { return }
    const noteElement = document.createElement('div') as HTMLDivElement;
    color = color ?? '#333';
    backgroundColor = backgroundColor ?? '#fff';
    icon = icon ?? 'label';
    noteElement.classList.add('label');
    noteElement.setAttribute('style', `background-color:${backgroundColor};color:${color}`);
    noteElement.innerHTML = `<praxis-icon name="${icon}"></praxis-icon><span>${note}</span>`;
    noteContainer.append(noteElement);
  }

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML =  `
      <style>${styles}</style>
      <div container>
        <div sort></div>
        <praxis-icon></praxis-icon>
        <praxis-anchor id="name"></praxis-anchor>
        <div right>
          <praxis-form-menu></praxis-form-menu>
        </div>
      </div>
      <div id="completeness" hidden></div>
      <div id="labels"></div>
      <div id="directEntry" hidden><praxis-icon name="mail-forward"></praxis-icon></div>
  `;
    this.formMenu = this.shadowRoot?.querySelector('praxis-form-menu') as PraxisFormMenu;
    this.formMenu.menuOnly = true;
  }
}

window.customElements.define('praxis-form-instance-list-item', PraxisFormInstanceListItem);