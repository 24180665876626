import { PraxisSideBarListItem } from "./PraxisSideBarListItem";
import { IProject } from "@/Common/interfaces/IProject";
import styles from "./PraxisSideBar.shadow.css";

export class PraxisSideBar extends HTMLElement {
  private backdropElement: HTMLDivElement;
  private titleElement: HTMLDivElement;
  private subtitleElement: HTMLDivElement;
  private footerSlotElement: HTMLSlotElement;
  private mainSlotElement: HTMLSlotElement;
  private toggleElement: HTMLDivElement;
  private template = `
   <style>${styles}</style>
   <div id="container">
    <div id="top">
      <div id="toggle"><praxis-icon name="global-navigation-button" size="small"></praxis-icon></div>
      <div id="titles">
        <div id="title"></div>
        <div id="subtitle"></div>
      </div>
    </div>
    <div id="main">
      <div id="main-title" hidden>
        <praxis-icon name="file-explorer" size="small"></praxis-icon>
        <span class="main-title-text">Projects</span>
      </div>
      <div id="main-list">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="divider"></div>
    <div id="footer">
      <slot name="footer"></slot>
    </div>
   </div>
   <div id="backdrop"></div>
 `;

  private static MAIN_TITLE_ATTRIBUTE = 'main-title';
  public static SUBTITLE_ATTRIBUTE = 'subtitle';

  public static get observedAttributes(): string[] {
    return [
      PraxisSideBar.MAIN_TITLE_ATTRIBUTE,
      PraxisSideBar.SUBTITLE_ATTRIBUTE,
    ];
  }

  public constructor() {
    super();
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.signout = this.signout.bind(this);
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.backdropElement = shadowRoot.querySelector('#backdrop') as HTMLDivElement;
    this.toggleElement = shadowRoot.querySelector('#toggle') as HTMLDivElement;
    this.titleElement = shadowRoot.querySelector('#title') as HTMLDivElement;
    this.subtitleElement = shadowRoot.querySelector('#subtitle') as HTMLDivElement;
    this._mainSideTitleElement = shadowRoot.querySelector('#main-title') as HTMLDivElement;
    this.mainSlotElement = shadowRoot.querySelector('slot[name="main"]') as HTMLSlotElement;
    this.footerSlotElement = shadowRoot.querySelector('slot[name="footer"]') as HTMLSlotElement;
    this.addEventListeners();
  }

  public toggleCollapse(): void {
    if (this.hasAttribute('collapsed')) {
      this.open();
    } else {
      this.close();
    }
  }

  public close(): void{
    const listItemElements = Array.from(this.querySelectorAll('praxis-side-bar-list-item'));
    if (!this.hasAttribute('collapsed')) {
      this.setAttribute('collapsed', '');
      for (const listItemElement of listItemElements) {
        listItemElement.setAttribute('collapsed', '');
      }

      if (window.innerWidth >= 640) {
        window.dispatchEvent(new Event('resize'));
      }
    }
  }

  public open(): void{
    const listItemElements = Array.from(this.querySelectorAll('praxis-side-bar-list-item'));
    if (this.hasAttribute('collapsed')) {
      this.removeAttribute('collapsed');
      for (const listItemElement of listItemElements) {
        listItemElement.removeAttribute('collapsed');
      }

      if (window.innerWidth >= 640) {
        window.dispatchEvent(new Event('resize'));
      }
    }
  }

  public updateProjectList(collection: {[code: string]: IProject | undefined}, sortedProjectCodes: string[]): void {
    this._mainSideTitleElement.hidden = false;

    //insert new project
    let prevItem: PraxisSideBarListItem | undefined = undefined;
    for(const code of sortedProjectCodes){
      const project = collection[code];
      if (project) {
        const item = this.querySelector(`praxis-side-bar-list-item[slot="main"][project-code="${code}"]`) as PraxisSideBarListItem;
        if (!item){
          prevItem = this.addProjectListItem(project, prevItem);
        }else{
          prevItem = item;
        }
      }
    }

    //remove old project
    for(const item of this.querySelectorAll(`praxis-side-bar-list-item[slot="main"]`)){
      const code = item.getAttribute('project-code') || '';
      if (sortedProjectCodes.indexOf(code) == -1){
        item.remove();
      }
    }
  }

  //update project list UI with the newly added project code items into a sorted-ready Project Items list
  public newProject(project: { code: string, name: string }): void {
    const sortedExistingProjectItems = Array.from(this.querySelectorAll(`praxis-side-bar-list-item[slot="main"][project-code]`));
    const index = sortedExistingProjectItems.findIndex(i => (i.textContent?.toUpperCase() || '') > project.name.toUpperCase());

    switch (index) {
      case -1:  //insert to the end
        this.addProjectListItem(project, sortedExistingProjectItems[sortedExistingProjectItems.length - 1] as HTMLElement);
        break;
      case 0: //prepend to the top
        this.addProjectListItem(project, undefined);
        break;
      default:
        this.addProjectListItem(project, sortedExistingProjectItems[index - 1] as PraxisSideBarListItem);
    }
  }

  public selectProject(projectCode?: string) {
    this.querySelectorAll('praxis-side-bar-list-item').forEach((e) => {
      e.removeAttribute('selected');
    })
    if (projectCode) {
      const item = this.querySelector(`praxis-side-bar-list-item[project-code="${projectCode}"]`);
      item?.setAttribute('selected', '');
    }
  }

  public addFooterItems(homeLabel: string, authenticated: boolean): void {
    if (authenticated) {
      this.addFooterItem('home', homeLabel, () => this.navigate('/'));
      this.addFooterItem('insert-chart', 'Report', () => this.navigate('/report'));
      // this.addFooterItem('feedback', 'Feedback');
    }
    // this.addFooterItem('help', 'Help');
    if (authenticated) {
      this.addFooterItem('eula', `${i18n.LABEL_END_USER_AGREEMENT}`, () => this.navigate('/eula'));
      this.addFooterItem('sign-out', `${i18n.LABEL_LOGOUT}`, this.signout);
    }
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisSideBar.MAIN_TITLE_ATTRIBUTE:
        this.setTitle(newValue);
        break;
      case PraxisSideBar.SUBTITLE_ATTRIBUTE:
        this.setSubtitle(newValue);
        break;
    }
  }

  private _mainSideTitleElement: HTMLDivElement;

  private addEventListeners(): void {
    this.addEventListener('select', () => window.innerWidth < 640 ? this.toggleCollapse() : null)
    this.toggleElement.addEventListener('click', this.toggleCollapse);
    this.backdropElement.addEventListener('click', this.toggleCollapse);
    window.addEventListener('resize', () => {
      // always collapsed the side bar on resize on small screen
      if (window.innerWidth < 640 && !this.hasAttribute('collapsed')) {
        this.toggleCollapse();
      }
    })
  }

  private setTitle(text: string): void {
    this.titleElement.textContent = text;
  }

  private setSubtitle(text: string): void {
    this.subtitleElement.textContent = text;
  }

  private addFooterItem(icon: string, label: string, listener?: () => void): void {
    const footerItem = document.createElement('praxis-side-bar-list-item') as PraxisSideBarListItem;
    footerItem.setAttribute('slot', 'footer');
    footerItem.setAttribute('icon', icon);
    footerItem.textContent = label;

    if (listener) {
      footerItem.addEventListener('click', listener);
    }

    this.appendChild(footerItem);

    if (this.hasAttribute('collapsed')) {
      footerItem.setAttribute('collapsed', '');
    }
  }

  // project is to be added 'before' or 'after' itemList
  private addProjectListItem(project: {code: string, name:string}, prevItem:HTMLElement | undefined): PraxisSideBarListItem {
    const projectListItem = document.createElement('praxis-side-bar-list-item') as PraxisSideBarListItem;
    projectListItem.setAttribute('slot', 'main');
    projectListItem.setAttribute('project-code', project.code);
    projectListItem.setAttribute('letter-icon', project.name[0] ? project.name[0] : '');
    projectListItem.textContent = project.name;
    projectListItem.addEventListener('click', () => {
      this.navigate(`/${project.code}`);
    });

    if (prevItem){
      prevItem.after(projectListItem)
    }else{
      this.prepend(projectListItem)
    }

    if (this.hasAttribute('collapsed')) {
      projectListItem.setAttribute('collapsed', '');
    }
    return projectListItem;
  }

  private navigate(path: string): void {
    this.dispatchEvent(new CustomEvent('navigate', {detail: path}));
  }

  private signout(): void {
    this.dispatchEvent(new Event('signout'));
  }
}

window.customElements.define('praxis-side-bar', PraxisSideBar);
