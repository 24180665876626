import { FormCompleteness, IFormInstance, ILabel, IMarkAsVerifyDetail, IMarkedAsCompleteDetail } from "@/Common/interfaces/IFormInstance";
import { IFormInstances } from "@/Common/interfaces/IFormInstances";
import { Reducer } from "redux";

/**
 * action types
 */
export const LOAD_FORM_INSTANCES = 'LOAD_FORM_INSTANCES';
export const UPDATE_DIRECT_ENTRY = 'UPDATE_DIRECT_ENTRY';
export const UPDATE_LABELS = 'UPDATE_LABELS';
export const UPDATE_COMPLETENESS = 'UPDATE_COMPLETENESS';
export const UPDATE_VERIFY = 'UPDATE_VERIFY';
export const DELETE_FORM_INSTANCE = 'DELETE_FORM_INSTANCE';
export const UPDATE_FLAG_STATUS = 'UPDATE_FLAG_STATUS';

interface ILoadFormInstanceAction {
  type: typeof LOAD_FORM_INSTANCES;
  formInstances: IFormInstance[];
}
interface IUpdateCompletenessAction{
  type: typeof UPDATE_COMPLETENESS;
  formInstanceId: number;
  completeness: FormCompleteness;
  markedAsCompleteDetail?: IMarkedAsCompleteDetail
}
interface IUpdateVerifyAction{
  type: typeof UPDATE_VERIFY;
  formInstanceId: number;
  completeness: FormCompleteness;
  markedAsVerifiedDetail?: IMarkAsVerifyDetail;
  ownership: string;
}
interface IUpdateDirectEntryAction {
  type: typeof UPDATE_DIRECT_ENTRY;
  formInstanceId: number;
  directEntryCode?: string;
  directEntryTime?: string;
}
interface IUpdateLabelsAction{
  type: typeof UPDATE_LABELS;
  formInstanceId: number;
  labels: ILabel[];
}
interface IDeleteAction{
  type: typeof DELETE_FORM_INSTANCE;
  formInstanceId: number;
  replacedFormInstanceId?: number;
}

interface IUpdateFlagStatus{
  type: typeof UPDATE_FLAG_STATUS;
  formInstanceId: number;
  hasFlagFromCoordinator: boolean;
  hasFlagFromMonitor: boolean;
}

export type FormInstancesActionTypes
  = ILoadFormInstanceAction
  | IUpdateCompletenessAction
  | IUpdateVerifyAction
  | IUpdateDirectEntryAction
  | IUpdateLabelsAction
  | IDeleteAction
  | IUpdateFlagStatus;

/**
 * reducer
 */
export const formInstances: Reducer<IFormInstances, FormInstancesActionTypes> = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FORM_INSTANCES: {
      const { formInstances } = action;
      for (const formInstance of formInstances) {
        state[formInstance.id] = formInstance;
      }
      return state;
    }
    case UPDATE_COMPLETENESS: {
      const { formInstanceId, completeness, markedAsCompleteDetail } = action;
      const update = state[formInstanceId];
      if (!update){
        console.error('FormInstance redux - form instance not found');
        return state;
      }
      update.completeness = completeness;
      update.formNoteDetails = { ...update.formNoteDetails, markedAsCompleteDetail: markedAsCompleteDetail };
      return {
        ...state,
        [formInstanceId]: update
      }
    }
    case UPDATE_VERIFY: {
      const { formInstanceId, completeness, ownership, markedAsVerifiedDetail } = action;
      const update = state[formInstanceId];
      if (!update){
        console.error('FormInstance redux - form instance not found');
        return state;
      }
      update.completeness = completeness;
      update.ownership = ownership
      update.formNoteDetails = { ...update.formNoteDetails, markedAsVerifiedDetail: markedAsVerifiedDetail };
      return {
        ...state,
        [formInstanceId]: update
      }
    }
    case UPDATE_DIRECT_ENTRY: {
      const { formInstanceId, directEntryCode, directEntryTime } = action;
      const update = state[formInstanceId];
      if (!update){
        console.error('FormInstance redux - form instance not found');
        return state;
      }
      update.directEntryCode = directEntryCode;
      update.directEntryTime = directEntryTime;
      return {
        ...state,
        [formInstanceId]: update
      }
    }
    case UPDATE_LABELS:{
      const { formInstanceId, labels } = action;
      const update = state[formInstanceId];
      if (!update){
        console.error('FormInstance redux - form instance not found');
        return state;
      }
      update.labels = labels;
      return {
        ...state,
        [formInstanceId]: update
      }
    }
    case DELETE_FORM_INSTANCE:{
      const {formInstanceId, replacedFormInstanceId} = action;
      const update = state[formInstanceId];
      if (!update){
        console.error('FormInstance redux - form instance not found');
        return state;
      }
      if (replacedFormInstanceId){
        state[replacedFormInstanceId] = {
          formGroupInstanceId: update.formGroupInstanceId,
          id: replacedFormInstanceId,
          name: update.name,
          readOnly: update.readOnly,
          code: update.code,
          type: update.type,
          completeness: 'New',
          formId: update.formId,
          directEntryCode: undefined,
          directEntryTime: undefined,
          formNoteDetails: undefined,
          labels: undefined,
          ownership: update.ownership,
          hasFlagFromMonitor: update.hasFlagFromMonitor,
          hasFlagFromCoordinator: update.hasFlagFromCoordinator
        }
      }
      delete state[formInstanceId];
      return state;
    }
    case UPDATE_FLAG_STATUS:{
      const { formInstanceId, hasFlagFromCoordinator, hasFlagFromMonitor } = action;
      const update = state[formInstanceId];
      if (!update) {
        return state;
      }
      update.hasFlagFromCoordinator = hasFlagFromCoordinator;
      update.hasFlagFromMonitor = hasFlagFromMonitor;
      return state;
    }
    default: {
      return state;
    }
  }
}