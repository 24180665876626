import styles from "./PraxisTag.shadow.css";

export class PraxisTag extends HTMLElement {
  public static get observedAttributes(): string[] {
    return [];
  }

  public get disabled(): boolean {
    return this.hasAttribute('disabled');
  }

  private template = `
    <style>${styles}</style>
    <slot></slot>
  `;

  public constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue === newValue) return;
    // switch (name) {
    // }
  }
}

window.customElements.define('praxis-tag', PraxisTag);
