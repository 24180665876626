import { PraxisIcon } from '../../PraxisIcon/PraxisIcon';
import styles from './PraxisSectionNavigationButton.shadow.scss';

interface IOptions {
  count: number;
  totalCount: number;
  invalidInput: HTMLElement | null;
}

export class PraxisSectionNavigationButton extends HTMLElement {
  private _backgroundElement: HTMLElement;
  private _iconElement: PraxisIcon;
  private _labelElement: HTMLDivElement

  public set label(str: string){
    this._labelElement.innerHTML = str;
  }

  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = `<style>${styles}</style><i></i><praxis-icon></praxis-icon><div id='psnb-label'></div>`;
    this._backgroundElement = shadowRoot.querySelector('i') as HTMLElement;
    this._iconElement = shadowRoot.querySelector(PraxisIcon.is) as PraxisIcon;
    this._labelElement = shadowRoot.querySelector('#psnb-label') as HTMLDivElement;
  }

  public update(options: IOptions): void {
    const { count, invalidInput, totalCount } = options;
    if (invalidInput) {
      this.classList.add('error');
    } else {
      this.classList.remove('error');
    }

    this._backgroundElement.style.right = `${totalCount === 0 ? 100 : (1 - ((totalCount - count)/totalCount))*100}%`;
    this._iconElement.setAttribute('name', count === totalCount ? 'check-mark' : 'info');
  }
}

customElements.define('p-s-n-b', PraxisSectionNavigationButton);
