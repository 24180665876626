import { PraxisDate } from '../PraxisDate/PraxisDate';
import { PraxisTime } from '../PraxisTime/PraxisTime';
import { PraxisTextarea } from '../PraxisTextarea/PraxisTextarea';
import { PraxisCheckbox } from "../PraxisCheckbox/PraxisCheckbox";
import { PraxisInput } from "../PraxisInput/PraxisInput";
import { PraxisRadio } from "../PraxisRadio/PraxisRadio";

const PRAXIS_ELEMENTS = {
  [PraxisDate.is]: PraxisDate,
  [PraxisTime.is]: PraxisTime,
  [PraxisInput.is]: PraxisInput,
  [PraxisTextarea.is]: PraxisTextarea,
}

export class PraxisSelectWithAnnotation extends HTMLElement{
  public static is = 'praxis-select-with-annotation';

  private _annotationInputs:HTMLInputElement[] = [];
  public get annotationInputs():HTMLInputElement[] {
    return this._annotationInputs;
  }
  private _selectInput:HTMLSelectElement | undefined;
  public get selectInput():HTMLSelectElement | undefined{
    if (this._selectInput == undefined){
      console.error("Select With Annotation Control select input is undefined, have you called the wrap function to setup the control");
    }
    return this._selectInput;
  }

  public static wrap(selectWithAnnotationDiv: HTMLDivElement){
    const is = selectWithAnnotationDiv.getAttribute('is');
    if (is !== 'select-with-annotation'){
      console.error("Input HTMLDivElement is not select-with-annoation");
      return;
    }

    const select = selectWithAnnotationDiv.querySelector<HTMLSelectElement>("select");
    const parent = document.createElement(this.is) as PraxisSelectWithAnnotation;
    select?.replaceWith(parent);
    parent._selectInput = select as HTMLSelectElement;
    const praxisSelect = select?.getAttribute('is')?.includes('radio')
      ? PraxisRadio.wrap(select as HTMLElement)
      : PraxisCheckbox.wrap(select as HTMLElement);
    parent.append(praxisSelect);
    if (select?.hasAttribute('identifier')){
      parent.setAttribute('identifier', '');
    }

    Array.from(selectWithAnnotationDiv.querySelectorAll<HTMLDivElement>('div[praxis-annotation]'))
      .forEach(a => {
        const value = Number.parseInt(a.getAttribute('classification-value') || '');

        const input = a.querySelector('input, textarea') as HTMLElement;
        if (input == null){
          console.error("Input or textarea not found in praxis annotation, only input and textarea can be supported as annotation field");
          return;
        }
        parent.annotationInputs.push(input as HTMLInputElement);

        const option = praxisSelect.shadowRoot?.querySelector(`div.option[value="${value}"]`);
        if (!option){
          console.error(`select option (${value}) not found when adding annotation`);
          return;
        }
        //Has to insert the annotation input to the position before the wrap
        //otherwise extra praxis-select-single will be created!!! (Not sure why???)
        option.insertAdjacentElement('afterend', a);

        const is = input.getAttribute('is');
        const wrappingElement = PRAXIS_ELEMENTS[is || ''];
        if (wrappingElement) {
          wrappingElement.wrap(input);
        } else {
          PraxisInput.wrap(input);
        }
      });

    return parent;
  }

  constructor() {
    super();
    //We probably dont need shadowRoot for this control
    // const shadowRoot = this.attachShadow({ mode: 'open' });
    // shadowRoot.innerHTML = `<style>${styles}</style>`
  }
}

window.customElements.define(PraxisSelectWithAnnotation.is, PraxisSelectWithAnnotation);
