import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import { PraxisContextMenu } from "@/WebComponents/PraxisContextMenu/PraxisContextMenu";
import { PraxisContextMenuItem } from "@/WebComponents/PraxisContextMenu/PraxisContextMenuItem";
import styles from "./PraxisBlobInstanceListItem.shadow.css";

export type PraxisBlobActions = 'download' | 'set-label' | 'rename' | 'delete'

export class PraxisBlobInstanceListItem extends HTMLElement {
  private menuActions: { [action: string]: { menuItem: PraxisContextMenuItem, data?: object } } = {};

  public set filename(value: string) {
    const fileNameElement = this.shadowRoot?.querySelector('[file-name]') as HTMLDivElement;
    if (fileNameElement) {
      fileNameElement.textContent = value;
    }
  }
  public set date(value: string) {
    const dateElement = this.shadowRoot?.querySelector('#date') as HTMLDivElement;
    if (dateElement) {
      dateElement.textContent = value;
    }
  }

  public constructor() {
    super();
    this._onClickListener = this._onClickListener.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = `<style>${styles}</style>
    <div container>
      <div sort>
      </div>
      <praxis-icon name="file_open"></praxis-icon>
      <div praxis-blob id="name">
        <div file-name></div>
      </div>
      <div right>
        <praxis-button icon="more"></praxis-button><praxis-context-menu></praxis-context-menu>
      </div>
    </div>
    <div id="labels">
    </div>
    <div id="date">
    </div>`;

    const moreButtonElement = shadowRoot.querySelector('[icon="more"]') as PraxisButton;
    const contextMenuElement = shadowRoot.querySelector('praxis-context-menu') as PraxisContextMenu;
    contextMenuElement.registerTrigger(moreButtonElement);

    this.menuActions["download"] = {
      menuItem: contextMenuElement.addMenuItem("Download this file", "download",
        () => { this._onClickListener("download") })
    };
    this.menuActions["download"].menuItem.hidden = true;

    this.menuActions["set-label"] = {
      menuItem: contextMenuElement.addMenuItem("Set label", "tag",
        () => { this._onClickListener("set-label") })
    };
    this.menuActions["set-label"].menuItem.hidden = true;

    this.menuActions["rename"] = {
      menuItem: contextMenuElement.addMenuItem("Rename this file", "edit",
        () => { this._onClickListener("rename") })
    };
    this.menuActions["rename"].menuItem.hidden = true;

    this.menuActions["delete"] = {
      menuItem: contextMenuElement.addMenuItem("Delete this file", "delete",
        () => { this._onClickListener("delete") })
    };
    this.menuActions["delete"].menuItem.hidden = true;
    moreButtonElement.hidden = true;

    const nameElement = shadowRoot.querySelector('#name') as HTMLDivElement;
    nameElement.onclick = () => {
      const idString = this.getAttribute('blob-id');
      if (!idString) {
        console.error('Praxis Blob Instance List Item - blob id is undefinded');
        return;
      }
      const id = parseInt(idString);
      const name = this.getAttribute('blob-name');
      const type = this.getAttribute('blob-type');
      this.dispatchEvent(new CustomEvent("download-blob", { detail: { id, name, type } }));
    }
  }

  public init(id: number, name: string, type: string) {
    this.setAttribute('blob-id', id.toString());
    this.setAttribute('blob-name', name);
    this.setAttribute('blob-type', type);
  }

  public initMenuItem(actions: { name: PraxisBlobActions, disabled: boolean, hide: boolean, data?: object }[]) {
    const moreBtn = this.shadowRoot?.querySelector('[icon="more"]') as PraxisButton;
    if (moreBtn) {
      moreBtn.hidden = false;
    }
    for (const action in this.menuActions) {
      const a = actions.find(a => a.name == action);
      if (a) {
        if (a.data) {
          const t = this.menuActions[action];
          if (t != undefined) {
            t.data = a.data;
          }
        }
        this.setMenuItem(a.name, a.disabled, a.hide);
      } else {
        this.setMenuItem(action as PraxisBlobActions, true, true);
      }
    }
  }

  public setMenuItem(name: PraxisBlobActions, disabled?: boolean, hide?: boolean) {
    const action = this.menuActions[name];
    if (action) {
      if (disabled !== undefined) {
        action.menuItem.disabled = disabled;
      }
      if (hide !== undefined) {
        action.menuItem.hidden = hide;
      }
    }
  }

  private _onClickListener(action: PraxisBlobActions) {
    const a = this.menuActions[action];
    const data = a == undefined ? {} : a.data;
    this.dispatchEvent(new CustomEvent("praxis-menu-click", { detail: { action, data } }));
  }
}

window.customElements.define('praxis-blob-instance-list-item', PraxisBlobInstanceListItem);