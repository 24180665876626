import { PraxisButton } from "@/WebComponents/PraxisButton/PraxisButton";
import { PraxisContextMenu } from "@/WebComponents/PraxisContextMenu/PraxisContextMenu";
import styles from "./PraxisDataCollectionPoint.shadow.css";
import { PraxisContextMenuItem } from "../PraxisContextMenu/PraxisContextMenuItem";
import { PraxisFormInstanceListItem } from "./PraxisFormInstanceListItem";
import { PraxisBlobInstanceListItem } from "./PraxisBlobInstanceListItem";
import { PraxisProgressBar } from "../PraxisProgressBar/PraxisProgressBar";

export type PraxisDataCollectionPointActions = 'edit' | 'add-optional-form' | 'add-files' | 'delete';

export class PraxisDataCollectionPoint extends HTMLElement {
  public static get observedAttributes(): string[] {
    return [];
  }

  private template = `
  <style>${styles}</style>
  <div  id="dataCollectionContainer">
    <div id="date"></div>
    <div collapse>
      <praxis-button icon="chevron-down"></praxis-button>
    </div>
    <div main>
      <div id="name"></div>
      <div id="facility"></div>
    </div>
    <div right>
      <praxis-button icon="more" hidden></praxis-button>
      <praxis-context-menu></praxis-context-menu>
    </div>
  </div>
  <div progress-container>
    <praxis-progress-bar class='no-border thin' hidden></praxis-progress-bar>
  </div>
  <div id="formInstances"></div>
  <div id="blobInstances"></div>
  `;

  private progressBar?: PraxisProgressBar;
  private nameElement: HTMLDivElement;
  private facilityElement: HTMLDivElement;
  private dateElement: HTMLDivElement;
  private menuActions: { [action: string]: {menuItem: PraxisContextMenuItem, data?: object} } = {};

  private formInstancesWrapper: HTMLDivElement;
  private collapseButtonElement: PraxisButton;

  public set showProgress(value: boolean){
    if (value){
      this.progressBar?.removeAttribute('hidden');
    }else{
      this.progressBar?.setAttribute('hidden', '');
    }
  }

  public set name(value: string){
    this.nameElement.textContent = value;
  }
  public set facility(value: string){
    this.facilityElement.textContent = value;
  }
  public set date(value: string) {
    const date = new Date(value);
    //parse date without timezone
    //https://stackoverflow.com/questions/17545708/parse-date-without-timezone-javascript
    const adjustedDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
    this.dateElement.textContent =
      `${adjustedDate.getUTCFullYear()}\n${adjustedDate.toLocaleString('default', { month: 'short' })} ${adjustedDate.getDate()}`;
  }
  public set formGroupInstanceId(value: string){
    this.setAttribute('form-group-instance-id', value);
  }
  public set completeness(value: boolean){
    if (value){
      this.removeAttribute('incomplete');
    }else{
      this.setAttribute('incomplete', '');
    }
  }

  public constructor() {
    super();
    this.toggleCollapse = this.toggleCollapse.bind(this);

    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;

    this.nameElement = shadowRoot.querySelector('#name') as HTMLDivElement;
    this.facilityElement = shadowRoot.querySelector('#facility') as HTMLDivElement;
    this.dateElement = shadowRoot.querySelector('#date') as HTMLDivElement;
    this.formInstancesWrapper = shadowRoot.querySelector('#formInstances') as HTMLDivElement;
    this.progressBar = shadowRoot.querySelector('praxis-progress-bar') as PraxisProgressBar;

    const contextMenuElement = shadowRoot.querySelector('praxis-context-menu') as PraxisContextMenu;
    const moreButtonElement = shadowRoot.querySelector('[icon="more"]') as PraxisButton;
    contextMenuElement.registerTrigger(moreButtonElement);

    this.collapseButtonElement = shadowRoot.querySelector('[icon="chevron-down"]') as PraxisButton;
    this.collapseButtonElement.addEventListener('click', this.toggleCollapse);

    this.menuActions["edit"] = {menuItem: contextMenuElement.addMenuItem(i18n.MENU_EDIT_DETAILS, 'edit', () => { this._onClickListener("edit") })};
    this.menuActions["edit"].menuItem.hidden = true;
    this.menuActions["add-optional-form"] = {menuItem: contextMenuElement.addMenuItem(i18n.MENU_ADD_OPTIONAL_FORMS, 'post_add', () => { this._onClickListener("add-optional-form") })};
    this.menuActions["add-optional-form"].menuItem.hidden = true;
    this.menuActions["add-files"] = {menuItem: contextMenuElement.addMenuItem(i18n.MENU_ADD_FILES, 'upload_file', () => { this._onClickListener("add-files") })};
    this.menuActions["add-files"].menuItem.hidden = true;
    this.menuActions["delete"] = {menuItem: contextMenuElement.addMenuItem(i18n.MENU_DELETE_DCP, 'delete', () => { this._onClickListener("delete") })};
    this.menuActions["delete"].menuItem.hidden = true;
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    // switch (name) {
    // }
  }

  public initMenuItem(actions: {name: PraxisDataCollectionPointActions, disabled: boolean, hidden: boolean, data?: object}[]){
    for(const action in this.menuActions){
      const a = actions.find(a => a.name == action);
      if (a){
        if (a.data) {
          const t = this.menuActions[action];
          if (t != undefined){
            t.data = a.data;
          }
        }
        this.setMenuItem(a.name, a.disabled, a.hidden);
      }else{
        this.setMenuItem(action as PraxisDataCollectionPointActions, true, true);
      }
    }
    const moreBtn = this.shadowRoot?.querySelector('[icon="more"]') as PraxisButton;
    if (moreBtn){
      moreBtn.hidden = !Object.values(this.menuActions).some(a => !a.menuItem.hidden);
    }
  }

  public setMenuItem(name: PraxisDataCollectionPointActions, disabled?: boolean, hidden?:boolean){
    const action = this.menuActions[name];
    if (action){
      if (disabled != undefined){
        action.menuItem.disabled = disabled;
      }
      if (hidden != undefined){
        action.menuItem.hidden = hidden;
      }
    }
  }

  public addFormInstances(formInstances: PraxisFormInstanceListItem[]){
    const formInstancesElement = this.shadowRoot?.querySelector('#formInstances') as HTMLDivElement;
    if (!formInstancesElement || !formInstances) { return }
    formInstancesElement.append(...formInstances);
  }

  public addBlobInstances(blobInstances: PraxisBlobInstanceListItem[]){
    const blobInstancesElement =  this.shadowRoot?.querySelector('#blobInstances') as HTMLDivElement;
    if (!blobInstancesElement || !blobInstances) { return }
    blobInstancesElement.append(...blobInstances);
  }

  private toggleCollapse() {
    this.formInstancesWrapper.hidden = !this.formInstancesWrapper.hidden;
    if (this.formInstancesWrapper.hidden) {
      this.setAttribute('collapsed', '')
      this.collapseButtonElement.setAttribute('name', 'chevron-up');
    } else {
      this.removeAttribute('collapsed')
      this.collapseButtonElement.setAttribute('name', 'chevron-down');
    }
  }

  private _onClickListener(action: PraxisDataCollectionPointActions) {
    const a = this.menuActions[action];
    const data = a != undefined ? a.data : {};
    this.dispatchEvent(new CustomEvent("praxis-menu-click", { detail: { action, data } }));
  }
}

window.customElements.define('praxis-data-collection-point', PraxisDataCollectionPoint);