import { PraxisButton } from "../PraxisButton/PraxisButton";
import { PraxisIcon } from "../PraxisIcon/PraxisIcon";
import styles from "./PraxisExpandableInfo.shadow.scss";

export class PraxisExpandableInfo extends HTMLElement {
  public static COLLAPSE_LABEL_ATTRIBUTE = 'collapse-label';
  public static EXPAND_LABEL_ATTRIBUTE = 'expand-label';
  public static MAIN_MESSAGE_ATTRIBUTE = 'main-message';
  public static ICON_ATTRIBUTE = 'icon';

  public static get observedAttributes(): string[] {
    return [
      PraxisExpandableInfo.COLLAPSE_LABEL_ATTRIBUTE,
      PraxisExpandableInfo.EXPAND_LABEL_ATTRIBUTE,
      PraxisExpandableInfo.MAIN_MESSAGE_ATTRIBUTE,
      PraxisExpandableInfo.ICON_ATTRIBUTE,
    ];
  }

  private labelContainerElement: HTMLDivElement;
  private labelElement: HTMLDivElement;
  private mainMessageElement: HTMLDivElement;
  private toggleCollapseButtonElement: PraxisButton;
  private iconElement: PraxisIcon;

  private template = `
    <style>${styles}</style>
    <div id="container">
      <div id="label-container">
        <div>
          <praxis-icon hidden></praxis-icon>
          <div id="label"></div>
        </div>
        <praxis-button icon="chevron-down"></praxis-button>
      </div>
      <div id="main-message" hidden><slot></slot></div>
    </div>
  `;

  public constructor() {
    super();
    this.toggleCollapse = this.toggleCollapse.bind(this);
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.innerHTML = this.template;
    this.labelContainerElement = shadowRoot.querySelector('#label-container') as HTMLDivElement;
    this.labelElement = shadowRoot.querySelector('#label') as HTMLDivElement;
    this.mainMessageElement = shadowRoot.querySelector('#main-message') as HTMLDivElement;
    this.toggleCollapseButtonElement = shadowRoot.querySelector('praxis-button') as PraxisButton;
    this.iconElement = shadowRoot.querySelector('praxis-icon') as PraxisIcon;

    this.labelContainerElement.addEventListener('click', this.toggleCollapse);
    this.collapse();
  }

  private attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (oldValue === newValue) return;
    switch (name) {
      case PraxisExpandableInfo.ICON_ATTRIBUTE:
        this.iconElement.setAttribute('name', newValue);
        this.iconElement.hidden = false;
        break;
      case PraxisExpandableInfo.EXPAND_LABEL_ATTRIBUTE:
        this.setLabel(newValue);
        break;
      case PraxisExpandableInfo.MAIN_MESSAGE_ATTRIBUTE:
        this.setMainMessage(newValue);
        break;
    }
  }

  private setLabel(text: string): void {
    this.labelElement.textContent = text;
  }

  private setMainMessage(text: string): void {
    this.mainMessageElement.textContent = text;
  }

  public toggleCollapse(): void {
    if (this.mainMessageElement.hasAttribute('hidden')) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public expand(): void {
    const label = this.getAttribute(PraxisExpandableInfo.COLLAPSE_LABEL_ATTRIBUTE);
    if (label) {
      this.setLabel(label);
    }
    this.mainMessageElement.removeAttribute('hidden');
    this.toggleCollapseButtonElement.setAttribute('icon','chevron-up');
  }

  public collapse(): void {
    const label = this.getAttribute(PraxisExpandableInfo.EXPAND_LABEL_ATTRIBUTE);
    if (label) {
      this.setLabel(label);
    }
    this.mainMessageElement.setAttribute('hidden', '');
    this.toggleCollapseButtonElement.setAttribute('icon','chevron-down');
  }
}

window.customElements.define('praxis-expandable-info', PraxisExpandableInfo);
